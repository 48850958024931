<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="main-container">
      <div class="d-flex justify-content-center flex-column">
        <div class="top-navbar">
          <a @click=" goTOHome()" class="icon-left">
            <img src="../assets/home.svg" alt="">
          </a>
          <h1>Manage Meetings</h1>
          <a @click="$router.go(-1)" class="icon-right">
            <img src="../assets/close.svg" alt="" @click="toggleSideBar">
          </a>
        </div>
      </div>
      <div class="page-content" v-if="!isAdvancedSearch">
        <div class="cp-container">
          <div class="cp-detail-new">
            <div class="cp-detail-plus" @click="goToIncompleteMeeting">
              <!-- <span class="mb-2"><img src="../assets/plus.svg" alt=""></span> -->
              <span class="text-bold">Incomplete Meetings</span>
              <span class="inc-meet">{{ meetingLead.incomplete_meetings_count }}</span>
            </div>
            <div class="cp-detail-plus cp-detail-add" @click="goToNewMeeting()">
              <span class="mb-2"><img src="../assets/groups.svg" alt=""></span>
              <span class="new-meet">Add New Meeting</span>
            </div>
          </div>
          <div class="search-box-main">
            <img src="../assets/search.svg" alt="" class="search-img-sub">
            <input type="text" v-model="search" @input="debouncedOnChange" placeholder="Search Broker name or Firm Name"
              class="text-input">
            <!-- <div @click="openAdvancedFilter()"><img src="" alt=""></div> -->
          </div>
        </div>
        <div class="advance-search-main px-3" @click="goToAdvanceSearch()">
          <span class="me-2 d-inline-block"><img src="../assets/advance-search.svg" alt=""></span>
          <span>ADVANCE SEARCH</span>
          <div class="opacity-50">Refine your Search Results!</div>
          <div><img class="advance-search" src="../assets/arrow.svg" alt=""></div>
        </div>
        <div class="cp-container">
          <div class="count">Meetings Count - <span class="text-bold">{{ meetingLead.meetings_count }}</span></div>
          <!-- <div class="cp-card-container" v-for="item in meetingLead.meetings" :key="item">
            <div class="cp-card">
              <div class="cp-card-content">
                <div class="cp-card-new-label">
                  <div>
                    <span class="label-des" v-if="item && item.executive_name">{{ item.executive_name }}</span>
                    <span class="new-label"><img src="../assets/star.svg" alt="">&nbsp;<span>{{ item.rating }}</span></span>
                  </div>
                  <div><img src="../assets/dots.svg" alt="" @click="goToMeetingDetail(item)"></div>
                </div>
                <div class="d-flex">
                  <div>Broker Name:</div>
                  <div>{{ item.broker_name }}</div>
                </div>
                <div class="d-flex">
                  <div>Firm Name:</div>
                  <div>{{ item.broker_firm_name }}</div>
                </div>
              </div>
              <div class="cp-actions-main">
                <div class="cp-actions-des">Start Time:<span>{{ item.start_time }}</span></div>
                <div class="cp-actions-des">End Time:<span>{{ item.end_time }}</span></div>
                <div class="audio-main" v-if="item && item.recording">
                  <audio controls>
                    <source :src="item.recording" type="audio/ogg">
                    <source :src="item.recording" type="audio/mpeg">
                    Your browser does not support the audio tag.
                  </audio>
                </div>
              </div>
            </div>
          </div> -->

          <div class="loader-wrapper" v-if="isLoading">
        <div class="loader">Loading...</div>
      </div>
          <div class="cp-card-container" v-for="item in meetingLead.meetings" :key="item">
            <div class="cp-card">
              <div class="cp-card-content">
                <div class="cp-card-new-label">
                  <div>
                    <span class="label-des">{{ item.executive_name }}</span>
                    <span class="new-label"><img src="../assets/star.svg" alt="">&nbsp;<span>{{ item.rating }}</span></span>
                  </div>
                  <div><img src="../assets/dots.svg" alt="" @click=" goToMeetingDetail(item)"></div>
                </div>
                <div class="d-flex">
                  <div>Broker Name:</div>
                  <div>{{ item.broker_name }}</div>
                </div>
                <div class="d-flex">
                  <div>Firm Name:</div>
                  <div>{{ item.broker_firm_name }}</div>
                </div>
              </div>
              <div class="cp-actions-main">
                <div class="cp-actions-des">Start Time:<span>{{ item.start_time }}</span></div>
                <div class="cp-actions-des">End Time:<span>{{ item.end_time }}</span></div>
                <div class="audio-main" v-if="item && item.recording">
                  <audio controls>
                    <source :src="item.recording" type="audio/ogg">
                    <source :src="item.recording" type="audio/mpeg">
                    Your browser does not support the audio tag.
                  </audio>
                  <div class="d-flex" v-if="item && item.recorded_audio_duration">
                  <!-- <div>Audio Duration:</div> -->
                  <div>{{ item.recorded_audio_duration }}</div>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-indicator">
              <span class="result-Text">Showing {{ pageStart }} - {{ pageEnd }} out of {{ total }}</span>
          </div>
          <div class="pagination-holder mt-4">
              <paginate  v-model="page" :page-count="totalPages" :page-range="3" :margin-pages="2" :click-handler="goToPage" :prev-text="'Prev'" :next-text="'Next'" :page-link-class="'page-link'" :prev-link-class="'page-link'" :next-link-class="'page-link'" :disabled-class="'page-item disabled'" :container-class="'pagination justify-content-center'" :page-class="'page-item'">
              </paginate>
          </div>
        </div>
      </div>
  
      <!-- side bar starts -->
      <div class="side-bar" v-if="isSideBarOpen">
        <div class="side-bar-container">
          <div class="d-flex justify-content-center flex-column main-container">
            <div class="cp-details clr justify-content-between">
              <h1 class="tele-calling-details-title">{{ userRole }}</h1>
              <img class="img-advanc" src="../assets/menu-close.svg" alt="" @click="toggleSideBar">
            </div>
            <div class="sidebar-txt">
              <div class="p-3 active" @click="goToHome">Manage Leads</div>
              <div class="p-3" @click=" goToCallAnalysis()">Call Analysis</div>
              <div @click="goToBrokers()" class="p-3">Brokers</div>
              <div class="p-3" @click=" goToProjects()">Projects</div>
              <div class="p-3" @click=" goToManageMeeting()">Manage Meetings</div>
            </div>
          </div>
          <div class="logout"><img src="../assets/logout.svg" alt="" @click="getlogout">
            Logout
          </div>
        </div>
        <div class="logout"><img src="../assets/logout.svg" alt="" @click="getlogout">
          Logout
        </div>
      </div>
    </div>
    <!-- advanced Search  -->
    <div class="adv-main-container" v-if="isAdvancedSearch">
      <div class="d-flex justify-content-center flex-column">
        <div class="top-navbar">
          <h1 class="">Advance Search</h1>
          <!-- <a @click="$router.go(-1)" class="back-arrow"> -->
          <img class="icon-left" src="../assets/close.svg" alt="" @click="closeAdvSearch">
          <!-- </a> -->
        </div>
        <div class="form-main page-content">
          <div class="date-picker-input mt-4">
            <VueDatePicker class="" id="datepicker1" v-model="meetingAdvanceSearch.start_time_gteq"
              placeholder="Display From"></VueDatePicker>
          </div>
          <div class="date-picker-input">
            <VueDatePicker class="" id="datepicker1" v-model="meetingAdvanceSearch.end_time_lteq"
              placeholder="Display Upto"></VueDatePicker>
          </div>
          <div>
            <input type="number" label="name" track-by="name" v-model="meetingAdvanceSearch.recorded_audio_duration_gteq"
              class="form-control" placeholder="Duration From (in minutes)" />
          </div>
          <div>
            <input type="number" label="name" track-by="name" v-model="meetingAdvanceSearch.recorded_audio_duration_lteq"
              class="form-control" placeholder="Duration Upto (in minutes)" :options="sourcesOptions"
              :canDeselect="false" />
          </div>
          <div>
            <Multiselect label="name" track-by="name" v-model="meetingAdvanceSearch.broker_id_in" :searchable="true"
              class="multi-select card-details-value card-details-select" :options="brokersOptions" mode="multiple"
              placeholder="Select Broker" :canDeselect="false" />
          </div>
          <div>
            <Multiselect label="name" track-by="name" :searchable="true" v-model="meetingAdvanceSearch.user_id_in"
              class="multi-select card-details-value card-details-select" :options="userOptions" mode="multiple"
              placeholder="Select Calling Executive" :canDeselect="false" />
          </div>
          <div>
            <Multiselect label="name" track-by="name" :searchable="true" v-model="meetingAdvanceSearch.revisited_eq"
              class="multi-select card-details-value card-details-select" :options="cpOptions"
              placeholder="Select Visit Status" :canDeselect="false" />
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="meetingAdvanceSearch.with_recording"
                  value="" id="default">
                <label class="form-check-label" for="default">
                  Audio Available?
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button type="button" class="bttn btn btn-clr" @click="clearAdvanceSearch">Clear All</button>
            </div>
          </div>
          <div class="footer-section">
            <button type="button" class="bttn btn search-clr" @click="advanceSearch()"> <img
                src="../assets/search-white.svg" alt="" /> Search</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import Multiselect from "@vueform/multiselect";
  import _debounce from 'lodash.debounce';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'managemeetings',
    components: {
      Multiselect
    },
    data() {
      return {
        isLoading:true,
        total: 0,
        perPage: 0,
        userRole: localStorage.getItem('tele-calling-name'),
        isSideBarOpen: false,
        leadData: {
  
        },
        buildersLeads: [],
        meetingAdvanceSearch: {
          start_time_gteq: "",
          end_time_lteq: "",
          recorded_audio_duration_gteq: "",
          recorded_audio_duration_lteq: "",
          broker_id_in: [],
          user_id_in: [],
          revisited_eq: "",
          with_recording: null
  
        },
        leadSearch: {
          // builders_leads:{
          name: '',
          phone: '',
          email: '',
          project_name: '',
          source: '',
          status: '',
          broker_status: '',
          broker_name: '',
          calling_executive: '',
          field_executive: '',
          ncd: null,
          created_at: '',
          updated_at: '',
          visit_details: [],
          booking_date: null,
          visited: '',
          revisited: '',
          is_qualified: ''
          // }
        },
        statusOptions: [],
        userOptions: [],
        sourcesOptions: [],
        projectsOptions: [],
        brokersOptions: [],
        executivesOptions: [],
        cpOptions: ["all","fresh visit","revisited"],
        isAdvancedSearch: false,
        isFromAdvSearch: false,
        meetingLead: [],
        searchLead: [],
        search: ""
      }
    },
    computed: {
      ...mapGetters(['loginData']),
      debouncedOnChange () {
        return _debounce(this.searchFilter, 700);
      },
        totalPages() {
            if (this.total === 0 || this.perPage === 0) {
                return 0;
            } else {
                return Math.ceil(this.total / this.perPage);
            }
        },
        page: {
            get() {
                let page = this.$route.query.page || 1
                return parseInt(page);
            },
            set() {
                true;
            }
        },
        pageStart() {
            if (this.total === 0) {
                return 0;
            }
            return (Math.max(0, (this.page - 1) * this.perPage + 1))
        },
        pageEnd() {
            if (this.total === 0) {
                return 0;
            }
            return (Math.min(this.total, this.page * this.perPage))
        },
    },
    watch: {
      search(newValue) {
        if (newValue === "") {
          this.manageLeads();
        }
      }
    },
    methods: {
      goToPage(page) {
          let newQ = {
              ...this.$route.query,
              page
          };
          this.$router.replace({
              query: newQ
          });
          this.isFromAdvSearch ? this.advanceSearch(newQ) : this.manageLeads(newQ);
      },
      clearAdvanceSearch() {
        this.meetingAdvanceSearch = {
          start_time_gteq: "",
          end_time_lteq: "",
          recorded_audio_duration_gteq: "",
          recorded_audio_duration_lteq: "",
          broker_id_in: [],
          user_id_in: [],
          revisited_eq: "",
          recorded_audio_present: null
        }
      },
      goToIncompleteMeeting() {
        this.$router.push({
          path: "/incompletemeeting",
          // query: {
          //     uuid: this.$route.query.uuid
          // }
        });
      },
      goToProjects() {
        this.$router.push({
          path: "/projects",
          query: {
            uuid: this.$route.query.uuid
          }
        });
      },
      goToManageMeeting() {
        this.$router.push({
          path: "/managemeetings",
          query: {
            uuid: this.$route.query.uuid
          }
        });
      },
      goToBrokers() {
        this.$router.push({
          path: "/broker",
          query: {
            uuid: this.$route.query.uuid,
          },
        });
      },
      goToCallAnalysis() {
        this.$router.push({
          path: "/callanalysis",
          query: {
            uuid: this.$route.query.uuid
          }
        });
      },
      goTOHome() {
        this.$router.push({
          path: "/home",
          query: {
            uuid: this.$route.query.uuid
          }
        });
      },
      openDialPad(phoneNo) {
        const phoneNumber = phoneNo;
        window.location.href = 'tel:' + phoneNumber;
      },
      toggleSideBar() {
        this.isSideBarOpen = !this.isSideBarOpen;
      },
      getlogout() {
        this.$axios.delete('logout').then(() => {
          localStorage.removeItem('cp-mandate-token');
          this.$router.replace('/');
        });
      },
      // getlogout() {
      //     this.$router.push({
      //         path: "/",
      //         query: {
      //             uuid: this.$route.query.uuid
      //         }
      //     });
      // },
      goToHistory(selectedDetails) {
        sessionStorage.setItem("selectedDetails", JSON.stringify(selectedDetails));
        this.$router.push({
          path: "/leadhistory",
          query: {
            uuid: this.$route.query.uuid
          }
        });
      },
      goToMeetingDetail(item) {
        sessionStorage.setItem("meetingData", JSON.stringify(item));
        this.$router.push({
          path: "/meetingdetails",
          query: {
            uuid: this.$route.query.uuid
          }
        });
      },
      goToNewMeeting() {
        this.$router.push({
          path: "/newmeeting1",
          query: {
            uuid: this.$route.query.uuid
          }
        });
      },
      goToHome() {
        this.$router.push({
          path: "/home",
          query: {
            uuid: this.$route.query.uuid
          }
        });
      },
      goToDetail(leads) {
        sessionStorage.setItem("selectedDetails", JSON.stringify(leads));
        this.$router.push({
          path: "/leaddetail",
          query: {
            uuid: this.$route.query.uuid
          }
        });
      },
      goToAdvanceSearch() {
        // this.$router.push({
        //     path: "/advancesearch",
        //     query: {
        //         uuid: this.$route.query.uuid
        //     }
        // });
        this.isAdvancedSearch = true;
      },
      closeAdvSearch() {
        this.isAdvancedSearch = false;
        this.manageLeads();
      },
      goToNewEnquary() {
        this.$router.push({
          path: "/newenquiry",
          query: {
            uuid: this.$route.query.uuid
          }
        });
      },
      getBuilderLeads(callType) {
        const params = {
          page: 1,
        };
        if (callType) {
          params[callType] = true;
        }
        this.$axios.get('builders/leads', {
          params
        }).then((response) => {
          this.leadData = response.data;
          callType ? this.buildersLeads = response.data[callType] : this.buildersLeads = response.data.builders_leads;
        });
      },
      advanceSearch(newQ) {
        this.isFromAdvSearch = true;
        this.meetingLead = [];
        let pageNo = 1;
        pageNo = newQ?.page;
        this.meetingAdvanceSearch.revisited_eq = this.meetingAdvanceSearch.revisited_eq === "fresh visit" ? false : this.meetingAdvanceSearch.revisited_eq === "revisited" ? true : ""
        this.$axios.post('meetings/search', {
          q: this.meetingAdvanceSearch,
          page: pageNo
        }).then(response => {
          this.meetingLead = response.data;
          this.perPage = response.data.per_page;
          this.total = response.data.meetings_count;
          window.scrollTo(0,0);
          console.log("meetingData", this.meetingLead);
          this.isAdvancedSearch = false;
        })
          .catch(err => {
            console.log(err);
          })
      },
      searchFilter() {
        const params = {
          bs: this.search
        };
        this.$axios.get('meetings', { params }).then((response) => {
          console.log(response.data, "meetings")
          this.meetingLead = response.data;
        }).catch(err => console.log(err));
      },
      getAllData() {
        this.$axios.get('builders/leads/meta_data').then((response) => {
          this.statusOptions = response.data.status;
          this.userOptions = response.data.users;
          this.sourcesOptions = response.data.sources;
          this.projectsOptions = response.data.projects;
          this.brokersOptions = response.data.brokers;
          this.executivesOptions = response.data.executives;
          // this.cpOptions = response.data.cp_status;
        })
        .catch(err => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          })
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        })
      },
      manageLeads(newQ) {
        this.isFromAdvSearch = false;
        const params = {
            page: 1,
        };
        params["page"] = newQ?.page;
        this.$axios.get('meetings', { params }).then((response) => {
          this.isLoading = false;
          this.meetingLead = response.data;
          this.perPage = response.data.per_page;
          this.total = response.data.complete_meetings_count;
          window.scrollTo(0,0);
          // this.statusOptions = response.data.status;
          // this.userOptions = response.data.users;
          // this.sourcesOptions = response.data.sources;
          // this.projectsOptions = response.data.projects;
          // this.brokersOptions = response.data.brokers;
          // this.executivesOptions = response.data.executives;
          // this.cpOptions = response.data.cp_status;
        })
        .catch(err => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          })
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        })
      }
    },
    mounted() {
      //this.getBuilderLeads(null);
      //this.advanceSearch();
      this.getAllData();
      this.manageLeads()
    }
  }
  </script>
  
  <style scoped>
  /* side bar starts */
  .side-bar {
    position: fixed;
    width: 100%;
    right: 0;
    top: 0;
    height: 100vh;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
  }
  
  .side-bar-container {
    width: 80%;
    background: #fff;
    position: relative;
    left: 20%;
    height: 100vh;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .audio-main {
    padding: 15px;
  }
  
  .logout {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    color: #EE4B4B;
    padding-bottom: 20px;
  }
  
  .sidebar-txt {
    display: flex;
    color: #888888;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    background: white;
    padding: 0 10px;
  }
  
  .sidebar-txt div {
    color: #888;
    font-size: 16px;
    border-bottom: 1px solid #F1E9E9;
    letter-spacing: 0.5px;
  }
  
  .sidebar-txt .active {
    color: #19A7CE;
    font-weight: 600;
  }
  
  /* sidebar ends */
  
  .cp-container {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .cp-detail {
    display: flex;
    justify-content: space-around;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .cp-detail-new {
    display: flex;
    justify-content: space-around;
    gap: 15px;
    margin-bottom: 32px;
  }
  
  .text-bold {
    font-weight: 600;
  }
  
  .inc-meet {
    font-size: 20px;
    font-weight: 800;
    line-height: 29px;
    letter-spacing: 0em;
    color: #000000;
  
  }
  
  .new-meet {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
  
  }
  
  .main-container {
    background-color: #F6F1F1;
  }
  
  .cp-details {
    padding: 16px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 4px 0px #0000000D;
    background-color: #fff;
  }
  
  .clr {
    background: #F1E9E9 !important;
  
  }
  
  /* .clor{
          background-color: #fff !important;
      } */
  
  .img {
    position: absolute;
    left: 16px;
  }
  
  .cp-detail-txt {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 2px 4px 0px #00000014;
    justify-content: space-between;
  }
  
  .cp-detail-plus {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 8px;
    flex: 1;
    justify-content: space-between;
    box-shadow: 0px 2px 4px 0px #00000014;
  
  }
  
  .cp-detail-add {
    background: #EE964B;
    color: #FFFFFF;
  }
  
  .tele-calling-details-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    color: #000000;
    margin-bottom: 0;
    letter-spacing: 0.35px;
  }
  
  .search-box-main {
    margin-top: 15px;
    position: relative;
  }
  
  .search-box-main input {
    box-shadow: 0px 1px 4px 0px #0000000D;
    background: #FFFFFF;
    border-radius: 40px;
    width: 100%;
    border: 0;
    padding: 14px 16px;
    border: 1px solid transparent;
  }
  
  .search-box-main input:focus {
    outline: none;
    border: 1px solid #19A7CE;
  }
  
  .search-img-sub {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .search-img {
    /* display: flex;
          width: 100%;
          border: 1px solid #dcdcdc;
          border-radius: 5px;
          background-color: #fff; */
  }
  
  .text-input {}
  
  .advance-search {
    position: absolute;
    right: 22px;
    top: 20px;
  }
  
  .advance-search-main {
    position: relative;
    padding: 13px 0 12px 16px;
    border-top: 1px solid #A77272;
    border-bottom: 1px solid #A77272;
    letter-spacing: 0.56px;
    color: #763A3A;
    background: #F1E9E9;
  }
  
  .count {
    padding-top: 16px;
    padding-left: 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    color: #A77272;
    margin-bottom: 12px;
  }
  
  .actions-main {
    display: flex;
    gap: 15px;
  }
  
  .actions-main div {
    border: 1px solid #19A7CE;
    background: #fff;
    border-radius: 34px;
    padding: 5px 16px;
    flex: 1;
    text-align: center;
    color: #19A7CE;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  
  .actions-main .active {
    background: #19A7CE;
    color: #FFFFFF;
  }
  
  .cp-actions-main {
    padding: 15px;
  }
  
  .cp-actions-des {
    text-align: center;
    font-weight: 400;
    color: #000;
    line-height: 17.34px;
    font-size: 12px;
    padding-bottom: 15px;
  }
  
  .cp-actions-des span {
    font-weight: 600;
  }
  
  .cp-card-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .cp-card {
    box-shadow: 0px 1px 4px 0px #0000000D;
    background: #fff;
    border-radius: 16px;
  }
  
  .cp-card-new-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
  }
  
  .cp-card-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: 400;
    color: #888888;
    padding: 16px;
    border-bottom: 1px dashed #B9B9B9;
  }
  
  .label-des {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    display: inline-block;
    margin-right: 6px;
  }
  
  .new-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    background: #E3F8EC;
    padding: 1px 4px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    color: #EE964B;
  }
  
  /* advance search  */
  .adv-main-container {
    background-color: #f1e9e9;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
  }
  
  .checkbox-main {
    align-items: center;
    gap: 10px;
    flex: 1;
    margin-top: 10px;
  }
  
  .form-main {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    background: #FFFFFF;
  }
  
  .img-advanc {
    position: absolute;
    right: 16px;
  }
  
  .card-details-value {
    border-radius: 8px !important;
    /* opacity: 0.5; */
    padding: 12px 20px;
    color: #bfbfbf;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    border: 1px solid #B9B9B9;
    padding: 15px;
  }
  
  .card-details-select {
    padding: 5px 15px;
  }
  
  .btn-clr {
    /* background-color: #19A7CE; */
    width: 100%;
    color: #19a7ce;
    border: 1px solid #19a7ce;
    border-radius: 34px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    padding: 12px;
    margin-bottom: 40px;
  }
  
  .search-clr {
    width: 100%;
    background-color: #19a7ce;
    /* border: 1px solid #19A7CE; */
    color: #ffffff;
    border-radius: 34px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  
  .submit-section {
    position: relative;
  }
  
  .user-input-wrp {
    position: relative;
    border: 1px solid #B9B9B9;
    border-radius: 8px;
    width: 100%;
    background: white;
  }
  
  .user-input-wrp .inputText {
    width: 100%;
    outline: none;
    border: none;
    /* border-bottom: 1px solid #777; */
    box-shadow: none !important;
    padding-bottom: 3px;
    padding-left: 16px;
  }
  
  .user-input-wrp .inputText:focus {
    border-color: blue;
    border-width: medium medium 2px;
  }
  
  .user-input-wrp .floating-label {
    position: absolute;
    pointer-events: none;
    top: 18px;
    left: 10px;
    transition: 0.2s ease all;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    color: #888888;
  }
  
  .user-input-wrp input:focus~.floating-label,
  .user-input-wrp input:not(:focus):valid~.floating-label {
    top: 6px;
    left: 16px;
    font-size: 13px;
    opacity: 1;
  }
  </style>