<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main-container bg-body">
    <div class="top-navbar">
      <a @click="$router.go(-1)" class="icon-left">
        <img src="../assets/home.svg" alt="" />
      </a>
      <h1 class="tele-calling-details-title">Call Analysis</h1>
      <img
        class="icon-right"
        src="../assets/menu-open.svg"
        alt=""
        @click="toggleSideBar"
      />
    </div>
    <div class="page-content">
      <div class="cp-container" v-if="!isAdvancedSearch">
        <div class="search-box-main">
          <img src="../assets/search.svg" alt="" class="search-img-sub" />
          <input
            type="text"
            v-model="search"
            @input="debouncedOnChange"
            placeholder="Search by lead name or executive name"
            class="text-input"
          />
          <!-- <div @click="openAdvancedFilter()"><img src="" alt=""></div> -->
        </div>
      </div>
      <div class="advance-search-main px-3" @click="goToAdvanceSearch()">
        <span class="me-2 d-inline-block"
          ><img src="../assets/advance-search.svg" alt=""
        /></span>
        <span>ADVANCE SEARCH</span>
        <div class="opacity-50">Refine your Search Results!</div>
        <div>
          <img class="advance-search" src="../assets/arrow.svg" alt="" />
        </div>
      </div>
      <div class="count ps-4">
        Call Logs Count -
        <span class="text-bold">{{ callLogsDetails.call_logs_count }}</span>
      </div>
      <div
        class="lable-card mx-3"
        v-for="(callData, index) in callLogsDetails.call_logs"
        :key="index"
      >
        <div class="cpl">
          <div
            class="d-flex justify-content-between align-items-center"
            v-if="callData && callData.lead_name"
          >
            <div>
              <div class="main-name">{{ callData.lead_name }}</div>
            </div>
            <div class="number">{{ callData.direction }}</div>
          </div>
          <div class="d-flex incoming-start-end-date">
            <div class="d-flex" v-if="callData && callData.to_number">
              <div class="number">{{ callData.from_number }}</div>
              <img class="img-card" src="../assets/blue-arrow.svg" alt="" />
              <div class="number-side">{{ callData.to_number }}</div>
            </div>
            <div class="d-flex" v-if="callData && callData.executive_name">
              <div class="number">Executive:&nbsp;</div>
              <div class="broker-name">{{ callData.executive_name }}</div>
            </div>
          </div>
        </div>
        <div class="audio-main" v-if="callData && callData.recording_url">
          <audio controls class="w-100">
            <source :src="callData.recording_url" type="audio/ogg" />
            <source :src="callData.recording_url" type="audio/mpeg" />
            Your browser does not support the audio tag.
          </audio>
        </div>
      </div>
      <div class="pagination-indicator">
        <span class="result-Text"
          >Showing {{ pageStart }} - {{ pageEnd }} out of {{ total }}</span
        >
      </div>
      <div class="pagination-holder mt-4">
        <paginate
          v-model="page"
          :page-count="totalPages"
          :page-range="3"
          :margin-pages="2"
          :click-handler="goToPage"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :page-link-class="'page-link'"
          :prev-link-class="'page-link'"
          :next-link-class="'page-link'"
          :disabled-class="'page-item disabled'"
          :container-class="'pagination justify-content-center'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </div>
    <div class="loader-wrapper" v-if="isLoading">
      <div class="loader">Loading...</div>
    </div>
    <!-- side bar starts -->
    <div class="side-bar" v-if="isSideBarOpen">
      <div class="side-bar-container">
        <div class="d-flex justify-content-center flex-column main-container">
          <div class="cp-details clr justify-content-between">
            <h1 class="tele-calling-details-title">{{ userRole }}</h1>
            <img
              class="img-advanc"
              src="../assets/menu-close.svg"
              alt=""
              @click="toggleSideBar"
            />
          </div>
          <div class="sidebar-txt">
            <div class="p-3 active" @click="goTOHome">Manage Leads</div>
            <div class="p-3" @click="goToCallAnalysis()">Call Analysis</div>
            <div @click="goToBrokers()" class="p-3">Brokers</div>
            <div class="p-3" @click="goToProjects()">Projects</div>
            <div class="p-3" @click="goToManageMeeting()">Manage Meetings</div>
          </div>
        </div>
        <div class="logout">
          <img src="../assets/logout.svg" alt="" @click="getlogout" />
          Logout
        </div>
      </div>
    </div>
    <!-- advanced Search  -->
    <div class="adv-main-container full-height" v-if="isAdvancedSearch">
      <div class="top-navbar">
        <h1>Advance Search</h1>
        <img
          class="icon-left"
          src="../assets/close.svg"
          alt=""
          @click="closeAdvSearch"
        />
      </div>
      <div
        class="d-flex justify-content-center flex-column main-container page-content"
      >
        <div class="form-main pt-4">
          <div class="date-picker-input">
            <VueDatePicker
              class=""
              id="datepicker2"
              v-model="callLogAdvanceSearch.start_time_gteq"
              placeholder="Time From"
            ></VueDatePicker>
          </div>
          <div class="date-picker-input">
            <VueDatePicker
              class=""
              id="datepicker2"
              v-model="callLogAdvanceSearch.end_time_lteq"
              placeholder="Time To"
            ></VueDatePicker>
          </div>
          <div class="form-floating cp-form">
            <input
              type="text"
              class="form-control"
              v-model="callLogAdvanceSearch.lead_lead_no_cont"
              id="lead_number"
              placeholder="Lead Number"
            />
            <label for="lead_number">Lead Number</label>
          </div>
          <div>
            <Multiselect
              label="name"
              track-by="name"
              v-model="callLogAdvanceSearch.in_direction"
              :searchable="true"
              class="multi-select card-details-value card-details-select"
              :options="directionOptions"
              placeholder="Select Direction"
              :canDeselect="false"
            />
          </div>
          <div>
            <Multiselect
              label="name"
              track-by="name"
              v-model="callLogAdvanceSearch.in_status"
              :searchable="true"
              class="multi-select card-details-value card-details-select"
              placeholder="Select Status"
              :options="callLogStatus"
              :canDeselect="false"
            />
          </div>
          <div>
            <Multiselect
              label="name"
              track-by="name"
              v-model="callLogAdvanceSearch.third_party_id_in"
              :searchable="true"
              class="multi-select card-details-value card-details-select"
              :options="venderOptions"
              placeholder="Select Vendor"
              :canDeselect="false"
            />
          </div>
          <div class="form-group my-3 submit-section-clr">
            <button
              type="button"
              class="bttn btn-clr"
              @click="clearAdvanceSearch"
            >
              Clear All
            </button>
          </div>
        </div>
        <div class="footer-section">
          <button
            type="button"
            class="bttn btn search-clr"
            @click="advanceSearch()"
          >
            <img src="../assets/search-white.svg" alt="" /> Search
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { mapGetters } from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import _debounce from "lodash.debounce";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "callanalysis",
  components: {
    Multiselect,
    VueDatePicker,
  },
  data() {
    return {
      isLoading: true,
      total: 0,
      perPage: 0,
      userRole: localStorage.getItem("tele-calling-name"),
      search: "",
      isSideBarOpen: false,
      callLogAdvanceSearch: {
        start_time_gteq: "",
        end_time_lteq: "",
        in_direction: "",
        in_status: "",
        lead_lead_no_cont: "",
        third_party_id_in: [],
      },
      leadData: {},
      data: null,
      date1: null,
      date2: null,
      buildersLeads: [],
      leadSearch: {
        // builders_leads:{
        name: "",
        phone: "",
        email: "",
        project_name: "",
        source: "",
        status: "",
        broker_status: "",
        broker_name: "",
        calling_executive: "",
        field_executive: "",
        ncd: null,
        created_at: "",
        updated_at: "",
        visit_details: [],
        booking_date: null,
        visited: "",
        revisited: "",
        is_qualified: "",
        // }
      },
      statusOptions: [],
      userOptions: [],
      sourcesOptions: [],
      projectsOptions: [],
      brokersOptions: [],
      executivesOptions: [],
      cpOptions: [],
      callLogsDetails: [],
      callLogStatus: [],
      venderOptions: ["Exotel", "Mcube"],
      directionOptions: ["Incoming", "Outbound"],
      isAdvancedSearch: false,
      isFromAdvSearch: false,
      assignTO: false,
    };
  },
  computed: {
    ...mapGetters(["loginData"]),
    debouncedOnChange() {
      return _debounce(this.searchFilter, 700);
    },
    totalPages() {
      if (this.total === 0 || this.perPage === 0) {
        return 0;
      } else {
        return Math.ceil(this.total / this.perPage);
      }
    },
    page: {
      get() {
        let page = this.$route.query.page || 1;
        return parseInt(page);
      },
      set() {
        true;
      },
    },
    pageStart() {
      if (this.total === 0) {
        return 0;
      }
      return Math.max(0, (this.page - 1) * this.perPage + 1);
    },
    pageEnd() {
      if (this.total === 0) {
        return 0;
      }
      return Math.min(this.total, this.page * this.perPage);
    },
  },
  methods: {
    goToPage(page) {
      let newQ = {
        ...this.$route.query,
        page,
      };
      this.$router.replace({
        query: newQ,
      });
      this.isFromAdvSearch
        ? this.advanceSearch(newQ)
        : this.getAllCallLogs(newQ);
    },
    openDialPad(phoneNo) {
      const phoneNumber = phoneNo;
      window.location.href = "tel:" + phoneNumber;
    },
    toggleSideBar() {
      this.isSideBarOpen = !this.isSideBarOpen;
    },
    getlogout() {
      this.$axios
        .delete("logout")
        .then(() => {
          localStorage.removeItem("cp-mandate-token");
          this.$router.replace("/");
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    goToProjects() {
      this.$router.push({
        path: "/projects",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToManageMeeting() {
      this.$router.push({
        path: "/managemeetings",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToCallAnalysis() {
      this.$router.push({
        path: "/callanalysis",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToHistory(selectedDetails) {
      sessionStorage.setItem(
        "selectedDetails",
        JSON.stringify(selectedDetails)
      );
      this.$router.push({
        path: "/leadhistory",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToMeetingDetail() {
      this.$router.push({
        path: "/meetingdetails",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToNewMeeting() {
      this.$router.push({
        path: "/newmeeting",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToHome() {
      this.$router.push({
        path: "/home",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToDetail(leads) {
      sessionStorage.setItem("selectedDetails", JSON.stringify(leads));
      this.$router.push({
        path: "/leaddetail",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToBrokers() {
      this.$router.push({
        path: "/broker",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToAdvanceSearch() {
      // this.$router.push({
      //     path: "/advancesearch",
      //     query: {
      //         uuid: this.$route.query.uuid
      //     }
      // });
      this.isAdvancedSearch = true;
    },
    closeAdvSearch() {
      this.isAdvancedSearch = false;
    },

    goToassigTO() {
      this.assignTO = true;
    },
    closeAssiTo() {
      this.assignTO = false;
    },
    goToNewEnquary() {
      this.$router.push({
        path: "/newenquiry",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    getBuilderLeads(callType) {
      const params = {
        page: 1,
      };
      if (callType) {
        params[callType] = true;
      }
      this.$axios
        .get("builders/leads", {
          params,
        })
        .then((response) => {
          console.log(response.data, "response");
          this.leadData = response.data;
          callType
            ? (this.buildersLeads = response.data[callType])
            : (this.buildersLeads = response.data.builders_leads);
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    // advanceSearch() {
    //     console.log()
    //     this.$axios.post('builders/leads/search', {
    //             q: this.leadSearch
    //         }).then(res => {
    //             console.log(res, "Res")
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // },
    getAllData() {
      this.$axios
        .get("builders/leads/meta_data")
        .then((response) => {
          console.log(response.data, "status");
          this.statusOptions = response.data.status;
          this.userOptions = response.data.users;
          this.sourcesOptions = response.data.sources;
          this.projectsOptions = response.data.projects;
          this.brokersOptions = response.data.brokers;
          this.executivesOptions = response.data.executives;
          this.cpOptions = response.data.cp_status;
          this.callLogStatus = response.data.call_logs_status;
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    clearAdvanceSearch() {
      this.callLogAdvanceSearch = {
        start_time_gteq: "",
        end_time_lteq: "",
        in_direction: "",
        in_status: "",
        lead_lead_no_cont: "",
        third_party_id_in: "",
      };
      this.getAllCallLogs();
    },
    getAllCallLogs(newQ) {
      this.isFromAdvSearch = false;
      const params = {
        page: 1,
      };
      params["page"] = newQ?.page;
      this.$axios
        .get("call_logs", {
          params,
        })
        .then((response) => {
          console.log(response.data, "status");
          this.isLoading = false;
          this.callLogsDetails = response.data;
          this.perPage = response.data.per_page;
          this.total = response.data.call_logs_count;
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    advanceSearch(newQ) {
      this.isFromAdvSearch = true;
      this.callLogsDetails = [];
      let pageNo = 1;
      pageNo = newQ?.page;
      this.callLogAdvanceSearch.third_party_id_in =
        this.callLogAdvanceSearch.third_party_id_in === "Exotel"
          ? 1
          : this.callLogAdvanceSearch.third_party_id_in === "Mcube"
          ? 2
          : "";
      this.callLogAdvanceSearch.in_direction =
        this.callLogAdvanceSearch.in_direction.toLowerCase();
      this.$axios
        .post("call_logs/search", {
          q: this.callLogAdvanceSearch,
          page: pageNo,
        })
        .then((response) => {
          this.callLogsDetails = response.data;
          this.perPage = response.data.per_page;
          this.total = response.data.call_logs_count;
          this.isAdvancedSearch = false;
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          this.$swal({
            title: err.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    searchFilter() {
      const params = {
        bs: this.search,
      };
      this.$axios
        .get("call_logs", {
          params,
        })
        .then((response) => {
          this.callLogsDetails = response.data;
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
  },
  mounted() {
    this.getBuilderLeads(null);
    this.advanceSearch();
    this.getAllData();
    this.getAllCallLogs();
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style><style scoped>
.full-height {
  min-height: 100vh;
  min-height: 100dvh;
}

.main-name {
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0em;
  color: #000000;
}

.broker-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #888888;
}

.incoming-start-end-date {
  flex-direction: column;
}

.img-card {
  padding: 0px 5px 0px 5px;
}

.number-side {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #000000;
}

.lable-card {
  margin: 8px;
  background: #ffffff;
  border-radius: 16px;
  border: none;
}

.number {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #888888;
}

.cpl {
  border-bottom: 0.75px dotted #b9b9b9;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.assign-to-form {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

/* side bar starts */
.assign-to {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  background: #19a7ce;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 34px;
  padding: 12px;
  margin-top: 60px;
}

.assign-to-card {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -2px 4px 0px #0000000d;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.head-name {
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0em;
  color: #000000;
}

.side-bar {
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  height: 100vh;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.side-bar-container {
  width: 80%;
  background: #fff;
  position: relative;
  left: 20%;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.audio-main {
  padding: 15px;
}

.logout {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.12px;
  color: #ee4b4b;
  padding-bottom: 20px;
}

.initiation {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  color: #763a3a;
}

.sidebar-txt {
  display: flex;
  color: #888888;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  background: white;
  padding: 0 10px;
}

.sidebar-txt div {
  color: #888;
  font-size: 16px;
  border-bottom: 1px solid #f1e9e9;
  letter-spacing: 0.5px;
}

.sidebar-txt .active {
  color: #19a7ce;
  font-weight: 600;
}

/* sidebar ends */

.cp-container {
  padding: 15px;
}

.cp-detail {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  margin-bottom: 15px;
}

.cp-detail-new {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  margin-bottom: 32px;
}

.text-bold {
  font-weight: 600;
}

.inc-meet {
  font-size: 20px;
  font-weight: 800;
  line-height: 29px;
  letter-spacing: 0em;
  color: #000000;
}

.new-meet {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}

.bg-body {
  background: #f6f1f1;
  min-height: 100vh;
  min-height: 100dvh;
}

.cp-details {
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px 0px #0000000d;
  background-color: #fff;
}

.clr {
  background: #f1e9e9 !important;
}

/* .clor{
                        background-color: #fff !important;
                    } */

.close {
  position: absolute;
  left: 16px;
}

.cp-detail-txt {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 2px 4px 0px #00000014;
  justify-content: space-between;
}

.cp-detail-plus {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 8px;
  flex: 1;
  justify-content: space-between;
  box-shadow: 0px 2px 4px 0px #00000014;
}

.cp-detail-add {
  background: #ee964b;
  color: #ffffff;
}

.tele-calling-details-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  color: #000000;
  margin-bottom: 0;
  letter-spacing: 0.35px;
}

.search-box-main {
  position: relative;
}

.search-box-main input {
  box-shadow: 0px 1px 4px 0px #0000000d;
  background: #ffffff;
  border-radius: 40px;
  width: 100%;
  border: 0;
  padding: 14px 16px;
  border: 1px solid transparent;
}

.search-box-main input:focus {
  outline: none;
  border: 1px solid #19a7ce;
}

.search-img-sub {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.search-img {
  /* display: flex;
                        width: 100%;
                        border: 1px solid #dcdcdc;
                        border-radius: 5px;
                        background-color: #fff; */
}

.text-input {
}

.advance-search {
  position: absolute;
  right: 22px;
  top: 20px;
}

.advance-search-main {
  position: relative;
  padding: 13px 0 12px 16px;
  border-top: 1px solid #a77272;
  border-bottom: 1px solid #a77272;
  letter-spacing: 0.56px;
  color: #763a3a;
  background: #f1e9e9;
}

.count {
  padding-top: 16px;
  padding-left: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #a77272;
  margin-bottom: 12px;
}

.actions-main {
  display: flex;
  gap: 15px;
}

.actions-main div {
  border: 1px solid #19a7ce;
  background: #fff;
  border-radius: 34px;
  padding: 5px 16px;
  flex: 1;
  text-align: center;
  color: #19a7ce;
  display: flex;
  gap: 5px;
  align-items: center;
}

.actions-main .active {
  background: #19a7ce;
  color: #ffffff;
}

.cp-actions-main {
  padding: 15px;
}

.cp-actions-des {
  text-align: center;
  font-weight: 400;
  color: #000;
  line-height: 17.34px;
  font-size: 12px;
  padding-bottom: 15px;
}

.cp-actions-des span {
  font-weight: 600;
}

.cp-card-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cp-card {
  box-shadow: 0px 1px 4px 0px #0000000d;
  background: #fff;
  border-radius: 16px;
}

.cp-card-new-label {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.cp-card-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 400;
  color: #888888;
  padding: 16px;
  /* border-bottom: 1px dashed #B9B9B9; */
}

.label-des {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  display: inline-block;
  margin-right: 6px;
}

.new-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #888888;
  background: #e3f8ec;
  padding: 1px 4px;
  border-radius: 4px;
  display: inline-block;
}

/* advance search  */
.adv-main-container {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  background-color: #fff;
}

.checkbox-main {
  align-items: center;
  gap: 10px;
  flex: 1;
  margin-top: 10px;
}

.form-main {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  background: #ffffff;
}

.img-advanc {
  position: absolute;
  right: 16px;
}

.card-details-value {
  border-radius: 8px !important;
  /* opacity: 0.5; */
  padding: 12px 20px;
  color: #bfbfbf;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  border: 1px solid #b9b9b9;
  padding: 15px;
}

.card-details-select {
  padding: 5px 15px;
}

/* .text {
                margin: 16px;
            } */

.btn-clr {
  /* background-color: #19A7CE; */
  width: 100%;
  color: #19a7ce;
  border: 1px solid #19a7ce;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  padding: 12px;
  background-color: #fff;
}

.search-clr {
  width: 100%;
  background-color: #19a7ce;
  /* border: 1px solid #19A7CE; */
  color: #ffffff;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.submit-section {
  position: relative;
}

.user-input-wrp {
  position: relative;
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  width: 100%;
  background: white;
}

.user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  /* border-bottom: 1px solid #777; */
  box-shadow: none !important;
  padding-bottom: 3px;
  padding-left: 16px;
}

.user-input-wrp .inputText:focus {
  border-color: blue;
  border-width: medium medium 2px;
}

.user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 18px;
  left: 10px;
  transition: 0.2s ease all;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #888888;
}

.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:not(:focus):valid ~ .floating-label {
  top: 6px;
  left: 16px;
  font-size: 13px;
  opacity: 1;
}

/* date picker start */
.date-picker-input {
  position: relative;
}

/* date picker end */
.submit-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #ffffff;
  background: #19a7ce;
  flex: 1;
  border-radius: 34px;
  padding: 12px;
  margin: 16px;
  display: flex;
  justify-content: center;
}

.cancle-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #19a7ce;
  border: 1px solid #19a7ce;
  border-radius: 34px;
  flex: 1;
  padding: 12px;
  margin: 16px;
  display: flex;
  justify-content: center;
}

.cp-detail-new {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  margin-bottom: 32px;
}

.cp-detail-plus {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 8px;
  flex: 1;
  justify-content: space-between;
  box-shadow: 0px 2px 4px 0px #00000014;
}

.all-broker {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #888888;
}

.inc-meet {
  font-size: 20px;
  font-weight: 800;
  line-height: 29px;
  letter-spacing: 0em;
  color: #000000;
}
</style>
