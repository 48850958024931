<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main-container">
    <div class="top-navbar">
      <h1>Lead</h1>
      <a class="icon-right" @click="toggleSideBar">
        <img src="../assets/menu-open.svg" alt="" />
      </a>
    </div>
    <div class="page-content">
      <div class="cp-container" v-if="!isAdvancedSearch">
        <div class="cp-detail" v-if="leadData">
          <div class="cp-detail-txt" @click="getBuilderLeads(null)">
            <span>All Leads</span>
            <span class="fw-bold">{{ leadData.count }}</span>
          </div>
          <div
            class="cp-detail-txt"
            v-if="callType !== 'backlogs_leads' && callType !== 'todays_leads'"
            @click="getBuilderLeads('todays_leads')"
          >
            <span>Today's Call</span>
            <span class="text-bold">{{ leadData?.todays_leads_count }}</span>
          </div>
          <div
            class="cp-detail-txt"
            v-if="callType !== 'backlogs_leads' && callType !== 'todays_leads'"
            @click="getBuilderLeads('backlogs_leads')"
          >
            <span>Backlogs</span>
            <span class="text-bold">{{ leadData?.backlogs_count }}</span>
          </div>
        </div>
        <div class="cp-detail-new">
          <template v-if="allMetaData && !allMetaData.is_executive">
            <div class="cp-detail-plus" @click="goTOCallingExecutive()">
              <span class="mb-2"><img src="../assets/plus.svg" alt="" /></span>
              <span class="text-bold">Assign Calling Executive</span>
            </div>
            <div class="cp-detail-plus" @click="goTOFieldExecutive()">
              <span class="mb-2"><img src="../assets/plus.svg" alt="" /></span>
              <span class="text-bold">Assign Field Executives</span>
            </div>
          </template>
          <div class="cp-detail-plus cp-detail-add" @click="goToNewEnquary()">
            <span class="mb-2"
              ><img src="../assets/contacts.svg" alt=""
            /></span>
            <span class="text-bold">Add New&nbsp;Lead</span>
          </div>
        </div>
        <div class="search-box-main">
          <img src="../assets/search.svg" alt="" class="search-img-sub" />
          <input
            type="text"
            v-model="search"
            @input="searchFilter()"
            placeholder="Search lead name, email or phone "
            class="text-input"
          />
          <!-- <div @click="openAdvancedFilter()"><img src="" alt=""></div> -->
        </div>
      </div>
      <div class="advance-search-main px-3" @click="goToAdvanceSearch()">
        <span class="me-2 d-inline-block"
          ><img src="../assets/advance-search.svg" alt=""
        /></span>
        <span>ADVANCE SEARCH</span>
        <div class="opacity-50">Refine your Search Results!</div>
        <div>
          <img class="advance-search" src="../assets/arrow.svg" alt="" />
        </div>
      </div>
      <div class="cp-container" v-if="leadData && !isAdvancedSearch">
        <div class="count">
          Leads Count -
          <span class="text-bold">
            <template
              v-if="
                callType !== 'todays_leads' && callType !== 'backlogs_leads'
              "
              >{{ leadData.count }}</template
            >
            <template v-if="callType == 'todays_leads'">{{
              leadData.todays_leads_count
            }}</template>
            <template v-if="callType == 'backlogs_leads'">{{
              leadData.backlogs_count
            }}</template>
          </span>
        </div>
        <div class="cp-card-container">
          <div class="cp-card" v-for="leads in buildersLeads" :key="leads">
            <div class="cp-card-content">
              <div class="cp-card-new-label">
                <div>
                  <span class="label-des">{{ leads.name }}</span>
                  <span class="new-label">{{ leads.status }}</span>
                </div>
                <div>
                  <img
                    src="../assets/dots.svg"
                    alt=""
                    @click="goToDetail(leads)"
                  />
                </div>
              </div>
              <div class="cp-project-name">{{ leads.project_name }}</div>
              <div class="cp-calling-exe">
                Calling Executive: <span>{{ leads.calling_executive }}</span>
              </div>
            </div>
            <div class="cp-actions-main">
              <div class="cp-actions-des">
                Next Call Date: <span>{{ leads.updated_at }}</span>
              </div>
              <div class="actions-main">
                <div @click="goToEdit(leads)">
                  <img src="../assets/edit.svg" alt="" /> Edit
                </div>
                <div @click="goToHistory(leads)" v-if="callEnabled">
                  <img src="../assets/time.svg" alt="" /> History
                </div>
                <div
                  @click="openDialPad(leads)"
                  class="actions-main active"
                  v-if="callEnabled"
                >
                  <img src="../assets/call.svg" alt="" /> Call
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-indicator">
            <span class="result-Text"
              >Showing {{ pageStart }} - {{ pageEnd }} out of {{ total }}</span
            >
          </div>
          <div class="pagination-holder mt-4">
            <paginate
              v-model="page"
              :page-count="totalPages"
              :page-range="3"
              :margin-pages="2"
              :click-handler="goToPage"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :page-link-class="'page-link'"
              :prev-link-class="'page-link'"
              :next-link-class="'page-link'"
              :disabled-class="'page-item disabled'"
              :container-class="'pagination justify-content-center'"
              :page-class="'page-item'"
            >
            </paginate>
          </div>
        </div>
      </div>
    </div>

    <!-- <transition name="modal"> -->
    <div class="modal-mask" v-if="showModal">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <!-- <slot name="header"> default header </slot> -->
          </div>

          <div class="modal-body">
            <template v-if="!isDialingFailed">
              <div v-if="isDialing">
                <img class="w-100" src="../assets/EnDialup.gif" alt="" />
              </div>
              <div v-if="!isDialing">
                <img class="w-100" src="../assets/EnConnected.gif" alt="" />
              </div>
            </template>
            <template v-if="isDialingFailed"
              ><img class="w-100" src="../assets/EnFailed.gif" alt=""
            /></template>
          </div>
          <div></div>

          <!-- <div class="modal-footer">
              <slot name="footer">
                default footer
                <button class="modal-default-button" @click="showModal = false">
                  OK
                </button>
              </slot>
            </div> -->
        </div>
      </div>
    </div>
    <!-- </transition> -->

    <!-- side bar starts -->
    <div class="side-bar" v-if="isSideBarOpen">
      <div class="side-bar-container">
        <div class="d-flex justify-content-center flex-column main-container">
          <div class="cp-details clr justify-content-between">
            <h1 class="tele-calling-details-title">{{ userRole }}</h1>
            <img
              class="img-advanc"
              src="../assets/menu-close.svg"
              alt=""
              @click="toggleSideBar"
            />
          </div>
          <div class="sidebar-txt">
            <div class="p-3 active" @click="goToHome">Manage Leads</div>
            <div class="p-3" @click="goToCallAnalysis()">Call Analysis</div>
            <div @click="goToBrokers()" class="p-3">Brokers</div>
            <div class="p-3" @click="goToProjects()">Projects</div>
            <div @click="goToManageMeeting()" class="p-3">Manage Meetings</div>
          </div>
        </div>
        <div class="logout" @click="getlogout">
          <img src="../assets/logout.svg" alt="" />
          Logout
        </div>
      </div>
      <div class="logout" @click="getlogout">
        <img src="../assets/logout.svg" alt="" />
        Logout
      </div>
    </div>

    <div class="loader-wrapper" v-if="isLoading">
      <div class="loader">Loading...</div>
    </div>
  </div>
  <!-- advanced Search  -->
  <div class="adv-main-container" v-if="isAdvancedSearch">
    <div class="d-flex justify-content-center flex-column main-container">
      <div class="cp-details">
        <h1 class="tele-calling-details-title">Advance Search</h1>
        <!-- <a @click="$router.go(-1)" class="back-arrow"> -->
        <img
          class="img"
          src="../assets/close.svg"
          alt=""
          @click="closeAdvSearch"
        />
        <!-- </a> -->
      </div>
      <div class="form-main">
        <div class="form-floating cp-form">
          <input
            type="text"
            class="form-control"
            id="leadName"
            placeholder="Lead Name"
            v-model="advancedSearchForm.broker_lead_name_cont"
          />
          <label for="leadName">Lead Name</label>
        </div>
        <div class="form-floating cp-form">
          <input
            type="number"
            class="form-control"
            id="mobileNumber"
            placeholder="Mobile Number"
            v-model="advancedSearchForm.broker_lead_phone_cont"
          />
          <label for="mobileNumber">Mobile Number</label>
        </div>
        <div class="form-floating cp-form">
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="Email"
            v-model="advancedSearchForm.broker_lead_email_cont"
          />
          <label for="email">Email</label>
        </div>
        <div>
          <Multiselect
            label="name"
            track-by="name"
            v-model="advancedSearchForm.broker_lead_project_id_in"
            :searchable="true"
            class="multi-select card-details-value card-details-select"
            :options="projectsOptions"
            placeholder="Select Project"
            mode="multiple"
            :canDeselect="false"
            :appendTo="'#openmodal'"
          />
        </div>
        <div>
          <Multiselect
            label="name"
            track-by="name"
            v-model="advancedSearchForm.broker_lead_source_id_in"
            :searchable="true"
            class="multi-select card-details-value card-details-select"
            placeholder="Select Source"
            :options="sourcesOptions"
            mode="multiple"
            :canDeselect="false"
          />
        </div>
        <div>
          <Multiselect
            label="name"
            track-by="name"
            v-model="advancedSearchForm.status_id_in"
            :searchable="true"
            class="multi-select card-details-value card-details-select"
            :options="statusOptions"
            placeholder="Select Status"
            mode="multiple"
            :canDeselect="false"
          />
        </div>
        <div>
          <Multiselect
            label="name"
            track-by="name"
            :searchable="true"
            mode="multiple"
            v-model="advancedSearchForm.broker_lead_status_id_in"
            class="multi-select card-details-value card-details-select"
            :options="cpOptions"
            placeholder="Select CP Status"
            :canDeselect="false"
          />
        </div>
        <div>
          <Multiselect
            label="name"
            track-by="name"
            :searchable="true"
            v-model="advancedSearchForm.broker_lead_broker_id_in"
            class="multi-select card-details-value card-details-select"
            :options="brokersOptions"
            placeholder="Select Broker"
            mode="multiple"
            :canDeselect="false"
          />
        </div>
        <div>
          <Multiselect
            label="name"
            track-by="name"
            :searchable="true"
            v-model="advancedSearchForm.user_id_in"
            class="multi-select card-details-value card-details-select"
            :options="executivesOptions"
            placeholder="Select Calling Executive"
            mode="multiple"
            :canDeselect="false"
          />
        </div>
        <div>
          <Multiselect
            label="name"
            track-by="name"
            :searchable="true"
            v-model="advancedSearchForm.meeting_executive_id_in"
            class="multi-select card-details-value card-details-select"
            :options="executivesOptions"
            placeholder="Select Field Executive"
            mode="multiple"
            :canDeselect="false"
          />
        </div>
        <div class="date-picker-input">
          <VueDatePicker
            class=""
            id="datepicker1"
            placeholder="NCD From"
            v-model="advancedSearchForm.ncd_gteq"
          >
          </VueDatePicker>
        </div>
        <div class="date-picker-input">
          <VueDatePicker
            class=""
            id="datepicker2"
            placeholder="NCD To"
            v-model="advancedSearchForm.ncd_lteq"
          >
          </VueDatePicker>
        </div>
        <div class="date-picker-input">
          <VueDatePicker
            class=""
            id="datepicker3"
            placeholder="Created at From"
            v-model="advancedSearchForm.created_at_gteq"
          ></VueDatePicker>
        </div>
        <div class="date-picker-input">
          <VueDatePicker
            class=""
            id="datepicker3"
            placeholder="Created at To"
            v-model="advancedSearchForm.created_at_lteq"
          ></VueDatePicker>
        </div>
        <div class="date-picker-input">
          <VueDatePicker
            class=""
            id="datepicker4"
            placeholder="Visit Date From"
            v-model="advancedSearchForm.visit_details_visit_date_gteq"
          ></VueDatePicker>
        </div>
        <div class="date-picker-input">
          <VueDatePicker
            class=""
            id="datepicker5"
            placeholder="Visit Date To"
            v-model="advancedSearchForm.visit_details_visit_date_lteq"
          ></VueDatePicker>
        </div>
        <div class="date-picker-input">
          <VueDatePicker
            class=""
            id="datepicker6"
            placeholder="Booking Date From"
            v-model="advancedSearchForm.booking_date_gteq"
          ></VueDatePicker>
        </div>
        <div class="date-picker-input">
          <VueDatePicker
            class=""
            id="datepicker7"
            placeholder="Booking Date To"
            v-model="advancedSearchForm.booking_date_lteq"
          ></VueDatePicker>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="default"
                v-model="advancedSearchForm.visited_eq"
              />
              <label class="form-check-label" for="default"> Visited </label>
            </div>
          </div>
          <div class="col-6">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="revisited"
                v-model="advancedSearchForm.revisited_eq"
              />
              <label class="form-check-label" for="revisited">
                Revisited
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="qualified"
                v-model="advancedSearchForm.is_qualified_eq"
              />
              <label class="form-check-label" for="qualified">
                Is qualified?
              </label>
            </div>
          </div>
        </div>
        <div class="form-group my-3 submit-section-clr" @click="clearAll()">
          <button type="button" class="bttn btn btn-clr">Clear All</button>
        </div>
        <div class="footer-section">
          <button
            type="button"
            class="bttn btn search-clr"
            @click="advanceSearch()"
          >
            <img src="../assets/search-white.svg" alt="" /> Search
          </button>
        </div>
      </div>
    </div>
  </div>
  <div id="openmodal"></div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { mapGetters } from "vuex";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "home",
  components: {
    Multiselect,
    VueDatePicker,
  },
  data() {
    return {
      isLoading: true,
      total: 0,
      perPage: 0,
      userRole: localStorage.getItem("tele-calling-name"),
      isSideBarOpen: false,
      leadData: {},
      buildersLeads: [],
      showModal: false,
      isDialing: true,
      isDialingFailed: false,
      callType: null,
      leadSearch: {
        // builders_leads:{
        name: "",
        phone: "",
        email: "",
        project_name: "",
        source: "",
        status: "",
        broker_status: "",
        broker_name: "",
        calling_executive: "",
        field_executive: "",
        ncd: null,
        created_at: "",
        updated_at: "",
        visit_details: [],
        booking_date: null,
        visited: "",
        revisited: "",
        is_qualified: "",
        // }
      },
      //isExecutive: false,
      callEnabled: false,
      statusOptions: [],
      userOptions: [],
      sourcesOptions: [],
      projectsOptions: [],
      brokersOptions: [],
      executivesOptions: [],
      cpOptions: [],
      allMetaData: [],
      isAdvancedSearch: false,
      isFromAdvSearch: false,
      advancedSearchForm: {
        broker_lead_name_cont: "",
        broker_lead_phone_cont: "",
        broker_lead_email_cont: "",
        broker_lead_project_id_in: [],
        broker_lead_source_id_in: [],
        broker_lead_status_id_in: [],
        status_id_in: [],
        broker_lead_broker_id_in: [],
        user_id_in: [],
        meeting_executive_id_in: [],
        ncd_gteq: null,
        ncd_lteq: "",
        created_at_gteq: "",
        created_at_lteq: "",
        visit_details_visit_date_gteq: "",
        visit_details_visit_date_lteq: "",
        booking_date_gteq: "",
        booking_date_lteq: "",
        visited_eq: "",
        revisited_eq: "",
      },
    };
  },
  methods: {
    openDialPad(item) {
      this.showModal = true;
      this.$axios
        .post(`builders/leads/${item.value}/make_call`, {})
        .then((res) => {
          this.isDialing = false;
          console.log(res, "res");
          setTimeout(() => {
            this.showModal = false;
            this.isDialing = true;
          }, 1000);
        })
        .catch((err) => {
          this.isDialingFailed = true;
          setTimeout(() => {
            this.showModal = false;
            // this.isDialing = true;
          }, 1000);
          console.log(err);
        });
    },
    toggleSideBar() {
      this.isSideBarOpen = !this.isSideBarOpen;
    },
    getlogout() {
      this.$axios
        .delete("logout")
        .then(() => {
          localStorage.removeItem("cp-mandate-token");
          this.$router.replace("/");
        })
        .catch((error) => {
          console.log(error.response.status, "error");
          if (error.response.status === 401 || error.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    searchFilter(callType) {
      this.callType = callType;
      this.$axios
        .get("builders/leads", {
          params: {
            bs: this.search,
          },
        })
        .then((response) => {
          this.leadData = response.data;

          callType
            ? (this.buildersLeads = response.data[callType])
            : (this.buildersLeads = response.data.builders_leads);
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    clearAll() {
      this.advancedSearchForm = {
        broker_lead_name_cont: "",
        broker_lead_phone_cont: "",
        broker_lead_email_cont: "",
        broker_lead_project_id_in: [],
        broker_lead_source_id_in: [],
        broker_lead_status_id_in: [],
        status_id_in: [],
        broker_lead_broker_id_in: [],
        user_id_in: [],
        meeting_executive_id_in: [],
        ncd_gteq: null,
        ncd_lteq: "",
        created_at_gteq: "",
        created_at_lteq: "",
        visit_details_visit_date_gteq: "",
        visit_details_visit_date_lteq: "",
        booking_date_gteq: "",
        booking_date_lteq: "",
        visited_eq: "",
        revisited_eq: "",
      };
    },
    // getlogout() {
    //     this.$router.push({
    //         path: "/",
    //         query: {
    //             uuid: this.$route.query.uuid
    //         }
    //     });
    // },
    goToHistory(selectedDetails) {
      sessionStorage.setItem(
        "selectedDetails",
        JSON.stringify(selectedDetails)
      );
      this.$router.push({
        path: "/leadhistory",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToHome() {
      this.$router.push({
        path: "/home",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },

    goTOCallingExecutive() {
      this.$router.push({
        path: "/assigncallingexecutive",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToProjects() {
      this.$router.push({
        path: "/projects",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToManageMeeting() {
      this.$router.push({
        path: "/managemeetings",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToBrokers() {
      this.$router.push({
        path: "/broker",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToCallAnalysis() {
      this.$router.push({
        path: "/callanalysis",
      });
    },
    goTOFieldExecutive() {
      this.$router.push({
        path: "/assignfieldexecutives",
      });
    },

    goToEdit(leads) {
      sessionStorage.setItem("selectedDetails", JSON.stringify(leads));
      this.$router.push({
        path: "/editenquiry",
      });
    },
    goToDetail(leads) {
      sessionStorage.setItem("selectedDetails", JSON.stringify(leads));
      this.$router.push({
        path: "/leaddetail",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },

    goToAdvanceSearch() {
      // this.$router.push({
      //     path: "/advancesearch",
      //     query: {
      //         uuid: this.$route.query.uuid
      //     }
      // });
      this.isAdvancedSearch = true;
    },
    closeAdvSearch() {
      this.isAdvancedSearch = false;
    },
    goToNewEnquary() {
      this.$router.push({
        path: "/newenquiry",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToPage(page) {
      let newQ = {
        ...this.$route.query,
        page,
      };
      this.$router.replace({
        query: newQ,
      });
      this.isFromAdvSearch
        ? this.advanceSearch(newQ)
        : this.getBuilderLeads(null, newQ);
    },
    getBuilderLeads(callType, newQ) {
      console.log("bbb", callType);
      this.isFromAdvSearch = false;
      this.callType = callType;
      const params = {
        page: 1,
      };
      if (callType) {
        params[callType] = true;
      }
      params["page"] = newQ?.page;
      this.$axios
        .get("builders/leads", {
          params,
        })
        .then((response) => {
          this.isLoading = false;
          this.leadData = response.data;
          console.log(this.leadData, "lead data************");
          this.perPage = response.data.per_page;
          console.log(callType, "call type");
          this.total = callType
            ? callType == "backlogs_leads"
              ? response.data.backlogs_count
              : response.data.todays_leads_count
            : response.data.count;
          callType
            ? (this.buildersLeads = response.data[callType])
            : (this.buildersLeads = response.data.builders_leads);
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    advanceSearch(newQ) {
      this.isFromAdvSearch = true;
      let pageNo = 1;
      pageNo = newQ?.page;
      this.$axios
        .post("builders/leads/search", {
          q: this.advancedSearchForm,
          page: pageNo,
        })
        .then((res) => {
          this.leadData = res.data;
          this.perPage = res.data.per_page;
          this.total = res.data.count;
          this.buildersLeads = res.data.builders_leads;
          this.isAdvancedSearch = false;
          window.scrollTo(0, 0);
          console.log(res, "res");
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    getAllData() {
      this.$axios
        .get("builders/leads/meta_data")
        .then((response) => {
          this.allMetaData = response.data;
          this.statusOptions = response.data.status;
          this.userOptions = response.data.users;
          this.sourcesOptions = response.data.sources;
          this.projectsOptions = response.data.projects;
          this.brokersOptions = response.data.brokers;
          this.executivesOptions = response.data.executives;
          this.cpOptions = response.data.cp_status;
          //this.isExecutive = response.data.is_executive;
          this.callEnabled = response.data.click_to_call_enabled;
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
  },
  computed: {
    ...mapGetters(["loginData"]),
    totalPages() {
      if (this.total === 0 || this.perPage === 0) {
        return 0;
      } else {
        return Math.ceil(this.total / this.perPage);
      }
    },
    page: {
      get() {
        let page = this.$route.query.page || 1;
        return parseInt(page);
      },
      set() {
        true;
      },
    },
    pageStart() {
      if (this.total === 0) {
        return 0;
      }
      return Math.max(0, (this.page - 1) * this.perPage + 1);
    },
    pageEnd() {
      if (this.total === 0) {
        return 0;
      }
      return Math.min(this.total, this.page * this.perPage);
    },
  },
  mounted() {
    this.getBuilderLeads(null);
    this.advanceSearch();
    this.getAllData();
  },
};
</script>

<style scoped>
/* side bar starts */
.side-bar {
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  height: 100vh;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.side-bar-container {
  width: 80%;
  background: #fff;
  position: relative;
  left: 20%;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logout {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.12px;
  color: #ee4b4b;
  padding-bottom: 20px;
}

.sidebar-txt {
  display: flex;
  color: #888888;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  background: white;
  padding: 0 10px;
}

.sidebar-txt div {
  color: #888;
  font-size: 16px;
  border-bottom: 1px solid #f1e9e9;
  letter-spacing: 0.5px;
}

.sidebar-txt .active {
  color: #19a7ce;
  font-weight: 600;
}

/* sidebar ends */

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  text-align: center;
  background-color: white;
  height: 500px;
  width: 500px;
  margin-top: 10%;
  padding: 60px 0;
  border-radius: 20px;
}

.close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

button {
  /* background-color: #ac003e; */
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 16px;
}

.cp-container {
  padding: 15px;
}

.cp-detail {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  margin-bottom: 15px;
}

.cp-detail-new {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  margin-bottom: 32px;
}

.text-bold {
  font-weight: 600;
}

.main-container {
  background-color: #f6f1f1;
}

.cp-details {
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px 0px #0000000d;
  background-color: #fff;
  position: relative;
}

.adv-main-container .cp-details {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
}

.clr {
  background: #f1e9e9 !important;
}

/* .clor{
    background-color: #fff !important;
} */

.img {
  position: absolute;
  right: 16px;
}

.cp-detail-txt {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 2px 4px 0px #00000014;
  justify-content: space-between;
  border: 1px solid transparent;
}

.cp-detail-txt.active {
  border: 1px solid #ee964b;
}

.cp-detail-plus {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 8px;
  flex: 1;
  justify-content: space-between;
  box-shadow: 0px 2px 4px 0px #00000014;
}

.cp-detail-add {
  background: #ee964b;
  color: #ffffff;
}

.tele-calling-details-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  color: #000000;
  margin-bottom: 0;
  letter-spacing: 0.35px;
}

.search-box-main {
  margin-top: 15px;
  position: relative;
}

.search-box-main input {
  box-shadow: 0px 1px 4px 0px #0000000d;
  background: #ffffff;
  border-radius: 40px;
  width: 100%;
  border: 0;
  padding: 14px 16px;
  border: 1px solid transparent;
}

.search-box-main input:focus {
  outline: none;
  border: 1px solid #19a7ce;
}

.search-img-sub {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.search-img {
  /* display: flex;
    width: 100%;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    background-color: #fff; */
}

.text-input {
}

.advance-search {
  position: absolute;
  right: 22px;
  top: 20px;
}

.advance-search-main {
  position: relative;
  padding: 13px 0 12px 16px;
  border-top: 1px solid #a77272;
  border-bottom: 1px solid #a77272;
  letter-spacing: 0.56px;
  color: #763a3a;
  background: #f1e9e9;
}

.count {
  padding-top: 16px;
  padding-left: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #a77272;
  margin-bottom: 12px;
}

.actions-main {
  display: flex;
  gap: 15px;
}

.actions-main div {
  border: 1px solid #19a7ce;
  background: #fff;
  border-radius: 34px;
  padding: 12px 16px;
  flex: 1;
  text-align: center;
  color: #19a7ce;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.56px;
}

.actions-main div img {
  margin-bottom: 2px;
}

.actions-main .active {
  background: #19a7ce;
  color: #ffffff;
}

.cp-actions-main {
  padding: 15px;
}

.cp-actions-des {
  text-align: center;
  font-weight: 400;
  color: #000;
  line-height: 17.34px;
  font-size: 12px;
  padding-bottom: 15px;
}

.cp-actions-des span {
  font-weight: 600;
}

.cp-card-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cp-card {
  box-shadow: 0px 1px 4px 0px #0000000d;
  background: #fff;
  border-radius: 16px;
}

.cp-card-new-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cp-card-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 400;
  color: #888888;
  padding: 16px;
  border-bottom: 1px dashed #b9b9b9;
}

.cp-project-name {
  color: #888;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
}

.cp-calling-exe {
  color: #888;
  font-size: 14px;
}

.cp-calling-exe span {
  font-weight: 600;
  letter-spacing: 0.56px;
}

.label-des {
  font-weight: 600;
  font-size: 14px;
  line-height: 20.23px;
  color: #000000;
  display: inline-block;
  margin-right: 6px;
}

.new-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #147773;
  background: #e3f8ec;
  padding: 1px 4px;
  border-radius: 4px;
  display: inline-block;
}

/* advance search  */
.adv-main-container {
  background-color: #f1e9e9;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
}

.checkbox-main {
  align-items: center;
  gap: 10px;
  flex: 1;
  margin-top: 10px;
}

.form-main {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  background: #ffffff;
}

.adv-main-container .form-main {
  padding-top: 86px;
  padding-bottom: 86px;
}

.adv-main-container .submit-section {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  left: 0;
  background-color: #fff;
  /* padding: 12px 16px; */
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.05);
}

.img-advanc {
  position: absolute;
  right: 16px;
}

.btn-clr {
  /* background-color: #19A7CE; */
  width: 100%;
  color: #19a7ce;
  border: 1px solid #19a7ce;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  padding: 12px 16px;
}

.search-clr {
  width: 100%;
  background-color: #19a7ce;
  color: #ffffff;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 12px 16px;
}

.submit-section {
  position: relative;
}

.user-input-wrp {
  position: relative;
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  width: 100%;
  background: white;
}

.user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  /* border-bottom: 1px solid #777; */
  box-shadow: none !important;
  padding-bottom: 3px;
  padding-left: 16px;
}

.user-input-wrp .inputText:focus {
  border-color: blue;
  border-width: medium medium 2px;
}

.user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 18px;
  left: 10px;
  transition: 0.2s ease all;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #888888;
}

.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:not(:focus):valid ~ .floating-label {
  top: 6px;
  left: 16px;
  font-size: 13px;
  opacity: 1;
}
</style>
