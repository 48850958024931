<template>
  <div class="main-container bg-body">
    <div class="top-navbar">
      <img
        class="icon-left"
        src="../assets/home.svg"
        alt=""
        @click="goTOHome()"
      />
      <h1 class="details-title">Projects</h1>
      <img
        class="icon-right"
        src="../assets/menu-open.svg"
        alt=""
        @click="toggleSideBar"
      />
    </div>
    <div
      class="d-flex justify-content-center flex-column main-container page-content"
    >
      <div class="project-search">
        <div class="cp-container px-3">
          <div class="search-box-main">
            <img src="../assets/search.svg" alt="" class="search-img-sub" />
            <input
              type="text"
              v-model="search"
              @input="debouncedOnChange"
              placeholder="Search By Projects Name"
              class="text-input"
            />
            <!-- <div @click="openAdvancedFilter()"><img src="" alt=""></div> -->
          </div>
        </div>
        <div class="count px-3 ps-4">
          Project Count -
          <span class="text-bold">{{ projectsLead.total_projects }}</span>
        </div>
        <div class="cp-card-container px-3">
          <div
            class="cp-card"
            v-for="(project, index) in projectsLead.projects"
            :key="index"
          >
            <div class="cp-card-content" v-if="project">
              <div class="cp-card-new-label">
                <div>
                  <span class="main-text" v-if="project && project.name">{{
                    project.name
                  }}</span>
                  <div
                    class="cp-calling-exe"
                    v-if="project && project.locality_name"
                  >
                    Location:
                    <span class="sub-text">{{ project.locality_name }}</span>
                  </div>
                  <div class="cp-calling-exe" v-if="project && project.rera_id">
                    RERA: <span class="sub-text">{{ project.rera_id }}</span>
                  </div>
                  <div
                    class="cp-calling-exe"
                    v-if="project && project.display_budget_range"
                  >
                    Budget:
                    <span class="sub-text">{{
                      project.display_budget_range
                    }}</span>
                  </div>
                </div>
                <!-- <div><img src="../assets/dots.svg" alt="" @click="goToDetail(leads)"></div> -->
              </div>
              <!-- <div class="cp-project-name">  </div> -->
            </div>
            <div class="d-flex align-items-center">
              <div @click="goToBrokerInitiation(project)" class="broker">
                Broker Initiation
              </div>
              <div @click="goTOIntrestedBroker(project)" class="broker">
                Interested Brokers
              </div>
            </div>
          </div>
        </div>
        <div class="pagination-indicator">
          <span class="result-Text"
            >Showing {{ pageStart }} - {{ pageEnd }} out of {{ total }}</span
          >
        </div>
        <div class="pagination-holder mt-4">
          <paginate
            v-model="page"
            :page-count="totalPages"
            :page-range="3"
            :margin-pages="2"
            :click-handler="goToPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :page-link-class="'page-link'"
            :prev-link-class="'page-link'"
            :next-link-class="'page-link'"
            :disabled-class="'page-item disabled'"
            :container-class="'pagination justify-content-center'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
      </div>
      <div class="side-bar" v-if="isSideBarOpen">
        <div class="side-bar-container">
          <div class="d-flex justify-content-center flex-column main-container">
            <div class="cp-details clr justify-content-between">
              <h1 class="tele-calling-details-title">{{ userRole }}</h1>
              <img
                class="img-advanc"
                src="../assets/menu-close.svg"
                alt=""
                @click="toggleSideBar"
              />
            </div>
            <div class="sidebar-txt">
              <div class="p-3 active" @click="goToHome">Manage Leads</div>
              <div class="p-3" @click="goToCallAnalysis()">Call Analysis</div>
              <div @click="goToBrokers()" class="p-3">Brokers</div>
              <div class="p-3" @click="goToProjects()">Projects</div>
              <div @click="goToManageMeeting()" class="p-3">
                Manage Meetings
              </div>
            </div>
          </div>
          <div class="logout" @click="getlogout">
            <img src="../assets/logout.svg" alt="" />
            Logout
          </div>
        </div>
        <div class="logout" @click="getlogout">
          <img src="../assets/logout.svg" alt="" />
          Logout
        </div>
      </div>
      <div class="loader-wrapper" v-if="isLoading">
        <div class="loader">Loading...</div>
      </div>

      <!-- <div class="side-bar" v-if="isSideBarOpen">
        <div class="side-bar-container">
          <div class="d-flex justify-content-center flex-column main-container">
            <div class="cp-details clr justify-content-between">
              <h1 class="tele-calling-details-title">Super Admin</h1>
              <img class="img-advanc" src="../assets/menu-open.svg" alt="" @click="toggleSideBar">
            </div>
            <div class="sidebar-txt">
              <div class="p-3 active" @click="goTOHomegit">Manage Leads</div>
              <div class="p-3" @click="goToCallAnalysis()">Call Analysis</div>
              <div class="p-3" @click="goToProjects()">Projects</div>
              <div @click="goToManageMeeting()" class="p-3">Manage Meetings</div>
            </div>
          </div>
        </div>
        <div class="logout"><img src="../assets/logout.svg" alt="" @click="getlogout">
          Logout
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _debounce from "lodash.debounce";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "projects",
  data() {
    return {
      isLoading: true,
      total: 0,
      perPage: 0,
      userRole: localStorage.getItem("tele-calling-name"),
      isSideBarOpen: false,
      projectsLead: [],
      search: "",
    };
  },
  computed: {
    ...mapGetters(["loginData"]),
    debouncedOnChange() {
      return _debounce(this.searchFilter, 700);
    },
    totalPages() {
      if (this.total === 0 || this.perPage === 0) {
        return 0;
      } else {
        return Math.ceil(this.total / this.perPage);
      }
    },
    page: {
      get() {
        let page = this.$route.query.page || 1;
        return parseInt(page);
      },
      set() {
        true;
      },
    },
    pageStart() {
      if (this.total === 0) {
        return 0;
      }
      return Math.max(0, (this.page - 1) * this.perPage + 1);
    },
    pageEnd() {
      if (this.total === 0) {
        return 0;
      }
      return Math.min(this.total, this.page * this.perPage);
    },
  },
  methods: {
    goToPage(page) {
      let newQ = {
        ...this.$route.query,
        page,
      };
      this.$router.replace({
        query: newQ,
      });
      this.projectLeads(newQ);
    },
    toggleSideBar() {
      this.isSideBarOpen = !this.isSideBarOpen;
    },
    goToProjects() {
      this.$router.push({
        path: "/projects",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToManageMeeting() {
      this.$router.push({
        path: "/managemeetings",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToCallAnalysis() {
      this.$router.push({
        path: "/callanalysis",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    getlogout() {
      this.$axios
        .delete("logout")
        .then(() => {
          localStorage.removeItem("cp-mandate-token");
          this.$router.replace("/");
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    goToBrokerInitiation(projectInfo) {
      sessionStorage.setItem("projectData", JSON.stringify(projectInfo));
      this.$router.push({
        path: "/brokerinitiation",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goTOHome() {
      this.$router.push({
        path: "/home",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToBrokers() {
      this.$router.push({
        path: "/broker",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goTOIntrestedBroker(projectInfo) {
      sessionStorage.setItem("projectData", JSON.stringify(projectInfo));
      this.$router.push({
        path: "/interestedbrokers",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    projectLeads(newQ) {
      const params = {
        page: 1,
      };
      params["page"] = newQ?.page;
      this.$axios
        .get("projects", {
          params,
        })
        .then((response) => {
          this.isLoading = false;
          this.projectsLead = response.data;
          this.perPage = response.data.per_page;
          this.total = response.data.total_projects;
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    searchFilter() {
      const params = {
        bs: this.search,
      };
      this.$axios
        .get("projects", {
          params,
        })
        .then((response) => {
          this.projectsLead = response.data;
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
  },
  mounted() {
    this.projectLeads();
  },
};
</script>

<style scoped>
/* side bar starts */
.side-bar {
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  height: 100vh;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.side-bar-container {
  width: 80%;
  background: #fff;
  position: relative;
  left: 20%;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logout {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.12px;
  color: #ee4b4b;
  padding-bottom: 20px;
}

.sidebar-txt {
  display: flex;
  color: #888888;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  background: white;
  padding: 0 10px;
}

.sidebar-txt div {
  color: #888;
  font-size: 16px;
  border-bottom: 1px solid #f1e9e9;
  letter-spacing: 0.5px;
}

.sidebar-txt .active {
  color: #19a7ce;
  font-weight: 600;
}

.clr {
  background: #f1e9e9 !important;
}

.cp-details {
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px 0px #0000000d;
  background-color: #fff;
  position: relative;
}

.tele-calling-details-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  color: #000000;
  margin-bottom: 0;
  letter-spacing: 0.35px;
}

.img-advanc {
  position: absolute;
  right: 16px;
}

/* sidebar ends */

.bg-body {
  min-height: 100vh;
  min-height: 100dvh;
  background-color: #f6f1f1 !important;
}

.cp-details {
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px 0px #0000000d;
  background-color: #fff;
}

.details-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  color: #000000;
  margin-bottom: 0;
  letter-spacing: 0.35px;
}

.search-box-main {
  margin-top: 15px;
  position: relative;
}

.search-box-main input {
  box-shadow: 0px 1px 4px 0px #0000000d;
  background: #ffffff;
  border-radius: 40px;
  width: 100%;
  border: 0;
  padding: 14px 16px;
  border: 1px solid transparent;
}

.search-box-main input:focus {
  outline: none;
  border: 1px solid #19a7ce;
}

.search-box-main {
  margin-top: 15px;
  position: relative;
}

.search-img-sub {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.cp-container {
  padding: 15px;
}

.count {
  padding-top: 16px;
  padding-left: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #a77272;
  margin-bottom: 12px;
}

.text-bold {
  font-weight: 600;
}

.cp-card-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cp-card {
  box-shadow: 0px 1px 4px 0px #0000000d;
  background: #fff;
  border-radius: 16px;
}

.cp-card-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 400;
  color: #888888;
  padding: 16px;
  border-bottom: 1px dashed #b9b9b9;
}

.cp-card-new-label {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.cp-calling-exe {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #888;
}

.main-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #000000;
}

.sub-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #888888;
}

.broker {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #19a7ce;
  flex: 1;
  border: 1px solid #19a7ce;
  border-radius: 34px;
  padding: 12px;
  margin: 16px;
  display: flex;
  justify-content: center;
}

/* side bar starts */
.side-bar {
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  height: 100vh;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.side-bar-container {
  width: 80%;
  background: #fff;
  position: relative;
  left: 20%;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logout {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.12px;
  color: #ee4b4b;
  padding-bottom: 20px;
}

.sidebar-txt {
  display: flex;
  color: #888888;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  background: white;
  padding: 0 10px;
}

.sidebar-txt div {
  color: #888;
  font-size: 16px;
  border-bottom: 1px solid #f1e9e9;
  letter-spacing: 0.5px;
}

.sidebar-txt .active {
  color: #19a7ce;
  font-weight: 600;
}

/* sidebar ends */
</style>
