<template>
  <div class="main-container">
    <div class="d-flex justify-content-center flex-column main-container">
      <div class="top-navbar">
        <h1>Edit Enquiry</h1>
        <a @click="$router.go(-1)" class="icon-left">
          <img src="../assets/back.svg" alt="" @click="toggleSideBar" />
        </a>
      </div>
      <div class="main-card page-content" v-if="selectedEnquiry">
        <div class="name-label">
          <div class="enq-no">{{ selectedEnquiry.lead_no }}</div>
          <div class="enq-time">{{ selectedEnquiry.created_at }}</div>
        </div>
        <div class="card-main">
          <div class="basic-info">Basic Info of Lead</div>
          <div class="form-main">
            <div class="form-floating cp-form">
              <input
                type="text"
                class="form-control"
                v-model="builder_lead.broker_lead_attributes.name"
                id="cutomerName"
                placeholder="Customer Name"
                name="Customer_Name"
                value="selectedLead.name"
                required
                @input="handleInput"
              />
              <label for="cutomerName"
                >Customer Name <span class="required">*</span></label
              >
              <div
                class="form-error-text"
                v-if="
                  v$.builder_lead.broker_lead_attributes.name.$invalid &&
                  isFormDirty
                "
              >
                This field is required
              </div>
            </div>
            <!-- <div class="error">{{ errors?.Customer_Name }}</div> -->
            <div class="form-floating cp-form" v-if="canAccess">
              <input
                type="number"
                class="form-control"
                id="mobileNumber"
                v-model="builder_lead.broker_lead_attributes.phone"
              />
              <label for="mobileNumber"
                >Mobile Number<span class="required">*</span></label
              >
            </div>
            <div class="form-floating cp-form">
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="Email"
                v-model="builder_lead.broker_lead_attributes.email"
                readonly
              />
              <label for="email">Email<span class="required">*</span></label>
            </div>
            <div>
              <Multiselect
                label="name"
                v-model="builder_lead.status_id"
                track-by="name"
                :searchable="true"
                :options="statusOptions"
                class="multi-select card-details-value card-details-select"
                placeholder="Lead Status"
                :canDeselect="true"
              />
            </div>
            <template v-if="builder_lead.status_id == 25">
              <div class="form-floating cp-form">
                <Multiselect
                  label="name"
                  v-model="builder_lead.dead_reason"
                  track-by="name"
                  name="DeadReason"
                  :searchable="true"
                  :options="deadReasonsOptions"
                  class="multi-select card-details-value card-details-select"
                  placeholder="Dead Reason"
                  :canDeselect="true"
                  required
                />
                <div
                  class="form-error-text"
                  v-if="v$.builder_lead.dead_reason?.$invalid && isFormDirty"
                >
                  This field is required
                </div>
              </div>
              <!-- <div class="error">{{ errors?.DeadReason }}</div> -->
              <div class="form-floating cp-form">
                <input
                  type="text"
                  class="form-control"
                  v-model="builder_lead.dead_reason_comment"
                  id="cutomerName"
                  placeholder="Customer Name"
                  value="selectedLead.name"
                />
                <label for="cutomerName"
                  >{{ builder_lead.dead_reason }}<span></span
                ></label>
              </div>
            </template>
            <template v-if="builder_lead.status_id == 22">
              <div class="date-picker-input">
                <VueDatePicker
                  class="required"
                  id="datepicker9"
                  name="booking_date"
                  v-model="builder_lead.booking_date"
                  placeholder="Booking date"
                  @input="handleInput"
                ></VueDatePicker>
                <div
                  class="form-error-text"
                  v-if="v$.builder_lead.booking_date?.$invalid && isFormDirty"
                >
                  This field is required
                </div>
              </div>
              <!-- <div class="error">{{ errors?.booking_date }} </div> -->
            </template>
            <div class="row my-3">
              <div class="col-12">
                <div class="form-check disabled">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="builder_lead.is_qualified"
                    id="qualified"
                  />
                  <label class="form-check-label" for="qualified">
                    Is qualified?
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-main">
          <div class="basic-info">Enquiry Requirement Detail</div>
          <div class="form-main">
            <div>
              <Multiselect
                label="name"
                v-model="builder_lead.broker_lead_attributes.project_id"
                track-by="name"
                :searchable="true"
                :options="projectsOptions"
                class="multi-select card-details-value card-details-select required"
                placeholder="Project"
                :canDeselect="true"
                :allow-empty="false"
                :disabled="true"
              />
            </div>
            <div class="date-picker-input">
              <VueDatePicker
                class=""
                id="datepicker7"
                v-model="builder_lead.ncd"
                :enable-time-picker="false"
                placeholder="NCD"
              ></VueDatePicker>
            </div>
            <div class="mb-2">
              <Multiselect
                label="name"
                v-model="builder_lead.broker_lead_attributes.source_id"
                track-by="name"
                :searchable="true"
                :options="sourcesOptions"
                class="multi-select card-details-value card-details-select"
                placeholder="Source"
                :canDeselect="false"
                :disabled="true"
              />
            </div>
          </div>
        </div>

        <div class="card-main">
          <div class="basic-info">Visit Details</div>
          <div class="form-main">
            <div v-if="selectedEnquiry.builder_visited == 'Yes'">
              <div
                v-for="date in selectedEnquiry.builder_visit_details"
                :key="date"
              >
                {{ date.visit_date }}
              </div>
            </div>
            <div class="date-picker-input">
              <VueDatePicker
                v-model="builder_lead.visit_details_attributes.visit_date"
                class=""
                id="datepicker8"
                placeholder="Visit Date"
              ></VueDatePicker>
            </div>
            <div class="mb-2">
              <Multiselect
                label="name"
                v-model="builder_lead.closing_executive_id"
                track-by="name"
                :searchable="true"
                :options="executiveOptions"
                class="multi-select card-details-value card-details-select required"
                placeholder="Closing Executive"
                :canDeselect="false"
              />
            </div>
          </div>
        </div>

        <div class="card-main">
          <div class="basic-info">Enquiry Requirement Detail</div>
          <div class="form-main">
            <div>
              <Multiselect
                label="name"
                v-model="builder_lead.user_id"
                track-by="name"
                name="Calling_Executive"
                :searchable="true"
                :options="executiveOptions"
                class="multi-select card-details-value card-details-select required"
                placeholder="Calling Executive"
                :canDeselect="false"
              />
              <div
                class="form-error-text"
                v-if="v$.builder_lead.user_id?.$invalid && isFormDirty"
              >
                This field is required
              </div>
            </div>
            <!-- <div class="error">{{ errors?.Calling_Executive }}</div> -->
            <div>
              <Multiselect
                label="name"
                v-model="builder_lead.meeting_executive_id"
                name="Field_Executive"
                track-by="name"
                :searchable="true"
                :options="executiveOptions"
                class="multi-select card-details-value card-details-select required"
                placeholder="Field Executive"
                :canDeselect="false"
              />
              <div
                class="form-error-text"
                v-if="
                  v$.builder_lead.meeting_executive_id?.$invalid && isFormDirty
                "
              >
                This field is required
              </div>
            </div>
            <!-- <div class="error">{{ errors?.Field_Executive }}</div> -->
            <div class="cp-main">
              <div class="cp-label">
                <div class="cp-txt-up clr">Comments</div>
                <div class="cp-txt clr" v-html="showComment"></div>
              </div>
            </div>
            <div class="form-floating mb-2 cp-form">
              <textarea
                rows="3"
                class="form-control"
                v-model="builder_lead.comment"
                name="Comments"
                placeholder="Leave a comment here"
                id="comment"
                @input="handleInput"
              ></textarea>
              <label for="comment">Comments</label>
              <div
                class="form-error-text"
                v-if="v$.builder_lead.comment?.$invalid && isFormDirty"
              >
                This field is required
              </div>
            </div>
            <!-- <div class="error">{{ errors?.Comments }}</div> -->
          </div>
        </div>
      </div>
      <div class="footer-section">
        <button type="button" class="bttn btn btn-clr" @click="saveLeads()">
          <img src="../assets/save.svg" alt="" /> Save
        </button>
        <button
          type="button"
          @click="$router.go(-1)"
          class="bttn btn clear-all"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "editenquiry",
  components: {
    Multiselect,
    VueDatePicker,
  },
  data() {
    return {
      v$: useVuelidate(),
      value: [],
      date: null,
      selectedLead: {},
      selectedEnquiry: {},
      options: [
        {
          value: "1",
          label: "Batman",
        },
        {
          value: "2",
          label: "Robin",
        },
        {
          value: "3",
          label: "Joker",
        },
      ],
      builder_lead: {
        user_id: 0,
        meeting_executive_id: "",
        status_id: "",
        ncd: "",
        dead_reason: "",
        dead_reason_comment: "",
        comment: "",
        closing_executive_id: "",
        is_qualified: false,
        booking_date: "",
        broker_lead_attributes: {
          other_contacts: "",
          country_id: "",
          id: "",
          name: "",
          phone: "",
          email: "",
          broker_id: "",
          project_id: "",
          source_id: "",
        },
        visit_details_attributes: {
          visit_date: "",
          id: "",
        },
      },
      statusOptions: [],
      userOptions: [],
      sourcesOptions: [],
      projectsOptions: [],
      brokersOptions: [],
      executiveOptions: [],
      deadReasonsOptions: [],
      canAccess: false,
      showComment: "",
      isFormDirty: false,
    };
  },
  validations() {
    return {
      builder_lead: {
        user_id: {
          required,
        },
        meeting_executive_id: {
          required: requiredIf(this.builder_lead?.status_id === 45),
        },
        booking_date: {
          required: requiredIf(this.builder_lead?.status_id === 22),
        },
        dead_reason: {
          required: requiredIf(this.builder_lead?.status_id === 25),
        },
        comment: {
          required: requiredIf(this.builder_lead?.status_id !== 25),
        },
        broker_lead_attributes: {
          name: {
            required,
          },
          // broker_id: {
          //     required
          // },
          // project_id: {
          //     required
          // },
        },
      },
    };
  },
  methods: {
    saveLeads() {
      console.log(this.v$);
      if (this.v$.$invalid) {
        this.isFormDirty = true;
        return;
      }
      this.builder_lead.broker_lead_attributes.id =
        this.selectedEnquiry.broker_lead_id;
      this.builder_lead.broker_lead_attributes.country_id =
        this.selectedEnquiry.country_id;
      let result = this.deadReasonsOptions.filter((obj) => {
        return obj.value == this.builder_lead.dead_reason;
      });
      this.builder_lead.dead_reason = result[0]?.name;
      if (this.builder_lead.status_id != 25) {
        delete this.builder_lead.dead_reason;
        delete this.builder_lead.dead_reason_comment;
      }
      if (this.builder_lead.status_id != 22) {
        delete this.builder_lead.booking_date;
      }
      this.$axios
        .patch(`builders/leads/${this.selectedLead.value}`, {
          builder_lead: this.builder_lead,
        })
        .then((res) => {
          console.log(res, "Res");
          this.$swal({
            title: res.data.message,
            icon: "success",
          }).then(() => {
            this.$router.push({
              path: "/home",
            });
          });
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    getAllData() {
      this.$axios
        .get("builders/leads/meta_data")
        .then((response) => {
          console.log(response.data, "status");
          this.statusOptions = response.data.status;
          this.userOptions = response.data.users;
          this.sourcesOptions = response.data.sources;
          this.projectsOptions = response.data.projects;
          this.brokersOptions = response.data.brokers;
          this.executiveOptions = response.data.executives;
          this.deadReasonsOptions = response.data.dead_reason;
          this.canAccess = response.data.can_access_contact_number;
          for (var i = 0; i < this.deadReasonsOptions.length; i++) {
            this.deadReasonsOptions[i]["name"] =
              this.deadReasonsOptions[i]["reason"];
            this.deadReasonsOptions[i]["value"] =
              this.deadReasonsOptions[i]["reason"];
          }
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    getLeadDetails() {
      // if(callType) {
      //     params[callType] = true;
      // }
      this.$axios
        .get(`builders/leads/${this.selectedLead.value}`)
        .then((response) => {
          this.selectedEnquiry = response.data.builders_lead;
          this.builder_lead.user_id = this.selectedEnquiry.user_id;
          this.builder_lead.broker_lead_attributes.phone =
            this.selectedEnquiry.phone;
          this.builder_lead.broker_lead_attributes.email =
            this.selectedEnquiry.email;
          this.builder_lead.status_id = this.selectedEnquiry.status_id;
          this.builder_lead.broker_lead_attributes.project_id =
            this.selectedEnquiry.project_id;
          this.builder_lead.broker_lead_attributes.source_id =
            this.selectedEnquiry.source_id;
          this.builder_lead.closing_executive_id =
            this.selectedEnquiry.closing_executive_id;
          this.builder_lead.ncd = this.selectedEnquiry.ncd;
          this.builder_lead.meeting_executive_id =
            this.selectedEnquiry.meeting_executive_id;
          this.showComment = this.selectedEnquiry.comment;
          this.builder_lead.broker_lead_attributes.broker_id =
            this.selectedEnquiry.broker_id;
          this.builder_lead.broker_lead_attributes.name =
            this.selectedEnquiry.name;
          this.builder_lead.dead_reason = this.selectedEnquiry.dead_reason;
          console.log("reason", this.selectedEnquiry.dead_reason);
          this.builder_lead.dead_reason_comment =
            this.selectedEnquiry.dead_reason_comment;
          this.builder_lead.booking_date = this.selectedEnquiry.booking_date;
          this.builder_lead.is_qualified = this.selectedEnquiry.is_qualified;
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
  },
  mounted() {
    // this.getLeads()
    this.selectedLead = JSON.parse(sessionStorage.getItem("selectedDetails"));
    this.getLeadDetails();
    this.getAllData();
    console.log(this.builder_lead.status_id, "status id");
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style><style scoped>
.form-error-text {
  font-weight: 300;
  font-size: 13px;
  margin-top: 5px;
  color: red;
}

.main-container {
  background-color: #f1e9e9;
}

.comment-label {
  line-height: 20.23px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 16px;
  color: #888;
}

.form-main {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card-main {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.search-img {
  /* position: absolute;
        top: 4px;
        left: 274px; */
}

.search-img {
  position: absolute;
  top: 4px;
  left: 295px;
}

.cp-details {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  box-shadow: 0px 2px 4px 0px #0000000d;
  background-color: #fff;
}

.tele-calling-details-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  color: #000000;
}

.img {
  position: absolute;
  left: 16px;
}

.card-details-value {
  border-radius: 8px !important;
  /* opacity: 0.5;
        padding: 12px 20px; */
  color: #bfbfbf;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  border: 1px solid #b9b9b9;
  padding: 15px;
}

/* .btn-clr {
    background-color: #19A7CE;
    width: 100%;
    color: #19a7ce;
    border: 1px solid #19a7ce;
    border-radius: 34px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
} */
.clear-all {
  background-color: #ffffff;
  width: 100%;
  color: #19a7ce;
  border: 1px solid #19a7ce;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}

.search-clr {
  width: 100%;
  background-color: #19a7ce;
  /* border: 1px solid #19A7CE; */
  color: #ffffff;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}

.date-picker-input {
  position: relative;
}

.submit-section {
  position: relative;
  width: 100%;
}

.main-card {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0px 0px;
  background: #ffffff;
}

.is-qual {
  gap: 4px;
  padding: 15px 0px;
}

.btn-clr {
  /* background-color: #19A7CE; */
  width: 100%;
  color: #ffffff;
  /* border: 1px solid #19a7ce; */
  background: #19a7ce;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.card-details-select {
  padding: 5px 15px;
}

.user-input-wrp {
  position: relative;
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  width: 100%;
  background: white;
}

.user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  /* border-bottom: 1px solid #777; */
  box-shadow: none !important;
  padding-bottom: 3px;
  padding-left: 16px;
  border-radius: 20px;
}

.user-input-wrp .inputText:focus {
  border-color: blue;
  border-width: medium medium 2px;
}

.user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 18px;
  left: 10px;
  transition: 0.2s ease all;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #888888;
}

.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:not(:focus):valid ~ .floating-label {
  top: 6px;
  left: 16px;
  font-size: 13px;
  opacity: 1;
}

.name-label {
  padding: 15px;
  padding-top: 30px;
}

.enq-no {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  color: #763a3a;
}

.enq-time {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #a77272;
}

.basic-info {
  background: #19a7ce;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  padding: 6px 0 6px 16px;
  color: #ffffff;
}

.save {
  gap: 8px;
}
</style>
