<template>
<div class="justify-content-center  ">
    <div class="col-md-12 login-holder">
        <div class="login-box ">
            <div class="d-flex justify-content-center">
                <div class="company-details">
                    <!-- <img src="../assets/godrej.svg" alt="" class="d-flex company-logo pt-3"> -->
                    <!-- <img src="../assets/image.svg" alt="" class="login-image"> -->
                </div>
            </div>
            <div class="txt m-3">Change Password</div>
            <!-- <div class=" texts m-3">Login using registered user id to access your telecalling features!</div> -->
            <form class="main-tab">
                <div class="godrej-img pt-2">
                    <img src="../assets/godrej.png" width="187" height="60" alt="" class="company-logo">
                </div>
                <div class="form-floating email-section mx-3 mb-3">
                    <input type="password" v-model="changePassword.password" class="form-control" id="newPassword" required minlength="3" placeholder=" ">
                    <label for="newPassword">New Password</label>
                </div>
                <div class="form-floating password-group mx-3">
                    <input type="password" v-model="changePassword.password" class="form-control" id="confirmPassword" ref="password" required minlength="3" placeholder=" ">
                    <label for="confirmPassword">Confirm New Password</label>
                </div>
                <!-- <div class="forget-pass m-3" @click="forgetPassword">Forget Password</div> -->
                <div class="form-group m-3 submit-section">
                    <button type="button" class="bttn btn btn-clr" @click="resetPasswordSubmit">Submit</button>
                </div>
                <div class="forget-pass">
                    <button type="button" class="forget-pass-txt m-3" @click="goToLogin()">Go to Login</button>
                </div>
            </form>
            <div class="term-text">By Clicking "Login" You Indicate that you have read and agree to be bound Terms of service
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'changepassword',
    data() {
        return {
            changePassword: {
                password_confirmation: '',
                password: '',
                reset_password_token: this.$route.query.reset_password_token,
            }
        }
    },
    methods: {
        resetPasswordSubmit() {
            this.$axios.put('users/password', this.changePassword).then(res => {
                    console.log(res, "Res")
                })
                .catch((err) => {
                    this.$swal({
                    title: err.response.data.message,
                    icon: "warning"
                })
                    if (err.response.status === 401 || err.response.status === 404) {
                        localStorage.removeItem("cp-mandate-token");
                        this.$router.replace("/");
                    }
                });
        },
        goToLogin() {
            this.$router.push({
                path: "/"
            });
        }
    },
    mounted() {
        // console.log(this.$route.query.reset_password_token);
    }
}
</script>

<style scoped>
.term-text {
    color: #888888;
    position: absolute;
    bottom: 0;
    padding: 0 40px 26px 40px;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
}

.submit-section {
    margin-top: 24px !important;
}

.godrej-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-clr {
    background-color: #19A7CE;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 30px !important;
    line-height: 1;
    font-size: 14px !important;
    font-weight: 500;
}

.main-tab {
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05);
    margin-left: 8px;
    margin-right: 8px;
}

.login-box {
    flex: 1;
    padding-bottom: 123px;
}

.term-text {
    color: #888888;
    position: absolute;
    bottom: 0;
    padding: 0 40px 26px 40px;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
}

.form-txt {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #B9B9B9;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    padding: 8px;
}

.email-section {
    margin-top: 16px !important;
}

.icon-password {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.forget-pass-txt {
    color: #19A7CE;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    background: transparent;
    padding: 0;
    outline: none !important;
    box-shadow: none;
    border: none;
    margin-bottom: 32px !important;
}

.forget-pass {
    display: flex;
    align-items: center;
    justify-content: center;
}

.password-group {
    position: relative;
}

.company-details {
    background-image: url('../assets/background.svg');
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-size: 100% auto;
    align-items: center;
    /* gap: 20px; */
}

.login-holder {
    background-image: url('../assets/background.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    height: 100vh;
    height: 100dvh;
    display: flex;
    align-items: center;
}

.company-logo {
    max-width: 187px
}

.img-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.txt {
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;

}

.bttn {
    width: 100%;
    border-radius: 20px;
    font-size: 15px;
    color: #FFFFFF;
}

.bttn:hover,
.bttn:active,
.bttn:focus-visible {
    color: #FFFFFF !important;
    background-color: #19A7CE !important;
    border-color: #19A7CE !important;
}

.form-floating label {
    color: #888;
}

.form-control {
    border-radius: 8px;
    border: 1px solid #B9B9B9;
    background-color: #FFF;
}

.form-control:focus {
    border: 2px solid #19A7CE;
    box-shadow: none;
}

.form-floating>.form-control~label::after {
    background-color: inherit !important;
}
</style>
