import { createWebHistory, createRouter } from "vue-router";
import Login from "../components/Login.vue";
import Home from "../components/Home.vue";
import SideMenu from "../components/SideMenu.vue";
import ResetPassword from "../components/ResetPassword.vue"
import LeadDetail from "../components/LeadDetail.vue"
import AdvanceSearch from "../components/AdvanceSearch.vue"
import ChangePassword from "../components/ChangePassword.vue"
import NewEnquiry from "../components/NewEnquiry.vue"
import LeadHistory from "../components/LeadHistory.vue"
import EditEnquiry from "../components/EditEnquiry.vue"
import AssignCallingExecutive from "../components/AssignCallingExecutive.vue"
import ManageMeetings from "../components/ManageMeetings.vue"
import MeetingDetails from "../components/MeetingDetails.vue"
import EditMeeting from "../components/EditMeeting.vue"
import AssignFieldExecutives from "../components/AssignFieldExecutives.vue"
import NewMeeting from "../components/NewMeeting.vue"
import NewMeeting1 from "../components/NewMeeting1.vue"
import Projects from "../components/Projects.vue"
import BrokerInitiation from "../components/BrokerInitiation.vue"
import InterestedBrokers from "../components/InterestedBrokers.vue"
import CallAnalysis from "../components/CallAnalysis.vue"
import IncompleteMeeting from "../components/IncompleteMeeting"
import BacklogInterestedBrokers from "../components/BacklogInterestedBrokers.vue"
import Broker from "../components/Broker.vue"
import NewBroker from "../components/NewBroker.vue"
import EditBroker from "../components/EditBroker.vue"
import BrokerDetails from "../components/BrokerDetails.vue"
import IncompleteMeetingDetails from "../components/IncompleteMeetingDetails.vue"
import EditIncompleteMeeting from "../components/EditIncompleteMeeting.vue"
const routes = [
    {
        path: "/",
        name: "login",
        component: Login,
      },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/sidemenu",
    name: "SideMenu",
    component: SideMenu,
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/leaddetail",
    name: "LeadDetail",
    component: LeadDetail,
  },
  {
    path: "/advancesearch",
    name: "AdvanceSearch",
    component: AdvanceSearch,
  },
  {
    path: "/changepassword",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/newenquiry",
    name: "NewEnquiry",
    component: NewEnquiry,
  },
  
  {
    path: "/leadhistory",
    name: "LeadHistory",
    component: LeadHistory,
  },
  {
    path: "/editenquiry",
    name: "EditEnquiry",
    component: EditEnquiry,
  },
  
  {
    path: "/assigncallingexecutive",
    name: "AssignCallingExecutive",
    component: AssignCallingExecutive,
  },

  {
    path: "/assignfieldexecutives",
    name: "AssignFieldExecutives",
    component: AssignFieldExecutives,
  },
  {
    path: "/managemeetings",
    name: "ManageMeetings",
    component: ManageMeetings,
  },
  
  {
    path: "/meetingdetails",
    name: "MeetingDetails",
    component: MeetingDetails,
  },
  {
    path: "/incompletemeetingdetails",
    name: "IncompleteMeetingDetails",
    component: IncompleteMeetingDetails,
  },
  {
    path: "/editincompletemeeting",
    name: "EditIncompleteMeeting",
    component: EditIncompleteMeeting,
  },
  {
    path: "/editmeeting",
    name: "EditMeeting",
    component: EditMeeting,
  },
  {
    path: "/newmeeting",
    name: "NewMeeting",
    component: NewMeeting,
  },
  {
    path: "/incompletemeeting",
    name: "IncompleteMeeting",
    component: IncompleteMeeting,
  },
  {
    path: "/newmeeting1",
    name: "NewMeeting1",
    component: NewMeeting1,
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects,
  },
  {
    path: "/brokerinitiation",
    name: "BrokerInitiation",
    component: BrokerInitiation,
  },
  {
    path: "/interestedbrokers",
    name: "InterestedBrokers",
    component: InterestedBrokers,
  },
  {
    path: "/backloginterestedbrokers",
    name: "BacklogInterestedBrokers",
    component: BacklogInterestedBrokers,
  },
  {
    path: "/callanalysis",
    name: "CallAnalysis",
    component: CallAnalysis,
  },
  {
    path: "/broker",
    name: "Broker",
    component: Broker,
  },
  {
    path: "/newbroker",
    name: "NewBroker",
    component: NewBroker,
  },
  {
    path: "/editbroker/:id",
    name: "EditBroker",
    component: EditBroker,
  },
  {
    path: "/brokerdetails/:id",
    name: "BrokerDetails",
    component: BrokerDetails,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;