<template>
  <div class="main-container bg-body">
    <div class="top-navbar">
      <h1>New Meeting</h1>
      <a @click="$router.go(-1)" class="icon-left">
        <img src="../assets/close.svg" alt="" @click="toggleSideBar" />
      </a>
    </div>
    <div class="form-main page-content">
      <div class="my-4">
        <Multiselect
          label="name"
          track-by="name"
          :searchable="true"
          :options="brokersOptions"
          v-model="meeting.broker_id"
          class="multi-select card-details-value card-details-select required"
          placeholder="Select broker"
          :canDeselect="false"
        />
        <div
          class="form-error-text"
          v-if="v$.meeting.broker_id.$invalid && isFormDirty"
        >
          This field is required
        </div>
      </div>
      <!-- <div class="audio-main ">
              <audio controls class="w-100">
                  <source type="audio/ogg">
                  <source type="audio/mpeg">
                  Your browser does not support the audio tag.
              </audio>
          </div> -->
      <div class="text-center mb-2 status-title">Recording Status</div>
      <div class="text-center mb-2 status" v-if="!startButton && !isStopActive">
        Not Recording
      </div>
      <div class="text-center mb-2 status" v-if="isPauseActive">
        Recording Paused
      </div>
      <!-- <div class="text-center mb-2 status" v-if="isResumeActive">Recording Resume</div> -->
      <div
        class="text-center mb-2 status"
        v-if="startButton && !isPauseActive && !isStopActive"
      >
        Recording...
      </div>
      <div class="text-center mb-2 status" v-if="isStopActive">
        Recording Completed
      </div>
      <div class="recording-controls">
        <button
          class="recording-btn"
          :class="{
            'btn-disabled': startButton,
            active: isStartActive && !isStopActive,
          }"
          type="button"
          id="record"
          @click="startRecord"
        >
          <div class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <mask
                id="mask0_398_715"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_398_715)">
                <path
                  d="M8 16H10V8H8V16ZM12 16L18 12L12 8V16ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                  fill="currentColor"
                />
              </g>
            </svg>
          </div>
          <div class="btn-copy">Start</div>
        </button>
        <button
          class="recording-btn"
          :class="{ 'btn-disabled': pauseButton, active: isPauseActive }"
          type="button"
          id="pause"
          @click="pauseAudio"
        >
          <div class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <mask
                id="mask0_398_711"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_398_711)">
                <path
                  d="M13 19V5H19V19H13ZM5 19V5H11V19H5ZM15 17H17V7H15V17ZM7 17H9V7H7V17Z"
                  fill="currentColor"
                />
              </g>
            </svg>
          </div>
          <div class="btn-copy">Pause</div>
        </button>
        <button
          class="recording-btn"
          :class="{ 'btn-disabled': resumeButton, active: isResumeActive }"
          type="button"
          id="resume"
          @click="resumeAudio"
        >
          <div class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <mask
                id="mask0_398_718"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_398_718)">
                <path
                  d="M6 18V6H8V18H6ZM10 18L20 12L10 6V18ZM12 14.475V9.525L16.125 12L12 14.475Z"
                  fill="currentColor"
                />
              </g>
            </svg>
          </div>
          <div class="btn-copy">Resume</div>
        </button>
        <button
          class="recording-btn stop"
          :class="{ 'btn-disabled': stopButton, active: isStopActive }"
          type="button"
          id="stop"
          @click="stopRecord"
        >
          <div class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <mask
                id="mask0_398_723"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_398_723)">
                <path
                  d="M8 16H16V8H8V16ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                  fill="#EE4B4B"
                />
              </g>
            </svg>
          </div>
          <div class="btn-copy">Stop</div>
        </button>
      </div>
      <button
        class="btn-outline"
        :class="{ 'btn-disabled': downloadButton }"
        id="download"
        @click="downloadAudio"
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <mask
              id="mask0_398_743"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <rect width="16" height="16" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_398_743)">
              <path
                d="M7.99996 10.6667L4.66663 7.33342L5.59996 6.36675L7.33329 8.10008V2.66675H8.66663V8.10008L10.4 6.36675L11.3333 7.33342L7.99996 10.6667ZM3.99996 13.3334C3.63329 13.3334 3.3194 13.2029 3.05829 12.9417C2.79718 12.6806 2.66663 12.3667 2.66663 12.0001V10.0001H3.99996V12.0001H12V10.0001H13.3333V12.0001C13.3333 12.3667 13.2027 12.6806 12.9416 12.9417C12.6805 13.2029 12.3666 13.3334 12 13.3334H3.99996Z"
                fill="#19A7CE"
              />
            </g>
          </svg>
          <span>Download before clicking on Next</span>
        </div>
      </button>
    </div>
    <div class="footer-section">
      <button
        type="button"
        class="btn-solid"
        :class="{ 'btn-disabled': isDownload }"
        @click="goToNext()"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "newmeeting1",
  components: {
    Multiselect,
  },
  data() {
    return {
      v$: useVuelidate(),
      meeting: {
        broker_id: "",
        recorded_audio_duration: null,
        recording: null,
      },
      brokersOptions: [],
      //         recordingStatus: "Record Sound",
      //   recording: false,
      //   title: "",
      //   recorder: null,
      //   blob: null,
      //   stream: null,
      finalChunks: [],
      startButton: false,
      pauseButton: true,
      resumeButton: true,
      stopButton: true,
      downloadButton: true,
      isStartActive: true,
      isPauseActive: false,
      isResumeActive: false,
      isStopActive: false,
      startTime: null,
      durationInSeconds: null,
      isDownload: false,
      isFormDirty: false,
    };
  },
  validations() {
    return {
      meeting: {
        broker_id: {
          required,
        },
      },
    };
  },
  methods: {
    /*
        I handle recording, but also stopping during recording. 
        */
    // async record() {
    //   if (this.recording) {
    //     this.stream.getAudioTracks()[0].stop();
    //     this.recorder.finishRecording();
    //     this.recordingStatus = "Record Sound";
    //     this.recording = false;
    //     return;
    //   }

    //   console.log("begin record");

    //   var constraints = {
    //     audio: true,
    //     video: false,
    // //   };

    //   this.stream = await navigator.mediaDevices.getUserMedia(constraints);

    //   var audioContext = new AudioContext();
    //   var input = audioContext.createMediaStreamSource(this.stream);

    //   let configs = {
    //     workerDir: "/web-audio-recorder/",
    //   };

    //   let recorder = new webkitAudioContext(input, configs);

    //   recorder.setOptions({
    //     timeLimit: 30,
    //     encodeAfterRecord: true,
    //     ogg: { quality: 0.5 },
    //     mp3: { bitRate: 160 },
    //   });

    //   recorder.onComplete = (recorder, blob) => {
    //     this.recordingStatus = "Record Sound";
    //     this.blob = blob;
    //   };

    //   recorder.startRecording();
    //   this.recorder = recorder;
    //   this.recordingStatus = "Stop Recording";
    //   this.recording = true;
    //   this.blob = null;
    //   console.log("ok it worked");
    // },
    // play() {
    //   let player = new window.Audio();
    //   player.src = window.URL.createObjectURL(this.blob);
    //   player.play();
    // },
    // async save() {
    //   console.log("save it");
    // //   let sound = {
    // //     title: this.title,
    // //     blob: this.blob,
    // //   };
    // //   await this.$store.dispatch("saveSound", sound);
    //   this.$router.push("/");
    // },
    startRecord() {
      this.startTime = Date.now();
      this.isStopActive = false;
      this.isStartActive = true;
      this.isDownload = true;
      this.startButton = true;
      this.pauseButton = false;
      this.stopButton = false;
      let audioChunks = [];
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then((stream) => {
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.start(1000);
          // const self = this
          this.mediaRecorder.ondataavailable = function (event) {
            audioChunks.push(event.data);
          };
          console.log("started");
        });
      this.finalChunks = audioChunks;
      console.log("final", this.finalChunks);
    },

    stopRecord() {
      if (this.startTime) {
        this.durationInSeconds += Date.now() - this.startTime;
      }
      this.durationInSeconds /= 1000;
      //this.durationInSeconds += (Date.now() - this.startTime);
      //this.durationInSeconds /= 1000;
      this.meeting.recorded_audio_duration = this.durationInSeconds;
      this.isStopActive = true;
      this.isResumeActive = false;
      this.startButton = false;
      this.pauseButton = true;
      this.resumeButton = true;
      this.stopButton = true;
      this.downloadButton = false;
      const self = this;
      this.mediaRecorder.stop();
      this.mediaRecorder.addEventListener("stop", () => {
        const audioBlob = new Blob(this.finalChunks, {
          type: "audio/ogg; codecs=opus",
        });
        this.meeting.recording = audioBlob;
        self.recordedVoice = URL.createObjectURL(audioBlob);
      });
      console.log("stopped");
    },
    pauseAudio() {
      this.durationInSeconds += Date.now() - this.startTime;
      this.startTime = null;
      this.isResumeActive = false;
      this.mediaRecorder.pause();
      this.isStartActive = false;
      this.isPauseActive = true;
      this.pauseButton = true;
      this.resumeButton = false;
      this.stopButton = false;
      console.log("pause");
    },
    resumeAudio() {
      this.startTime = Date.now();
      this.mediaRecorder.resume();
      this.isResumeActive = true;
      this.isPauseActive = false;
      this.resumeButton = true;
      this.pauseButton = false;
      console.log("resume");
    },
    downloadAudio() {
      if (this.finalChunks) {
        console.log(this.finalChunks);
        const blob = new Blob(this.finalChunks, {
          type: "audio/ogg; codecs=opus",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        const timestamp = Date.now();
        a.href = url;
        a.download = `${this.meeting.broker_id}-${timestamp}`;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.isDownload = false;
      }
    },
    getAllData() {
      this.$axios
        .get("builders/leads/meta_data")
        .then((response) => {
          this.brokersOptions = response.data.brokers;
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    goToNext() {
      // this.v$.$validate();
      if (this.v$.$invalid) {
        this.isFormDirty = true;
        return;
      }
      if (this.meeting.recording !== null || "") {
        const audioRecording = new FormData();
        audioRecording.append(
          "meeting[recorded_audio]",
          this.meeting.recording
        );
        audioRecording.append("meeting[broker_id]", this.meeting.broker_id);
        audioRecording.append(
          "meeting[recorded_audio_duration]",
          this.meeting.recorded_audio_duration
        );
        this.$axios
          .post("meetings", audioRecording)
          .then((response) => {
            console.log(response, "response");
            sessionStorage.setItem(
              "brokerData",
              JSON.stringify(response.data.meeting)
            );
            this.$router.push({
              path: "/newmeeting",
            });
          })
          .catch((err) => {
            this.$swal({
              title: err.response.data.message,
              icon: "warning",
            });
            if (err.response.status === 401 || err.response.status === 404) {
              localStorage.removeItem("cp-mandate-token");
              this.$router.replace("/");
            }
          });
      } else {
        const audioRecording = { meeting:{
          broker_id:this.meeting.broker_id
        }
      }
        this.$axios
          .post("meetings", audioRecording)
          .then((response) => {
            console.log(response, "response");
            sessionStorage.setItem(
              "brokerData",
              JSON.stringify(response.data.meeting)
            );
            this.$router.push({
              path: "/newmeeting",
            });
          })
          .catch((err) => {
            this.$swal({
              title: err.response.data.message,
              icon: "warning",
            });
            if (err.response.status === 401 || err.response.status === 404) {
              localStorage.removeItem("cp-mandate-token");
              this.$router.replace("/");
            }
          });
      }
    },
  },
  mounted() {
    this.getAllData();
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style><style scoped>
.form-main {
  padding: 0 16px;
}

.form-error-text {
  font-weight: 300;
  font-size: 13px;
  margin-top: 5px;
  color: red;
}

.form-error-text.floating__error {
  position: absolute;
  right: 12px;
  top: -14px;
  background: #ffffff;
  display: inline-block;
  margin-bottom: -20px;
}

.bg-body {
  min-height: 100vh;
  min-height: 100dvh;
  background-color: #fafafa !important;
}

.status-title {
  color: #888;
  font-size: 14px;
  font-weight: 400;
}

.status {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.recording-controls {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.recording-btn {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  min-width: 60px;
  width: 100%;
  flex-grow: 1;
  color: #888;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
  transition: 0.3s;
}

.recording-btn.active {
  color: #19a7ce;
}

.stop.recording-btn.active {
  color: #ee4b4b;
}

.recording-btn.active .icon {
  background-color: rgba(25, 167, 206, 0.12);
}

.recording-btn.active.stop .icon {
  background-color: rgba(238, 75, 75, 0.12);
}

.recording-btn .icon {
  width: 100%;
  border-radius: 8px;
  border: 1px solid currentColor;
  background: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
}

.btn-outline {
  padding: 12px;
  color: #19a7ce;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.56px;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 34px;
  border: 1px solid #19a7ce;
  background: #fff;
}

.btn-solid {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 12px;
  width: 100%;
  display: block;
  border-radius: 34px;
  border: 1px solid #19a7ce;
  background: #19a7ce;
}

.disabled {
  filter: grayscale(0.6);
  cursor: not-allowed;
  pointer-events: none;
}

.btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.btn-disabled .icon {
  background-color: #d5d5d5;
}
</style>
