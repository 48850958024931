<template>
    <div class="main-container">
      <div class="top-navbar">
        <h1>Edit Meeting</h1>
        <a @click="$router.go(-1)" class="icon-left">
          <img src="../assets/close.svg" alt="" @click="toggleSideBar" />
        </a>
      </div>
      <div class="d-flex justify-content-center flex-column page-content">
        <div class="form-main pt-4">
          <div>
            <Multiselect label="name" track-by="name" v-model="editMeeting.meeting.user_id" :options="userOptions"
              :searchable="true" class="multi-select card-details-value card-details-select" placeholder="Select User"
              :canDeselect="false" />
              <div class="form-error-text" v-if="v$.editMeeting.meeting.user_id.$invalid && isFormDirty">
          This field is required
        </div>
          </div>
          <div>
            <Multiselect label="name" track-by="name" v-model="editMeeting.meeting.broker_id" :options="brokersOptions"
              :searchable="true" class="multi-select card-details-value card-details-select" placeholder="Select Broker"
              :canDeselect="false" disabled />
          </div>
          <div class="date-picker-input">
            <VueDatePicker class="" id="datepicker1" v-model="editMeeting.meeting.start_time" placeholder="Start Time">
            </VueDatePicker>
            <div class="form-error-text" v-if="v$.editMeeting.meeting.start_time.$invalid && isFormDirty">
          This field is required
        </div>
          </div>
          <div class="date-picker-input">
            <VueDatePicker class="" id="datepicker1" v-model="editMeeting.meeting.end_time" placeholder="End Time">
            </VueDatePicker>
            <div class="form-error-text" v-if="v$.editMeeting.meeting.end_time.$invalid && isFormDirty">
          This field is required
        </div>
          </div>
          <!-- <div class="form-floating mb-3 cp-form" v-if="loadGoogleSCript">
          <vue-google-autocomplete ref="address" id="map" classname="form-control" placeholder="Please type your address"
            v-on:placechanged="getAddressData" country="in">
          </vue-google-autocomplete>
          <label for="comment">Please type your address</label>
          <div class="form-error-text" v-if="v$.editMeeting.meeting.address.$invalid && isFormDirty">
        This field is required
      </div>
        </div> -->
        </div>
        <div class="rate-overall pb-2">Rate overall meeting quality</div>
        <div class="form-error-text" v-if="v$.editMeeting.meeting.rating.$invalid && isFormDirty">
          This field is required
        </div>
        <div class="rating mb-4">
          <div v-for="index in 5" :key="index" class="icon" @click="setRating(index)">
            <svg :class="{ active: index <= editMeeting.meeting.rating }" xmlns="http://www.w3.org/2000/svg" width="28"
              height="28" viewBox="0 0 28 28" fill="none">
              <g clip-path="url(#clip0_443_933)">
                <path
                  d="M21.7617 27.2844L14 22.4273L6.23825 27.2843C5.95628 27.463 5.59255 27.4493 5.32103 27.2522C5.05027 27.056 4.92608 26.7147 5.00778 26.3903L7.22837 17.507L0.293333 11.6271C0.0369581 11.4124 -0.0631747 11.0631 0.0401847 10.7451C0.143544 10.4263 0.430325 10.2028 0.763591 10.1795L9.81514 9.54744L13.239 1.05512C13.4905 0.435076 14.5095 0.435076 14.761 1.05512L18.1848 9.54744L27.2364 10.1795C27.5697 10.2028 27.8564 10.4263 27.9598 10.7451C28.0632 11.0631 27.963 11.4124 27.7067 11.6271L20.7716 17.5071L22.9922 26.3904C23.0739 26.7148 22.9497 27.0561 22.6789 27.2524C22.4018 27.4536 22.0391 27.4581 21.7617 27.2844Z"
                  fill="#E6E6E6" />
              </g>
              <defs>
                <clipPath id="clip0_443_933">
                  <rect width="28" height="28" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div class="form-floating mb-3 mx-3 cp-form disabled">
        <textarea rows="4" v-model="editMeeting.meeting.address" class="form-control disabled" disabled id="minutes">
  Fazalpur Mehrola Rudrapur, Jagatpur, Uttarakhand 244923, India</textarea>
      </div>
        <div class="cp-main-label py-4 px-3">
          <div class="form-floating mb-3 cp-form">
            <textarea rows="4" class="form-control" placeholder="Comment" id="minutes2"
              v-model="editMeeting.meeting.comment"></textarea>
            <label for="comment">Minutes of Meeting</label>
            <div class="form-error-text" v-if="v$.editMeeting.meeting.comment.$invalid && isFormDirty">
          This field is required
        </div>
          </div>
          <div class="cp-main">
            <div class="upload-btn-wrapper" v-if="getAllResponseData && (getAllResponseData.is_superadmin || getAllResponseData.can_upload)">
              <button class="btn">Upload Image <img src="../assets/upload.svg" alt=""></button>
              <input type="file" name="myfile" @change="uploadFile" />
            </div>
            <div class="upload-btn-wrapper" @click="toggleCamera" v-if="!isCameraOpen && getAllResponseData && !getAllResponseData.is_superadmin">
              <button class="btn">Open Camera For Selfie</button>
            </div>
            <!-- <div class="view" @click="showImage">
              <img src="../assets/panorama.svg" alt="" /> View
            </div> -->
          </div>
          <div class="web-camera-container" v-if="isCameraOpen">
          <!-- <button class="btn" v-if="isCameraOpen" @click="toggleCamera">CLose</button> -->
          <div class="camera-button" v-if="isCameraOpen" @click="toggleCamera">
            <button type="button" class="btn btn-block">
                <div class="d-flex mx-auto align-items-center justify-content-center">
                    <img src="@/assets/photo-camera.svg" class=" mr-2 camera-image opacity-80">
                    <span class="mt-1 d-block">Close Camera</span>
                </div>
            </button>
        </div>
          <div v-if="isCameraOpen" class="camera-box" :class="{ 'flash' : isShotPhoto }">
            <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
            <video v-show="!isPhotoTaken" ref="camera" :width="450" :height="337.5" class="my-4" autoplay playsinline></video>
            <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" class="my-4" :width="canvasWidth" :height="canvasHeight"></canvas>
        </div>
        <div class="d-flex camera-op justify-content-center position-relative">
            <div v-if="isCameraOpen" class=" ">
                <button type="button" class="btn camera-shoot mx-auto p-2 d-flex justify-content-center align-items-center" @click="takePhoto">
                    <img src="@/assets/capture.svg" class="opacity-80">
                </button>
            </div>
            <div v-if="isCameraOpen" class="">
                <button type="button" class="btn camera-shoot camera-switch mx-auto p-2 d-flex justify-content-center align-items-center" @click="switchCamera">
                    <img src="@/assets/flip-camera.svg" class="opacity-80">
                </button>
            </div>
        </div>
        </div>
          <div class="form-error-text" v-if="v$.editMeeting.meeting.image_base64.$invalid && isFormDirty">
          This field is required
        </div>
        <div class="cp-main-label">
            <div class="cp-main-new">
              <input class="form-check-input" type="checkbox" v-model="editMeeting.meeting.revisited" value="" id="default">
              <label class="form-check-label" for="default">
                Revisited
              </label>
            </div>
          </div>
        </div>
        <div class="form-main bg-white" v-for="(campaign, index) in editMeeting.meeting.campaigns_attributes" :key="index">
          <div class="Campaign pt-0">Campaign {{ index + 1 }}</div>
          <div>
            <Multiselect label="name" track-by="name" :searchable="true" :options="projectsOptions"
              class="multi-select card-details-value card-details-select" placeholder="Project"
              v-model="campaign.project_id" :canDeselect="true" />
          </div>
          <div>
            <input type="text" label="name" class="form-control" v-model="campaign.title" placeholder="Title" />
          </div>
          <div class="date-picker-input">
            <VueDatePicker class="" id="datepicker1" v-model="campaign.start_date" placeholder="Start Time"></VueDatePicker>
          </div>
          <div class="date-picker-input required">
            <VueDatePicker class="" id="datepicker1" v-model="campaign.end_date" placeholder="End Time"></VueDatePicker>
          </div>
          <div>
            <Multiselect label="name" track-by="name" :searchable="true" :options="campaignOptions"
              class="multi-select card-details-value card-details-select" placeholder="Campaign Type"
              v-model="campaign.campaign_type" :canDeselect="true" />
          </div>
          <div class="form-floating mb-3 cp-form">
            <textarea rows="4" class="form-control" placeholder="Minutes of Meeting" id="comment"
              v-model="campaign.comment"></textarea>
            <label for="comment">Minutes of Meeting</label>
          </div>
          <div class="form-group m-3 mb-5 submit-section" v-if="isCampaign">
          <div class="delete" @click="deleteCampaign(index,campaign)">
            <img src="../assets/delete.svg" alt="" />&nbsp;Delete Campaign
        </div>
      </div>
        </div>
        <div class="form-group m-3 mb-5 submit-section">
          <button type="button" class="bttn  add-campaign" @click="addCampaign"><img src="../assets/plus-blue.svg" alt="" />
            Add Campaign </button>
        </div>
        <div class="footer-section">
          <button type="button" class="bttn btn-clr" @click="submitMeeting"><img src="../assets/save.svg" alt="" />
            Save</button>
          <button type="button" class="bttn  clear-all" @click="$router.go(-1)">Cancel</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Multiselect from "@vueform/multiselect";
  import VueDatePicker from "@vuepic/vue-datepicker";
  import "@vuepic/vue-datepicker/dist/main.css";
  //import VueGoogleAutocomplete from "vue-google-autocomplete";
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'editincompletemeeting',
    components: {
      Multiselect,
      VueDatePicker,
      //VueGoogleAutocomplete
    },
    data() {
      return {
        v$: useVuelidate(),
        editMeeting: {
          meeting: {
            user_id: "",
            broker_id: "",
            start_time: "",
            end_time: "",
            rating: "",
            //latitude: "",
            // longitude: "",
            image_base64:null,
            image_filename:"",
            address:"",
            revisited: "",
            comment: "",
            campaigns_attributes: [{
              project_id: '',
              title: '',
              start_date: "",
              end_date: "",
              campaign_type: "",
              comment: ""
            }]
          },
          id: "19605",
        },
        editMeetingDetails: [],
        brokersOptions: [],
        statusOptions: [],
        userOptions: [],
        sourcesOptions: [],
        projectsOptions: [],
        executivesOptions: [],
        getAllResponseData:[],
        cpOptions: [],
        campaignOptions: [],
        newImage: false,
        editAddress: "",
        isFormDirty:false,
        loadGoogleSCript: false,
        isCampaign:false,
        isCameraOpen: false,
        isPhotoTaken: false,
        isShotPhoto: false,
        photos: [],
        canvasWidth: 450,
        canvasHeight: 337.5,
        videoDevices: [],
        mediaStream: null,
        facingMode: "environment",
        switchingCamera: false,
        finalImage: null,
        photoSet: null,
        checkSize: false,
        checkPhoto: false,
        maxImageSize: 5 * 1024 * 1000,
        isTimeExpired: false
      }
    },
    validations () {
      return {
        editMeeting: {
          meeting: {
            user_id:{required},
            start_time: {required},
            end_time: {required},
            rating: {required},
            image_base64:{required},
            //address:{required},
            comment: {required}
          }
        }
      }
    },
    beforeUnmount() {
    if (this.isCameraOpen) {
      this.stopCameraStream();
    }
  },
    methods: {
      async switchCamera() {
      this.switchingCamera = true;
      const tracks = this.mediaStream.getVideoTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      await this.createCameraElement(
        this.facingMode === "environment" ? "user" : "environment"
      );
      this.switchingCamera = false;
    },
    async toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        const devices = await navigator.mediaDevices.enumerateDevices();
        this.videoDevices = devices.filter((d) => d.kind === "videoinput");
        await this.createCameraElement(
          this.videoDevices.length === 1 ? "user" : "environment"
        );
        const dimensions = this.videoDimensions(this.$refs.camera);
        this.canvasWidth = dimensions.width;
        this.canvasHeight = dimensions.height;
      }
    },
    async createCameraElement(facingMode) {
      this.facingMode = facingMode;
      let video = this.$refs.camera;
      this.mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: facingMode },
      });
      video.srcObject = this.mediaStream;
      return await video.play();
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();
      tracks.forEach(track => {
        track.stop();
      });
    },
    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;
        const FLASH_TIMEOUT = 50;
        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }
      this.isPhotoTaken = !this.isPhotoTaken;
      const context = this.$refs.canvas.getContext('2d');

      context.drawImage(this.$refs.camera, 0, 0, this.canvasWidth, this.canvasHeight);
      this.finalImage = this.dataURLtoFile(document.getElementById("photoTaken").toDataURL("image/jpeg"), "selfie-file");
      this.convertBase64(this.finalImage);
      if (this.finalImage) {
        this.photoSet = true;
        this.checkPhoto = false;
      } else {
        this.photoSet = null;
      }
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      if (n > this.maxImageSize) {
        this.checkSize = true;
        return null;
      } else {
        this.checkSize = false;
      }
      const u8arr = new Uint8Array(n);
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1;
      }
      return new File([u8arr], filename, { type: mime });
    },
    videoDimensions(video) {
      // Ratio of the video's intrisic dimensions
      let videoRatio = video.videoWidth / video.videoHeight;
      // The width and height of the video element
      let width = video.offsetWidth,
        height = video.offsetHeight;
      // The ratio of the element's width to its height
      let elementRatio = width / height;
      // If the video element is short and wide
      if (elementRatio > videoRatio) width = height * videoRatio;
      // It must be tall and thin, or exactly equal to the original ratio
      else height = width / videoRatio;
      return {
        width: width,
        height: height
      };
    },
      uploadFile(event) {
        this.newImage = true;
        const file = event.target.files[0];
        this.editMeeting.meeting.image_filename = file.name;
        this.convertBase64(file);
      },
      convertBase64(file) {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
  
          fileReader.onload = () => {
            this.editMeeting.meeting.image_base64 = fileReader.result;
            resolve(fileReader.result)
          }
  
          fileReader.onerror = (error) => {
            reject(error)
          }
        })
      },
    //   getAddressData(addressData, placeResultData) {
    //   this.$loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBKUqFrbcYcXi2gJy_z86bFVbNkRPL7ZDw&libraries=places")
    //     .then(() => {
    //       this.editMeeting.meeting.latitude = addressData.latitude;
    //       this.editMeeting.meeting.longitude = addressData.longitude;
    //       this.editMeeting.meeting.address = placeResultData.formatted_address;
    //       this.loadGoogleSCript = true;
    //     })
    //     .catch(() => {
    //       // Script couldn't be found to unload; make sure it was loaded and that you passed the same URL
    //     });
    // },
      setRating(value) {
        this.editMeeting.meeting.rating = value;
      },
      showImage() {
        window.open(this.editMeetingDetails.image);
      },
      submitMeeting() {
        if (this.v$.$invalid) {
          this.isFormDirty = true;
          return;
        }
        // let MeetingPayload = {};
        // if(this.newImage) {
        //             MeetingPayload = {
        //         meeting : {
        //         user_id:this.editMeeting.meeting.user_id,
        //         broker_id: this.editMeeting.meeting.broker_id,
        //         start_time: this.editMeeting.meeting.start_time,
        //         end_time: this.editMeeting.meeting.end_time,
        //         rating: this.editMeeting.meeting.rating,
        //         // latitude: this.editMeeting.meeting.latitude,
        //         // // longitude: this.editMeeting.meeting.longitude,
        //         image_base64:this.editMeeting.meeting.image_base64,
        //         // address:this.editMeeting.meeting.address,
        //         revisited: this.editMeeting.meeting.revisited,
        //         comment: this.editMeeting.meeting.comment,
        //         campaigns_attributes:[{
        //         project_id: this.editMeeting.meeting.campaigns_attributes.project_id,
        //         title: this.editMeeting.meeting.campaigns_attributes.title,
        //         start_date: this.editMeeting.meeting.campaigns_attributes.start_date,
        //         end_date: this.editMeeting.meeting.campaigns_attributes.end_date,
        //         campaign_type:this.editMeeting.meeting.campaigns_attributes.campaign_type,
        //         comment:this.editMeeting.meeting.campaigns_attributes.comment
        //         }]
        //         }
        //   }
  
        // } else {
        //             MeetingPayload = {
        //         meeting : {
        //         user_id:this.editMeeting.meeting.user_id,
        //         broker_id: this.editMeeting.meeting.broker_id,
        //         start_time: this.editMeeting.meeting.start_time,
        //         end_time: this.editMeeting.meeting.end_time,
        //         rating: this.editMeeting.meeting.rating,
        //         // latitude: this.editMeeting.meeting.latitude,
        //         // longitude: this.editMeeting.meeting.longitude,
        //         // image_base64:this.editMeeting.meeting.image_base64,
        //         // address:this.editMeeting.meeting.address,
        //         revisited: this.editMeeting.meeting.revisited,
        //         comment: this.editMeeting.meeting.comment,
        //         campaigns_attributes:[{
        //         project_id: this.editMeeting.meeting.campaigns_attributes.project_id,
        //         title: this.editMeeting.meeting.campaigns_attributes.title,
        //         start_date: this.editMeeting.meeting.campaigns_attributes.start_date,
        //         end_date: this.editMeeting.meeting.campaigns_attributes.end_date,
        //         campaign_type:this.editMeeting.meeting.campaigns_attributes.campaign_type,
        //         comment:this.editMeeting.meeting.campaigns_attributes.comment
        //         }]
        //         }
        //   }
        // }
        //   console.log("pay",MeetingPayload);
        this.$axios.patch(`meetings/${this.editMeetingDetails.value}`, this.editMeeting).then((res) => {
          console.log(res, "submitted");
          this.$router.push({
            path: "/managemeetings",
            // query: {
            //     uuid: this.$route.query.uuid
            // }
          });
        })
          .catch(err => {
            this.$swal({
              title: err.response.data.message,
              icon: "warning",
            })
          })
      },
      addCampaign() {
        this.isCampaign = true;
        this.editMeeting.meeting.campaigns_attributes.push({
          project_id: '',
          title: '',
          start_date: "",
          end_date: "",
          campaign_type: "",
          comment: ""
        })
      },
      deleteCampaign(index) {
        this.editMeeting.meeting.campaigns_attributes.splice(index, 1);
      },
      getAllData() {
        this.$axios
          .get("builders/leads/meta_data")
          .then((response) => {
            console.log("response", response);
            this.getAllResponseData = response.data;
            this.statusOptions = response.data.status;
            this.userOptions = response.data.users;
            this.sourcesOptions = response.data.sources;
            this.projectsOptions = response.data.projects;
            this.brokersOptions = response.data.brokers;
            this.executivesOptions = response.data.executives;
            this.cpOptions = response.data.cp_status;
            this.campaignOptions = response.data.campaign_types;
  
          })
          .catch((err) => console.log(err));
      },
      getCurrentPosition() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.editMeeting.meeting.latitude = position.coords.latitude;
                    this.editMeeting.meeting.longitude = position.coords.longitude;
                    this.getStreetAddressFrom(position.coords.latitude, position.coords.longitude);
                }, (error) => {
                    console.log(error);
                }, { timeout: 10000, enableHighAccuracy: true, maximumAge: 0 });
            } else {
                //Geolocation is not supported by this browser
                console.log('Geolocation is not supported by this browser');
            }
        },
        async getStreetAddressFrom(lat, long) {
            try {
                let response = await fetch(
                    "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                    lat +
                    "," +
                    long +
                    "&key=AIzaSyBKUqFrbcYcXi2gJy_z86bFVbNkRPL7ZDw"
                );
                const responseAddress = await response.json();
                if (responseAddress.error_message) {
                    console.log(responseAddress.error_message)
                } else {
                    this.editMeeting.meeting.address = responseAddress.results[0].formatted_address;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mounted() {
      //this.getCurrentPosition();
      this.editMeetingDetails = JSON.parse(sessionStorage.getItem("editData"));
      console.log("editData", this.editMeetingDetails);
      this.getAllData();
      this.editMeeting.meeting.start_time = this.editMeetingDetails.start_time;
      this.editMeeting.meeting.end_time = this.editMeetingDetails.end_time;
      this.editMeeting.meeting.comment = this.editMeetingDetails.comment;
      this.editMeeting.meeting.rating = this.editMeetingDetails.rating;
      this.editMeeting.meeting.user_id = this.editMeetingDetails.user_id;
      this.editMeeting.meeting.broker_id = this.editMeetingDetails.broker_id;
      this.editAddress = this.editMeetingDetails.address;
      this.editMeeting.meeting.campaigns_attributes = this.editMeetingDetails.campaign_details;
      //     this.editMeeting.meeting = {
      //     ...this.editMeeting.meeting,
      //     ...this.editMeetingDetails
      //   }
      // this.$loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBKUqFrbcYcXi2gJy_z86bFVbNkRPL7ZDw&libraries=places")
      // .then(() => {
      //   this.loadGoogleSCript = true;
      // })
      // .catch(() => {
      //   // Script couldn't be found to unload; make sure it was loaded and that you passed the same URL
      // });
    },
    created() { 
      this.getCurrentPosition();
    }
  }
  </script>
  
  <style scoped>
  .main-container {
    background-color: #FAFAFA;
  }
  
  .form-error-text {
    font-weight: 300;
    font-size: 13px;
    margin-top: 5px;
    color: red;
  }
  
  .form-error-text.floating__error {
    position: absolute;
    right: 12px;
    top: -14px;
    background: #ffffff;
    display: inline-block;
    margin-bottom: -20px;
  }
  
  .cp-main-label {
    /* gap: 25px; */
    display: flex;
    flex-direction: column;
    padding: 24px 0 24px 16px;
  }
  
  .save {
    gap: 8px;
    padding-top: 72px;
    padding-bottom: 8px;
  }
  
  .submit-section {
    position: relative;
  }
  
  .add-campaign {
    /* background-color: #19A7CE; */
    width: 100%;
    color: #19a7ce;
    border: 1px solid #19a7ce;
    background: #FFFFFF;
    border-radius: 34px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 12px;
  }
  
  .btn-clr {
    /* background-color: #19A7CE; */
    width: 100%;
    color: #FFFFFF;
    border: 1px solid #19a7ce;
    background: #19A7CE;
    border-radius: 34px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 12px;
  }
  
  .view {
    padding: 10px 15px;
    border: 1px solid #19a7ce;
    border-radius: 34px;
    color: #19a7ce;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.56px;
  }
  
  .clear-all {
    background-color: #FFFFFF;
    width: 100%;
    color: #19a7ce;
    border: 1px solid #19a7ce;
    border-radius: 34px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    padding: 12px;
  }
  
  .Campaign {
    padding: 16px;
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
  }
  
  .cp-main {
    display: flex;
    flex-direction: row;
    gap: 31px
  }

  .cp-main-new {
    display: flex;
    flex-direction: row;
    gap: 10px
  }
  
  .cp-label {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .text {
    margin: 16px;
  }
  
  .cp-details {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    justify-content: center;
    box-shadow: 0px 2px 4px 0px #0000000d;
    background-color: #fff;
  }
  
  .tele-calling-details-title {
    font-size: 16px;
    font-weight: 800;
    line-height: 23px;
    letter-spacing: 0em;
    color: #000000;
  }
  
  .img-detail {
    position: absolute;
    left: 16px;
  }
  
  .form-main {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 24px;
  }

  .delete {
    color: #EE4B4B;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 20px 20px 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.56px;
}

  
  .card-details-select {
    padding: 5px 15px;
  }
  
  .card-details-value {
    border-radius: 8px !important;
    /* opacity: 0.5;
          padding: 12px 20px; */
    color: #bfbfbf;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    border: 1px solid #B9B9B9;
    /* padding: 15px; */
  
  }
  
  .start-time {
    padding: 15px;
  }
  
  .rate-overall {
    display: flex;
    justify-content: center;
    padding: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #888888;
  
  }
  
  /* rating */
  .rating {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
  
  .rating label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    cursor: pointer;
  }
  
  .rating label:last-child {
    position: static;
  }
  
  .rating label:nth-child(1) {
    z-index: 5;
  }
  
  .rating label:nth-child(2) {
    z-index: 4;
  }
  
  .rating label:nth-child(3) {
    z-index: 3;
  }
  
  .rating label:nth-child(4) {
    z-index: 2;
  }
  
  .rating label:nth-child(5) {
    z-index: 1;
  }
  
  .rating label input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  
  .rating label .icon {
    float: left;
    color: transparent;
  }
  
  .rating label:last-child .icon {
    color: #E6E6E6;
    ;
  }
  
  .rating:not(:hover) label input:checked~.icon,
  .rating:hover label:hover input~.icon {
    color: #FFCE3E;
  }
  
  .rating label input:focus:not(:checked)~.icon:last-child {
    color: #000;
    text-shadow: 0 0 5px #09f;
  }
  
  /* fileuploader */
  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    flex: 1;
  }
  
  .btn {
    border: 1px solid #B9B9B9;
    color: #888888;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    /* font-size: 20px; */
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    width: 100%;
  }
  
  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  
  .rating .icon .active path {
    fill: #FFCE3E !important;
  }

  .web-camera-container {
    margin-top: 1rem;
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfdfe8;
    border-radius: 4px;
    width: 100%;
}

#photoTaken {
    width: 100%;
}

.camera-image {
    width: 2rem;
}

.opacity-80 {
    opacity: 0.8;
}

.selfie-subtitle {
    opacity: 0.5;
}

.camera-op {
    width: 100%;
}

.camera-shoot {
    border: 1px solid #D1D5DB;
    background: #F3F4F6;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
}

.camera-shoot-image {
    width: 3.5rem;
    height: 3.5rem;
}

.camera-switch {
    position: absolute;
    right: 0;
}

.camera-button {
    width: 100%;
}
  </style>
  