<template>
  <div class="main-container">
    <div class="d-flex justify-content-center flex-column main-container">
      <div class="cp-details">
        <h1 class="tele-calling-details-title">Advance Search</h1>
        <a @click="$router.go(-1)" class="back-arrow">
          <img
            class="img"
            src="../assets/close.svg"
            alt=""
            @click="toggleSideBar"
          />
        </a>
      </div>
      <div class="form-main">
        <div class="input-group flex-nowrap">
          <input
            type="text"
            class="form-control card-details-value"
            placeholder="Lead Name"
            aria-label="Name"
            aria-describedby="addon-wrapping"
          />
        </div>
        <div class="user-input-wrp">
          <br />
          <input type="number" class="inputText" required />
          <span class="floating-label">Mobile Number</span>
        </div>
        <div class="input-group flex-nowrap">
          <input
            type="text"
            class="form-control card-details-value"
            placeholder="Lead Email"
            aria-label="Name"
            aria-describedby="addon-wrapping"
          />
        </div>
        <div>
          <!-- <label for="" class="card-ljdetails-label">Lead City</label> -->
          <!-- link for multiselect library https://github.com/vueform/multiselect -->
          <Multiselect
            label="name"
            track-by="name"
            v-model="leadSearch.project_id"
            :searchable="true"
            class="multi-select card-details-value card-details-select"
            :options="projectsOptions"
            placeholder="Select Project"
            :canDeselect="false"
          />
        </div>
        <div>
          <Multiselect
            label="name"
            track-by="name"
            v-model="leadSearch.source"
            :searchable="true"
            class="multi-select card-details-value card-details-select"
            placeholder="Select Source"
            :options="sourcesOptions"
            :canDeselect="false"
          />
        </div>
        <div>
          <Multiselect
            label="name"
            track-by="name"
            v-model="leadSearch.status"
            :searchable="true"
            class="multi-select card-details-valuen card-details-select"
            :options="statusOptions"
            placeholder="Select Status"
            :canDeselect="false"
          />
        </div>
        <div>
          <Multiselect
            label="name"
            track-by="name"
            :searchable="true"
            v-model="leadSearch.cp_status"
            class="multi-select card-details-value card-details-select"
            :options="cpOptions"
            placeholder="Select CP Status"
            :canDeselect="false"
          />
        </div>
        <div>
          <Multiselect
            label="name"
            track-by="name"
            :searchable="true"
            v-model="leadSearch.brokers"
            class="multi-select card-details-value card-details-select"
            :options="brokersOptions"
            placeholder="Select Broker"
            :canDeselect="false"
          />
        </div>
        <div>
          <Multiselect
            label="name"
            track-by="name"
            :searchable="true"
            v-model="leadSearch.executives"
            class="multi-select card-details-value card-details-select"
            :options="executivesOptions"
            placeholder="Select Calling Executive"
            :canDeselect="false"
          />
        </div>
        <div>
          <Multiselect
            label="name"
            track-by="name"
            :searchable="true"
            v-model="leadSearch.executives"
            class="multi-select card-details-value card-details-select"
            :options="executivesOptions"
            placeholder="Select Field Executive"
            :canDeselect="false"
          />
        </div>

        <div class="w-100">
          <div class="input-group flex-nowrap">
            <input
              type="datetime-local"
              class="form-control card-details-value"
              placeholder="MM/DD/YYYY"
              aria-label="Name"
              aria-describedby="addon-wrapping"
            />
          </div>
        </div>
        <div class="w-100">
          <div class="input-group flex-nowrap">
            <input
              type="datetime-local"
              class="form-control card-details-value"
              placeholder="NCD To"
              aria-label="Name"
              aria-describedby="addon-wrapping"
            />
          </div>
        </div>
        <div class="w-100">
          <div class="input-group flex-nowrap">
            <input
              type="datetime-local"
              class="form-control card-details-value"
              placeholder="Created at From"
              aria-label="Name"
              aria-describedby="addon-wrapping"
            />
          </div>
        </div>
        <div class="w-100">
          <div class="input-group flex-nowrap">
            <input
              type="datetime-local"
              class="form-control card-details-value"
              placeholder="Created at To"
              aria-label="Name"
              aria-describedby="addon-wrapping"
            />
          </div>
        </div>
        <div class="w-100">
          <div class="input-group flex-nowrap">
            <input
              type="datetime-local"
              class="form-control card-details-value"
              placeholder="Visit Date From"
              aria-label="Name"
              aria-describedby="addon-wrapping"
            />
          </div>
        </div>
        <div class="w-100">
          <div class="input-group flex-nowrap">
            <input
              type="datetime-local"
              class="form-control card-details-value"
              placeholder="Visit Date To"
              aria-label="Name"
              aria-describedby="addon-wrapping"
            />
          </div>
        </div>
        <div class="w-100">
          <div class="input-group flex-nowrap">
            <input
              type="datetime-local"
              class="form-control card-details-value"
              placeholder="Booking Date From"
              aria-label="Name"
              aria-describedby="addon-wrapping"
            />
          </div>
        </div>
        <div class="w-100">
          <div class="input-group flex-nowrap">
            <input
              type="datetime-local"
              class="form-control card-details-value"
              placeholder="NCD To"
              aria-label="Name"
              aria-describedby="addon-wrapping"
            />
          </div>
        </div>
        <div class="d-flex">
          <div class="d-flex checkbox-main">
            <input type="checkbox" />
            <div>Visited</div>
          </div>
          <div class="d-flex checkbox-main">
            <input type="checkbox" checked disabled />
            <div>Revisited</div>
          </div>
        </div>
        <div class="d-flex checkbox-main">
          <input type="checkbox" checked disabled />
          <div>Is qualified?</div>
        </div>
        <div class="form-group m-3 submit-section">
          <button type="button" class="bttn btn btn-clr">Clear All</button>
        </div>
        <div class="form-group m-3 submit-section">
          <div class="search-img"></div>
          <button
            type="button"
            class="bttn btn search-clr"
            @click="advanceSearch()"
          >
            <img src="../assets/search-white.svg" alt="" /> Search
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "advancesearch",
  components: {
    Multiselect,
  },
  data() {
    return {
      leadSearch: {
        // builders_leads:{
        name: "",
        phone: "",
        email: "",
        project_name: "",
        source: "",
        status: "",
        broker_status: "",
        broker_name: "",
        calling_executive: "",
        field_executive: "",
        ncd: null,
        created_at: "",
        updated_at: "",
        visit_details: [],
        booking_date: null,
        visited: "",
        revisited: "",
        is_qualified: "",
        // }
      },
      statusOptions: [],
      userOptions: [],
      sourcesOptions: [],
      projectsOptions: [],
      brokersOptions: [],
      executivesOptions: [],
      cpOptions: [],
    };
  },
  methods: {
    advanceSearch() {
      this.$axios
        .post("builders/leads/search", {
          q: this.leadSearch,
        })
        .then((res) => {
          console.log(res, "Res");
        })
        .catch((err) => {
          console.log(err);
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    getAllData() {
      this.$axios
        .get("builders/leads/meta_data")
        .then((response) => {
          this.statusOptions = response.data.status;
          this.userOptions = response.data.users;
          this.sourcesOptions = response.data.sources;
          this.projectsOptions = response.data.projects;
          this.brokersOptions = response.data.brokers;
          this.executivesOptions = response.data.executives;
          this.cpOptions = response.data.cp_status;
        })
        .catch((err) => {
          console.log(err);
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
  },
  mounted() {
    this.advanceSearch();
    this.getAllData();
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style><style scoped>
.main-container {
  background-color: #f1e9e9;
}

.checkbox-main {
  align-items: center;
  gap: 10px;
  flex: 1;
  margin-top: 10px;
}

.cp-details {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  box-shadow: 0px 2px 4px 0px #0000000d;
  background-color: #fff;
}

.form-main {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  background: #ffffff;
}

.tele-calling-details-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  color: #000000;
}

.img {
  position: absolute;
  left: 16px;
}

.card-details-value {
  border-radius: 8px !important;
  /* opacity: 0.5; */
  padding: 12px 20px;
  color: #bfbfbf;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  border: 1px solid #b9b9b9;
  padding: 15px;
}

.card-details-select {
  padding: 5px 15px;
}

.btn-clr {
  /* background-color: #19A7CE; */
  width: 100%;
  color: #19a7ce;
  border: 1px solid #19a7ce;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}

.search-clr {
  width: 100%;
  background-color: #19a7ce;
  /* border: 1px solid #19A7CE; */
  color: #ffffff;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.submit-section {
  position: relative;
}

.user-input-wrp {
  position: relative;
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  width: 100%;
  background: white;
}

.user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  /* border-bottom: 1px solid #777; */
  box-shadow: none !important;
  padding-bottom: 3px;
  padding-left: 16px;
}

.user-input-wrp .inputText:focus {
  border-color: blue;
  border-width: medium medium 2px;
}

.user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 18px;
  left: 10px;
  transition: 0.2s ease all;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #888888;
}

.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:not(:focus):valid ~ .floating-label {
  top: 6px;
  left: 16px;
  font-size: 13px;
  opacity: 1;
}
</style>
