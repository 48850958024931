<template>
  <div class="d-flex justify-content-between flex-column main-container">
    <div class="top-navbar">
      <h1>Assign Calling Executive</h1>
      <a @click="$router.go(-1)" class="icon-left">
        <img src="../assets/close.svg" alt="" />
      </a>
    </div>
    <div class="calling-excutive-main page-content">
      <div class="manual-assig mt-4">
        Manual Assignment of Enquiries - Calling Executive
      </div>
      <div>
        <Multiselect
          label="name"
          track-by="name"
          name="calling_Excutive_Form"
          v-model="callingExcutiveForm.from_assigned_user"
          :searchable="true"
          class="multi-select card-details-value card-details-select"
          placeholder="Select From"
          :options="userOptions"
          :canDeselect="false"
        />
      </div>
      <div class="error">{{ errors?.calling_Excutive_Form }}</div>
      <div>
        <Multiselect
          label="name"
          track-by="name"
          name="calling_Excutive_Form"
          v-model="callingExcutiveForm.to_assigned_user"
          :searchable="true"
          class="multi-select card-details-value card-details-select"
          :options="userOptions"
          placeholder="Select To"
          :canDeselect="false"
        />
      </div>
      <div class="error">{{ errors?.calling_Excutive_Form }}</div>
      <div class="row my-2">
        <div class="col-12">
          <div class="form-check">
            <input
              v-model="callingExcutiveForm.transfer_live_leads"
              class="form-check-input"
              type="checkbox"
              value=""
              id="transfer"
              checked
            />
            <label class="form-check-label" for="transfer">
              Transfer live leads?
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-section">
      <button type="button" class="bttn btn btn-clr" @click="update()">
        <img src="../assets/save.svg" alt="" />Update
      </button>
      <button type="button" class="bttn btn clear-all">Clear All</button>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "assigncallingexecutive",
  components: {
    Multiselect,
  },
  data() {
    return {
      userOptions: [],
      errors: {},
      callingExcutiveForm: {
        from_assigned_user: "",
        to_assigned_user: "",
        transfer_live_leads: false,
      },
    };
  },
  methods: {
    handleInput(e) {
      let { name, value } = e.target;
      console.log(name, value);
      if (value == "") {
        this.errors[name] = `${name} should not be empty`;
      } else {
        this.errors[name] = "";
      }
      console.log(this.errors);
    },
    getAllData() {
      this.$axios
        .get("builders/leads/meta_data")
        .then((response) => {
          // this.statusOptions = response.data.status;
          this.userOptions = response.data.users;
          this.transfer = response.data.transfer_live_leads;
          // this.sourcesOptions = response.data.sources;
          // this.projectsOptions = response.data.projects;
          // this.brokersOptions = response.data.brokers;
          // this.executivesOptions=response.data.executives;
          // this.cpOptions=response.data.cp_status;
        })
        .catch((err) => {
          console.log(err);
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    update() {
      if (this.callingExcutiveForm.from_assigned_user == "") {
        this.errors["calling_Excutive_Form"] =
          "calling Excutive Form should not be empty";
      }
      if (this.callingExcutiveForm.from_assigned_user == "") {
        this.errors["calling_Excutive_Form"] =
          "calling Excutive Form should not be empty";
      }
      if (
        this.callingExcutiveForm.from_assigned_user == "" ||
        this.callingExcutiveForm.from_assigned_user == ""
      ) {
        return;
      }
      if (!this.callingExcutiveForm.transfer_live_leads) {
        delete this.callingExcutiveForm.transfer_live_leads;
      }
      this.$axios
        .patch(
          "builders/leads/bulk_action_for_assigned",
          this.callingExcutiveForm
        )
        .then((res) => {
          console.log(res, "Res");
          this.$swal({
            title: res.data.message,
            icon: "success",
          }).then(() => {
            this.$router.push({
              path: "/home",
            });
          });
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
  },
  mounted() {
    this.getAllData();
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style><style scoped></style><style scoped>
.error {
  font-size: 12px;
  color: red;
}

.main-container {
  height: 100vh;
}

.tele-calling-details-title {
  font-size: 16px;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: 0em;
  color: #000000;
}

.calling-excutive-main {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  padding: 0px 16px;
}

.cp-details {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  box-shadow: 0px 2px 4px 0px #0000000d;
  /* background-color: #fff; */
}

.img-assign {
  position: absolute;
  left: 16px;
}

.card-details-value {
  border-radius: 8px !important;
  /* opacity: 0.5; */
  padding: 12px 20px;
  color: #bfbfbf;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  border: 1px solid #b9b9b9;
  padding: 15px;
}

.card-details-select {
  padding: 5px 15px;
}

.checkbox-main {
  align-items: center;
  gap: 10px;
  flex: 1;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #888888;
}

.manual-assig {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #000000;
  text-align: center;
}

.footer {
  gap: 8px;
  padding: 10px 16px;
}

.submit-section {
  /* position: relative;
    width: 100%; */
  flex: 1;
}

.clear-all {
  background-color: #ffffff;
  width: 100%;
  color: #19a7ce;
  border: 1px solid #19a7ce;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}

.btn-clr {
  /* background-color: #19A7CE; */
  width: 100%;
  color: #ffffff;
  /* border: 1px solid #19a7ce; */
  background: #19a7ce;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
</style>
