<template>
  <div class="main-container">
    <div class="d-flex justify-content-center flex-column main-container">
      <div class="top-navbar">
        <h1>New Broker</h1>
        <a @click="$router.go(-1)" class="icon-left">
          <img src="../assets/close.svg" alt="" @click="toggleSideBar" />
        </a>
      </div>
      <div class="main-card page-content">
        <div class="mt-4 form-floating cp-form">
          <input
            type="text"
            v-model="user.broker.name"
            class="form-control"
            id="customerName"
            name="NewBroker"
            placeholder="Lead Name"
          />
          <label for="customerName " class="required">Broker Name</label>
          <div
            class="form-error-text"
            v-if="v$.user.broker.name.$invalid && isFormDirty"
          >
            This field is required
          </div>
        </div>
        <div class="form-floating cp-form">
          <input
            type="number"
            v-model="user.broker.phone"
            class="form-control"
            id="mobileNumber"
            name="Phone_Number"
            placeholder="Mobile Number"
          />
          <label for="mobileNumber">Mobile Number</label>
          <div
            class="form-error-text"
            v-if="v$.user.broker.phone.$invalid && isFormDirty"
          >
            This field is required
          </div>
        </div>
        <div class="form-floating cp-form">
          <input
            type="email"
            v-model="user.broker.email"
            class="form-control"
            name="email"
            id="email"
            placeholder="Email"
            autocomplete="off"
          />
          <label for="email">Email</label>
          <div
            class="form-error-text"
            v-if="v$.user.broker.email.$invalid && isFormDirty"
          >
            This field is required
          </div>
        </div>
        <div class="form-floating cp-form">
          <input
            type="text"
            v-model="user.password"
            class="form-control secure"
            id="password"
            placeholder="Email"
          />
          <label for="email">Enter password</label>
        </div>
        <div class="form-floating cp-form">
          <input
            type="text"
            v-model="user.password_confirmation"
            class="form-control secure"
            id="password"
            placeholder="Email"
          />
          <label for="email">Confirm password</label>
        </div>
        <div class="form-floating cp-form">
          <input
            type="email"
            v-model="user.broker.firm_name"
            class="form-control"
            name="firm_name"
            id="firm_name"
            placeholder="Email"
          />
          <label for="email">Firm Name<span class="required">*</span></label>
          <div
            class="form-error-text"
            v-if="v$.user.broker.firm_name.$invalid && isFormDirty"
          >
            This field is required
          </div>
        </div>
        <div>
          <Multiselect
            label="name"
            v-model="user.broker.rm_id"
            track-by="name"
            :searchable="true"
            :options="userOptions"
            class="multi-select card-details-value card-details-select"
            placeholder="RM"
            :canDeselect="false"
          />
        </div>
        <div class="form-floating cp-form">
          <input
            type="number"
            v-model="user.broker.alternative_phone"
            class="form-control"
            id="alternative_phone"
            placeholder=""
          />
          <label for="email">Alternate Phone</label>
        </div>
        <div class="form-floating cp-form">
          <input
            type="email"
            v-model="user.broker.sms_credit"
            class="form-control"
            id="sms_credit"
            placeholder=""
          />
          <label for="email">SMS Credits</label>
        </div>
        <div class="form-floating cp-form">
          <input
            type="email"
            v-model="user.broker.email_credit"
            class="form-control"
            id="email_credit"
            placeholder=""
          />
          <label for="email">Email Credit</label>
        </div>
        <div class="form-floating cp-form">
          <input
            type="email"
            v-model="user.broker.rera_no"
            class="form-control"
            name="rera"
            id="rera"
            placeholder=""
          />
          <label for="email">RERA id</label>
          <div
            class="form-error-text"
            v-if="v$.user.broker.rera_no.$invalid && isFormDirty"
          >
            This field is required
          </div>
        </div>
        <div>
          <Multiselect
            label="name"
            v-model="user.broker.locality_id"
            track-by="name"
            name="location"
            :searchable="true"
            :options="localitiesOptions"
            class="multi-select card-details-value card-details-select required"
            placeholder="Locality"
            :canDeselect="false"
          />
          <div
            class="form-error-text"
            v-if="v$.user.broker.locality_id.$invalid && isFormDirty"
          >
            This field is required
          </div>
        </div>
        <!-- <div class="error">{{ errors?.location }}</div> -->
        <div>
          <Multiselect
            label="name"
            v-model="user.broker.tech_understanding"
            track-by="name"
            :searchable="true"
            :options="techUnderstandingOptions"
            class="multi-select card-details-value card-details-select"
            placeholder="Tech Understanding"
            :canDeselect="false"
          />
        </div>
        <div>
          <Multiselect
            label="name"
            v-model="user.broker.infra_strength"
            track-by="name"
            :searchable="true"
            :options="infraStrengthOptions"
            class="multi-select card-details-value card-details-select"
            placeholder="Infra Strength"
            :canDeselect="false"
          />
        </div>
        <div>
          <Multiselect
            label="name"
            v-model="user.broker.market"
            track-by="name"
            :searchable="true"
            :options="marketOptions"
            class="multi-select card-details-value card-details-select"
            placeholder="Active Market"
            :canDeselect="false"
          />
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="user.broker.is_syndicate"
                type="checkbox"
                value=""
                id="default"
              />
              <label class="form-check-label" for="default">
                Is Syndicate?
              </label>
            </div>
          </div>
          <div class="col-6">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="user.broker.is_walkin_active"
                type="checkbox"
                value=""
                id="revisited"
              />
              <label class="form-check-label" for="revisited">
                Is Walk-in Active?
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="user.broker.is_booking_active"
                type="checkbox"
                value=""
                id="qualified"
              />
              <label class="form-check-label" for="qualified">
                Is Booking Active?
              </label>
            </div>
          </div>
        </div>
        <div class="form-floating mb-4 cp-form">
          <textarea
            rows="3"
            class="form-control"
            v-model="user.broker.comment"
            placeholder="Leave a comment here"
            id="comment"
          ></textarea>
          <label for="comment">Comments</label>
        </div>
      </div>
    </div>
    <div class="footer-section">
      <button type="button" class="bttn btn btn-clr" @click="addNewBroker()">
        <img src="../assets/save.svg" alt="" /> Save
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "newbroker",
  components: {
    Multiselect,
  },
  data() {
    return {
      v$: useVuelidate(),
      value: [],
      options: [
        {
          value: "1",
          label: "Batman",
        },
        {
          value: "2",
          label: "Robin",
        },
        {
          value: "3",
          label: "Joker",
        },
      ],
      user: {
        broker: {
          name: "",
          email: "",
          firm_name: "",
          rm_id: "",
          phone: "",
          comment: "",
          rera_no: "",
          email_credit: "",
          sms_credit: "",
          locality_id: "",
          tech_understanding: "",
          infra_strength: "",
          market: "",
          is_syndicate: "",
          is_walkin_active: "",
          is_booking_active: "",
          alternative_phone: "",
        },
        password: "",
        password_confirmation: "",
      },
      statusOptions: [],
      userOptions: [],
      sourcesOptions: [],
      projectsOptions: [],
      brokersOptions: [],
      localitiesOptions: [],
      infraStrengthOptions: [],
      techUnderstandingOptions: [],
      marketOptions: [],
      isFormDirty: false,
    };
  },
  validations() {
    return {
      user: {
        broker: {
          name: { required },
          phone: { required },
          email: { required },
          firm_name: { required },
          rera_no: { required },
          locality_id: { required },
        },
      },
    };
  },
  methods: {
    getLeads() {
      this.$axios
        .post("builders/leads", {
          builder_lead: this.leadForm,
        })
        .then((res) => {
          console.log(res, "Res");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllData() {
      this.$axios
        .get("builders/leads/meta_data")
        .then((response) => {
          this.statusOptions = response.data.status;
          this.userOptions = response.data.users;
          this.sourcesOptions = response.data.sources;
          this.projectsOptions = response.data.projects;
          this.brokersOptions = response.data.brokers;
          this.localitiesOptions = response.data.localities;
          this.techUnderstandingOptions = response.data.tech_understanding;
          this.infraStrengthOptions = response.data.infra_strength;
          this.marketOptions = response.data.market;
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    addNewBroker() {
      console.log(this.v$.$invalid, "testing");
      if (this.v$.$invalid) {
        this.isFormDirty = true;
        return;
      }
      // delete this.user.password;
      // delete this.user.password_confirmation;
      this.$axios
        .post("builders/brokers", {
          user: this.user,
        })
        .then((response) => {
          this.$swal({
            title: response.data.message,
            icon: "success",
          }).then(() => {
            this.$router.push({
              path: "/broker",
            });
          });
        })
        .catch((err) => {
          // console.log(err);
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
  },
  mounted() {
    // this.getLeads()
    this.getAllData();
  },
};
</script>

<style scoped>
.form-error-text {
  font-weight: 300;
  font-size: 13px;
  margin-top: 5px;
  color: red;
}

.main-container {
  background-color: #f1e9e9;
}

input.secure {
    text-security: disc;
    -webkit-text-security: disc;
}

.search-img {
  /* position: absolute;
        top: 4px;
        left: 274px; */
}

.search-img {
  position: absolute;
  top: 4px;
  left: 295px;
}

.cp-details {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  box-shadow: 0px 2px 4px 0px #0000000d;
  background-color: #fff;
}

.tele-calling-details-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  color: #000000;
}

.img {
  position: absolute;
  left: 16px;
}

.card-details-value {
  border-radius: 8px !important;
  /* opacity: 0.5;
        padding: 12px 20px; */
  color: #bfbfbf;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  border: 1px solid #b9b9b9;
  padding: 15px;
}

/* .btn-clr {
        background-color: #19A7CE;
        width: 100%;
        color: #19A7CE;
        border: 1px solid #19A7CE;
        border-radius: 34px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
    } */

.search-clr {
  width: 100%;
  background-color: #19a7ce;
  /* border: 1px solid #19A7CE; */
  color: #ffffff;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}

.submit-section {
  position: relative;
}

.main-card {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  background: #ffffff;
}

.btn-clr {
  /* background-color: #19A7CE; */
  width: 100%;
  color: #ffffff;
  /* border: 1px solid #19a7ce; */
  background: #19a7ce;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.card-details-select {
  padding: 5px 15px;
}

.user-input-wrp {
  position: relative;
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  width: 100%;
  background: white;
}

.user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  /* border-bottom: 1px solid #777; */
  box-shadow: none !important;
  padding-bottom: 3px;
  padding-left: 16px;
  border-radius: 20px;
}

.user-input-wrp .inputText:focus {
  border-color: blue;
  border-width: medium medium 2px;
}

.user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 18px;
  left: 10px;
  transition: 0.2s ease all;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #888888;
}

.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:not(:focus):valid ~ .floating-label {
  top: 6px;
  left: 16px;
  font-size: 13px;
  opacity: 1;
}
</style>
