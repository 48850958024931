import { createStore } from 'vuex'

export default createStore({
    state: {
      brokerData: [],
      loginData:[]
    },
    mutations: {
        setBrokerData(state, brokerData) {
            state.brokerData = brokerData
          },
          setLoginData(state, loginData) {
            state.loginData = loginData;
          }
    },
    getters: {
        brokerData(state) {
            return state.brokerData;
          },
          loginData(state) {
            return state.loginData;
          }
    },
    actions: {

      }
  });