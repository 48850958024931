<template>
  <div class="main-container">
    <div class="d-flex justify-content-center flex-column">
      <div class="cp-details">
        <h1 class="tele-calling-details-title">Lead Details</h1>
        <a @click="$router.go(-1)" class="back-arrow">
          <img class="img" src="../assets/back.svg" alt="" />
        </a>
      </div>
    </div>
    <div>
      <div class="label">
        <span class="label-cp">{{ selectedBuilderLeads.name }}</span>
        <span class="label-txt">{{ selectedBuilderLeads.lead_no }}</span>
      </div>
      <div class="cp-main-label py-4 px-3">
        <div class="cp-main">
          <div class="cp-label">
            <div class="cp-txt-up">Enquired for</div>
            <div class="cp-txt">{{ selectedBuilderLeads.project_name }}</div>
          </div>
          <div class="cp-label">
            <div class="cp-txt-up">Created at</div>
            <div class="cp-txt">{{ selectedBuilderLeads.created_at }}</div>
          </div>
        </div>
        <div class="cp-main">
          <div class="cp-label" v-if="canAccess">
            <div class="cp-txt-up">Mobile Number</div>
            <div class="cp-txt">{{ selectedBuilderLeads.phone }}</div>
          </div>
          <div class="cp-label">
            <div class="cp-txt-up">Email</div>
            <div class="cp-txt">{{ selectedBuilderLeads.email }}</div>
          </div>
        </div>
        <div class="cp-main">
          <div class="cp-label">
            <div class="cp-txt-up">CP</div>
            <div class="cp-txt">{{ selectedBuilderLeads.broker_status }}</div>
          </div>
          <div class="cp-label">
            <div class="cp-txt-up">Field Executive</div>
            <div class="cp-txt">{{ selectedBuilderLeads.field_executive }}</div>
          </div>
        </div>
        <div class="cp-main">
          <div class="cp-label">
            <div class="cp-txt-up">Last Followup</div>
            <div class="cp-txt">
              {{ selectedBuilderLeads.last_follow_up_at }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cp-container">
      <div class="cp-card">
        <div class="actions-main">
          <div @click="goToEdit(leads)">
            <img src="../assets/edit.svg" alt="" /> Edit
          </div>
          <div @click="goToHistory" v-if="callEnabled">
            <img src="../assets/time.svg" alt="" /> History
          </div>
          <div
            class="active"
            @click="openDialPad(selectedBuilderLeads.phone)"
            v-if="callEnabled"
          >
            <img src="../assets/call.svg" alt="" /> Call
          </div>
        </div>
        <div>
          <ul class="nav nav-tabs lead-detail-tab" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Broker
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Builder
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div class="cp-main-label p-4">
                <div class="cp-main">
                  <div class="cp-label">
                    <div class="cp-txt-up clr">Calling Executive</div>
                    <div class="cp-txt clr">
                      {{ selectedBuilderLeads.calling_executive }}
                    </div>
                  </div>
                  <div class="cp-label">
                    <div class="cp-txt-up clr">Field Executive</div>
                    <div class="cp-txt clr">
                      {{ selectedBuilderLeads.field_executive }}
                    </div>
                  </div>
                </div>
                <div class="cp-main">
                  <div class="cp-label">
                    <div class="cp-txt-up clr">Next Action Date</div>
                    <div class="cp-txt clr">{{ selectedBuilderLeads.ncd }}</div>
                  </div>
                  <div class="cp-label">
                    <div class="cp-txt-up clr">Updated At</div>
                    <div class="cp-txt clr">
                      {{ selectedBuilderLeads.updated_at }}
                    </div>
                  </div>
                </div>
                <div class="cp-main">
                  <div class="cp-label">
                    <div class="cp-txt-up clr">Visited?</div>
                    <div class="cp-txt clr">
                      {{ selectedBuilderLeads.broker_visited }}
                    </div>
                  </div>
                  <div
                    class="cp-label"
                    v-if="
                      selectedBuilderLeads && selectedBuilderLeads.booking_date
                    "
                  >
                    <div class="cp-txt-up clr">Builder Booking Date</div>
                    <div class="cp-txt clr">
                      {{ selectedBuilderLeads.booking_date }}
                    </div>
                  </div>
                </div>
                <div class="cp-main">
                  <div class="cp-label">
                    <div class="cp-txt-up clr">Comments</div>
                    <div
                      class="cp-txt clr"
                      v-html="selectedBuilderLeads.comment"
                    ></div>
                  </div>
                  <!-- <div class="cp-label">
                  <div>Field Executive</div>
                  <div>Sushant Gaikwad</div>
              </div> -->
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div class="cp-main-label p-4">
                <div class="cp-main">
                  <div class="cp-label">
                    <div class="cp-txt-up clr">Enquiry Status</div>
                    <div class="cp-txt clr">
                      {{ selectedBuilderLeads.status }}
                    </div>
                  </div>
                  <div class="cp-label">
                    <div class="cp-txt-up clr">Next Action Date</div>
                    <div class="cp-txt clr">{{ selectedBuilderLeads.ncd }}</div>
                  </div>
                </div>
                <div class="cp-main">
                  <div class="cp-label">
                    <div class="cp-txt-up clr">Updated At</div>
                    <div class="cp-txt clr">
                      {{ selectedBuilderLeads.updated_at }}
                    </div>
                  </div>
                  <div class="cp-label">
                    <div class="cp-txt-up clr">Visited?</div>
                    <div class="cp-txt clr">
                      {{ selectedBuilderLeads.builder_visited }}
                    </div>
                  </div>
                </div>
                <div class="cp-main">
                  <div class="cp-label">
                    <div class="cp-txt-up clr">Visit Details</div>
                    <div
                      class="cp-txt clr"
                      v-for="item in selectedBuilderLeads.builder_visit_details"
                      :key="item"
                    >
                      {{ item?.visit_date }}
                    </div>
                  </div>
                  <div class="cp-label">
                    <div class="cp-txt-up clr">Comments</div>
                    <div
                      class="cp-txt clr"
                      v-html="selectedBuilderLeads.comment"
                    ></div>
                  </div>
                  <!-- <div class="cp-label">
                                    <div class="cp-txt-up clr">Builder Booking Date</div>
                                    <div class="cp-txt clr">NA</div>
                                </div> -->
                </div>
                <!-- <div class="cp-main">
                                <div class="cp-label">
                                    <div class="cp-txt-up clr">Comments</div>
                                    <div class="cp-txt clr">
                                        {{ selectedBuilderLeads.comment }}
                                    </div>
                                </div> -->
                <!-- <div class="cp-label">
                  <div>Field Executive</div>
                  <div>Sushant Gaikwad</div>
              </div> -->
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" v-if="isSuperAdmin">
        <div @click="deleteLead()" class="delete">
          <img src="../assets/delete.svg" alt="" /> Delete Lead
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "leaddetail",
  data() {
    return {
      leadDetails: {},
      callEnabled: false,
      isExecutive: false,
      selectedLead: {},
      selectedBuilderLeads: {},
      canAccess: false,
      isSuperAdmin: false,
    };
  },
  methods: {
    openDialPad(phoneNo) {
      const phoneNumber = phoneNo;
      window.location.href = "tel:" + phoneNumber;
    },
    goToEdit() {
      this.$router.push({
        path: "/editenquiry",
      });
    },
    goToHistory() {
      this.$router.push({
        path: "/leadhistory",
      });
    },
    getLeadDetails() {
      // if(callType) {
      //     params[callType] = true;
      // }
      this.$axios
        .get(`builders/leads/${this.selectedLead.value}`)
        .then((response) => {
          this.leadDetails = response.data;
          this.selectedBuilderLeads = response.data.builders_lead;
          // this.incomingCalls = response.data.incoming_calls;
          // this.outGoingCalls = response.data.outgoing_calls;
          // callType ? this.buildersLeads = response.data[callType] : this.buildersLeads = response.data.builders_leads;
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    getAllData() {
      this.$axios
        .get("builders/leads/meta_data")
        .then((response) => {
          console.log(response.data, "status");
          this.isSuperAdmin = response.data.is_superadmin;
          this.canAccess = response.data.can_access_contact_number;
          this.isExecutive = response.data.is_executive;
          this.callEnabled = response.data.click_to_call_enabled; // this.statusOptions = response.data.status;
          // this.userOptions = response.data.users;
          // this.sourcesOptions = response.data.sources;
          // this.projectsOptions = response.data.projects;
          // this.brokersOptions = response.data.brokers;
          // this.executivesOptions = response.data.executives;
          // this.cpOptions = response.data.cp_status;
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    deleteLead() {
      this.$swal({
        title: "Are you sure you want to delete",
        icon: "info",
      }).then(() => {
        this.$axios
          .delete(`builders/leads/${this.selectedLead.value}`)
          .then(() => {
            this.$router.push({
              path: "/home",
            });
          });
      });
    },
  },
  mounted() {
    this.selectedLead = JSON.parse(sessionStorage.getItem("selectedDetails"));
    this.getLeadDetails();
    this.getAllData();
  },
};
</script>

<style scoped>
.lead-detail-tab li {
  flex: 1;
}

.lead-detail-tab li button {
  width: 100%;
  color: #b9b9b9;
  border: none;
}

.cp-container {
  padding: 15px;
}

.delete {
  color: #ee4b4b;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.35px;
  margin-top: 30px;
  margin-bottom: 30px;
}

/* .cp-detail {
    display: flex;
    justify-content: space-around;
    gap: 15px;
    margin-bottom: 15px;
} */

.cp-detail-new {
  display: flex;
  justify-content: space-around;
  gap: 17px;
}

.main-container {
  background-color: #f1e9e9;
  min-height: 100dvh;
  min-height: 100vh;
}

.cp-details {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px 0px #0000000d;
  background-color: #fff;
  position: relative;
}

.img {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.cp-detail-txt {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 2px 4px 0px #00000014;
}

.cp-txt {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #a77272;
}

.cp-txt-up {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #a77272;
  margin-bottom: 2px;
}

.clr {
  color: #888888;
}

.clr {
}

.cp-main {
  display: flex;
  flex-direction: row;
}

.cp-main-label {
  gap: 25px;
  display: flex;
  flex-direction: column;
  padding: 24px 0 24px 16px;
}

.cp-detail-plus {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 8px;
  flex: 1;
  box-shadow: 0px 2px 4px 0px #00000014;
}

.cp-detail-add {
  background: #ee964b;
  color: #ffffff;
}

.tele-calling-details-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.56px;
  color: #000000;
  margin-bottom: 0;
}

/* .search-box-main {
    margin-top: 15px;
    position: relative;
} */

.search-box-main input {
  box-shadow: 0px 1px 4px 0px #0000000d;
  background: #ffffff;
  border-radius: 40px;
  width: 100%;
  border: 0;
  padding: 7px 10px;
}

/* .search-img-sub {
    position: absolute;
    right: 15px;
    top: 5px;
} */

/* .search-img {
    /* display: flex;
    width: 100%;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    background-color: #fff; 
} */

/* .text-input {} */

/* .advance-search {
    position: absolute;
    right: 22px;
    top: 20px;
} */

/* .advance-search-main {
    position: relative;
    padding: 13px 0 12px 16px;
    border-top: 1px solid #A77272;
    border-bottom: 1px solid #A77272;
} */

/* .count {
    padding: 16px 0 0 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
} */
.label {
  display: flex;
  gap: 10px;
  padding: 16px 0 0 16px;
  align-items: center;
}

.label-cp {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.56px;
  color: #763a3a;
}

.label-txt {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0em;
  color: #a77272;
}

.actions-main {
  display: flex;
  gap: 15px;
  border-bottom: 1px dashed #b9b9b9;
  padding: 16px 16px 26px 16px;
  margin-bottom: 16px;
}

.cp-label {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.actions-main div {
  border: 1px solid #19a7ce;
  background: #fff;
  border-radius: 34px;
  padding: 12px 16px;
  flex: 1;
  text-align: center;
  color: #19a7ce;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.56px;
}

.actions-main .active {
  background: #19a7ce;
  color: #ffffff;
}

.cp-actions-main {
  padding: 15px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #19a7ce;
  font-weight: 600;
  letter-spacing: 0.56px;
  border: none;
  border-bottom: 3px solid #19a7ce;
}

.cp-actions-des {
  text-align: center;
  font-weight: 400;
  color: #000;
  line-height: 17.34px;
  font-size: 12px;
  padding-bottom: 15px;
}

.cp-actions-des span {
  font-weight: 600;
}

/* .cp-card-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
} */

.cp-card {
  box-shadow: 0px 1px 4px 0px #0000000d;
  background: #fff;
  border-radius: 16px;
}

/* .cp-card-new-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
} */

/* .cp-card-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: 400;
    color: #888888;
    padding: 16px;
    border-bottom: 1px dotted #B9B9B9;
} */

/* .label-des {
    font-weight: 600;
    font-size: 14px;
    line-height: 20.23px;
    color: #000000;
} */

/* .new-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #147773;
    background: #E3F8EC;
    padding: 5px;
    border-radius: 4px;
} */
</style>
