<template>
  <div>
    <div class="main">
      <div>Super Admin</div>
      <div><img src="../assets/menu-close.svg" alt=""></div>
    </div>
    <div class="main-txt">
      <div>Manage Leads</div>
      <div>Call Analysis</div>
      <div>View Events</div>
      <div>Manage Meetings</div>
    </div>
    <div class="logout"><img src="../assets/logout.svg" alt="">Logout</div>
  </div>
</template>

<script>
export default {
  neme: 'sidemenu',
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.main {
  display: flex;
  justify-content: space-around;
  background: #F1E9E9;
}

.main-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: #888888;
}

.logout {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: #EE4B4B;
}
</style>
