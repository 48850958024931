<template>
  <div class="main-container">
    <div class="top-navbar">
      <h1>Broker Details</h1>
      <a @click="$router.go(-1)" class="icon-left">
        <img src="../assets/close.svg" alt="" @click="toggleSideBar" />
      </a>
    </div>
    <div class="d-flex justify-content-center flex-column page-content">
      <div class="main-card-detail">
        <div class="name-label">
          <div class="enq-no">{{ user.broker.name }}</div>
          <router-link
            class="name ellipses underline m-0 edit"
            :to="{ name: 'EditBroker', params: { id: $route.params.id } }"
            ><img src="../assets/edit.svg" alt="" /> Edit</router-link
          >
        </div>
      </div>
      <div class="card-main">
        <div class="basic-info">Broker Details</div>
      </div>
      <div class="cp-main-label p-4">
        <div class="cp-main">
          <div class="cp-label">
            <div class="cp-txt-up">Broker Name:</div>
            <div class="cp-txt">{{ user.broker.name }}</div>
          </div>
          <div class="cp-label" v-if="canAccess">
            <div class="cp-txt-up">Contact:</div>
            <div class="cp-txt">{{ user.broker.phone }}</div>
          </div>
        </div>
        <div class="cp-main">
          <div class="cp-label">
            <div class="cp-txt-up">Email:</div>
            <div class="cp-txt">{{ user.broker.email }}</div>
          </div>
          <div class="cp-label">
            <div class="cp-txt-up">Firm Name:</div>
            <div class="cp-txt">{{ user.broker.firm_name }}</div>
          </div>
        </div>
        <div class="cp-main">
          <div class="cp-label">
            <div class="cp-txt-up">RERA:</div>
            <div class="cp-txt">{{ user.broker.rera_no }}</div>
          </div>
          <div class="cp-label">
            <div class="cp-txt-up">Primary Locality:</div>
            <div class="cp-txt">{{ user.broker.locality_name }}</div>
          </div>
        </div>
      </div>
      <div class="card-main">
        <div class="basic-info">Other Information</div>
      </div>
      <div class="cp-main-label p-4">
        <div class="cp-main">
          <div class="cp-label">
            <div class="cp-txt-up">Technology Understanding:</div>
            <div class="cp-txt">{{ user.broker.tech_understanding }}</div>
          </div>
          <div class="cp-label">
            <div class="cp-txt-up">Infrastructure Strength:</div>
            <div class="cp-txt">{{ user.broker.infra_strength }}</div>
          </div>
        </div>
      </div>
      <div class="cp-main-label p-4">
        <div class="cp-main">
          <div class="cp-label">
            <div class="cp-txt-up">Active Market:</div>
            <div class="cp-txt">{{ user.broker.market }}</div>
          </div>
          <div class="cp-label">
            <div class="cp-txt-up">Is Syndicate?</div>
            <div class="cp-txt">{{ user.broker.is_syndicate }}</div>
          </div>
        </div>
      </div>
      <!-- <div class="audio-main">
                <audio controls class="w-100">
                    <source type="audio/ogg" />
                    <source type="audio/mpeg" />
                    Your browser does not support the audio tag.
                </audio>
            </div> -->
      <!-- <div class="cp-main-label p-4">
                <div class="cp-main">
                    <div class="cp-label">
                        <div class="cp-txt-up">Uploaded Image</div>
                        <div class="d-flex">
                            <div class="cp-txt">Active Market:</div>
                            <div class="cp-txt">14-Dec-2023 10:37 AM</div>
                        </div>
                    </div>
                    <div class="view">
                        <img src="../assets/panorama.svg" alt="" /> View
                    </div>
                </div>
            </div> -->
      <!-- <div class="cp-main-label p-4">
                <div class="cp-main">
                    <div class="cp-label">
                        <div class="cp-txt-up">Comment</div>
                        <div class="d-inline-flex">
                            <div class="cp-txt">14-12-23 10:37 AM (Super Admin) :</div>
                            <div class="cp-txt">
                                in desktop test Start pause resume pause stop recording
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
      <div class="card-main">
        <div class="basic-info">CRM Details</div>
      </div>
      <div class="cp-main-label p-4">
        <div class="cp-main">
          <div class="cp-label">
            <div class="cp-txt-up">Comments:</div>
            <div class="cp-txt">{{ user.broker.comment }}</div>
          </div>
        </div>
      </div>
      <!-- <div class="card-main">
                <div class="basic-info">Campaigns</div>
            </div> -->
      <!-- <div class="cp-main-label p-4">
                <div class="cp-main">
                    <div class="cp-label">
                        <div class="cp-txt-up">No Campaigns Avaliable</div>
                        <div class="cp-txt">3/4, 8th Cross, 4th Main Rd, Jai Maruthi Nagar, Nandini Layout, Bengaluru, Karnataka 560096, India </div>
                    </div>
                </div>
            </div> -->
      <div class="delete" v-if="isSuperAdmin" @click="deleteBroker()">
        <img src="../assets/delete.svg" alt="" />&nbsp;Delete Broker
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "brokerdetails",
  data() {
    return {
      user: {
        broker: {
          name: "",
          email: "",
          firm_name: "",
          rm_id: "",
          phone: "",
          comment: "",
          rera_no: "",
          email_credit: "",
          sms_credit: "",
          locality_id: "",
          tech_understanding: "",
          infra_strength: "",
          market: "",
          is_syndicate: "",
          is_walkin_active: "",
          is_booking_active: "",
          alternative_phone: "",
        },
        password: "",
        password_confirmation: "",
      },
      isExecutive: false,
      isSuperAdmin: false,
      canAccess: false,
    };
  },
  methods: {
    goToEditMeeting() {
      this.$router.push({
        path: "/editmeeting",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    getAllData() {
      this.$axios
        .get("builders/leads/meta_data")
        .then((response) => {
          this.isSuperAdmin = response.data.is_superadmin;
          this.isExecutive = response.data.is_executive;
          this.canAccess = response.data.can_access_contact_number;
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    getBrokerPrefilledData() {
      this.$axios
        .get(`builders/brokers/${this.$route.params.id}`)
        .then((res) => {
          let broker = res.data.broker;
          this.user.broker.name = broker.name;
          this.user.broker.phone = broker.phone;
          this.user.broker.email = broker.email;
          this.user.broker.firm_name = broker.firm_name;
          this.user.broker.rm_id = broker.rm_id;
          this.user.broker.alternative_phone = broker.alternative_phone;
          this.user.broker.alternative_phone = broker.alternative_phone;
          this.user.broker.sms_credit = broker.sms_credit;
          this.user.broker.email_credit = broker.sms_credit;
          this.user.broker.rera_no = broker.rera_no;
          this.user.broker.locality_id = broker.locality_id;
          this.user.broker.locality_name = broker.locality_name;
          this.user.broker.tech_understanding = broker.tech_understanding;
          this.user.broker.infra_strength = broker.infra_strength;
          this.user.broker.market = broker.market;
          this.user.broker.is_syndicate = broker.is_syndicate;
          this.user.broker.is_walkin_active = broker.is_walkin_active;
          this.user.broker.is_booking_active = broker.is_booking_active;
          this.user.broker.comment = broker.comment;
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    deleteBroker() {
      let deleteConfirm = confirm("Are you sure you want to Delete?");
      if (deleteConfirm === false) {
        return;
      }
      this.$axios
        .delete(`builders/brokers/${this.$route.params.id}`)
        .then((response) => {
          console.log(response.data, "status");
          this.$router.push({
            path: "/broker",
          });
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
  },
  mounted() {
    this.getBrokerPrefilledData();
    this.getAllData();
  },
};
</script>

<style scoped>
.tele-calling-details-title {
  font-size: 16px;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: 0em;
  color: #000000;
}

.cp-details {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  box-shadow: 0px 2px 4px 0px #0000000d;
  background-color: #fff;
}

.delete {
  color: #ee4b4b;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px 20px 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.56px;
}

.main-card {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0px 0px;
  background: #ffffff;
}

.img-detail {
  position: absolute;
  left: 16px;
}

.main-card-detail {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0px 0px;
  background: #ffffff;
}

.name-label {
  padding: 15px;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view {
  padding: 10px 15px;
  border: 1px solid #19a7ce;
  border-radius: 34px;
  color: #19a7ce;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.56px;
}

.enq-no {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  color: #763a3a;
}

.card-main {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.basic-info {
  background: #19a7ce;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  padding: 6px 0 6px 16px;
  color: #ffffff;
}

.cp-main-label {
  gap: 25px;
  display: flex;
  flex-direction: column;
  padding: 24px 0 24px 16px;
}

.cp-main {
  display: flex;
  flex-direction: row;
  gap: 31px;
}

.other-info {
  display: flex;
  flex-direction: row;
  gap: 117px;
}

.cp-txt {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #a77272;
  word-break: break-all;
}

.cp-txt-up {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #a77272;
}

.cp-label {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 50%;
}

.audio-main {
  padding: 15px;
}

.edit {
  border: 1px solid #19a7ce;
  background: #fff;
  border-radius: 34px;
  padding: 8px 16px;
  /* flex: 1; */
  text-align: center;
  color: #19a7ce;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.56px;
}
</style>
