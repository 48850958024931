<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main-container">
    <div class="d-flex justify-content-center flex-column">
      <div class="top-navbar">
        <a @click="goTOHome()" class="icon-left">
          <img src="../assets/home.svg" alt="" />
        </a>
        <h1>Brokers</h1>
        <a class="icon-right">
          <img src="../assets/menu-open.svg" alt="" @click="toggleSideBar" />
        </a>
      </div>
    </div>
    <div class="page-content" v-if="!isAdvancedSearch">
      <div class="cp-container">
        <div class="cp-detail-new">
          <div class="cp-detail-plus cp-detail-add" @click="goToNewBroker()">
            <span class="mb-2"><img src="../assets/groups.svg" alt="" /></span>
            <span class="new-meet">Add New Broker</span>
          </div>
        </div>
        <div class="cp-brok">Brokers</div>
        <div class="search-box-main">
          <img src="../assets/search.svg" alt="" class="search-img-sub" />
          <input
            type="text"
            v-model="search"
            @input="searchFilter()"
            placeholder="Search Broker name, email or phone "
            class="text-input"
          />
          <!-- <div @click="openAdvancedFilter()"><img src="" alt=""></div> -->
        </div>
      </div>
      <div class="advance-search-main px-3" @click="goToAdvanceSearch()">
        <span class="me-2 d-inline-block"
          ><img src="../assets/advance-search.svg" alt=""
        /></span>
        <span>ADVANCE SEARCH</span>
        <div class="opacity-50">Refine your Search Results!</div>
        <div>
          <img class="advance-search" src="../assets/arrow.svg" alt="" />
        </div>
      </div>
      <div class="cp-container" v-if="brockerLeads">
        <div class="count">
          Broker Count - <span class="text-bold">{{ brockerLeads.count }}</span>
        </div>
        <div class="cp-card-container">
          <div
            class="cp-card"
            v-for="broker in brockerLeads.brokers"
            :key="broker"
          >
            <div class="cp-card-content">
              <div class="cp-card-new-label">
                <div class="d-flex w-100 justify-content-between">
                  <!-- <router-link class="name ellipses underline m-0" :to="{ name: 'BrokerDetails', params: {  id: broker.value }}"> -->
                  <span class="head-name">{{ broker.name }}</span>

                  <router-link
                    class="name ellipses underline m-0"
                    :to="{
                      name: 'BrokerDetails',
                      params: { id: broker.value },
                    }"
                  >
                    <div>
                      <img src="../assets/dots.svg" alt="" />
                    </div>
                  </router-link>
                </div>
                <div class="cp-calling-exe">
                  Firm Name:
                  <span class="sub-text">{{ broker.firm_name }}</span>
                </div>
                <div class="cp-calling-exe" v-if="canCall">
                  Contact: <span class="sub-text">{{ broker.phone }}</span>
                </div>
                <div class="cp-calling-exe">
                  RM: <span class="sub-text">{{ broker.rm_name }}</span>
                </div>
                <!-- <div><input type="checkbox"></div> -->
              </div>

              <!-- <div class="cp-project-name">  </div> -->
            </div>
            <div class="d-flex align-items-center">
              <!-- <div class="broker"><img src="../assets/add.svg" alt=""> Prospect</div> -->
              <div class="broker">
                <router-link
                  class="name ellipses underline m-0"
                  :to="{ name: 'EditBroker', params: { id: broker.value } }"
                >
                  <img src="../assets/edit.svg" alt="" /> Edit
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination-indicator">
      <span class="result-Text"
        >Showing {{ pageStart }} - {{ pageEnd }} out of {{ total }}</span
      >
    </div>
    <div class="pagination-holder mt-4">
      <paginate
        v-model="page"
        :page-count="totalPages"
        :page-range="3"
        :margin-pages="2"
        :click-handler="goToPage"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :page-link-class="'page-link'"
        :prev-link-class="'page-link'"
        :next-link-class="'page-link'"
        :disabled-class="'page-item disabled'"
        :container-class="'pagination justify-content-center'"
        :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
  <!-- <div class="footer-section">
        <button type="button" class="assign-to" @click="goToassigTO()">Assign To</button>
    </div> -->
  <!-- Assign To starts -->
  <div class="assign-to-form" v-if="assignTO">
    <div class="form-main assign-to-card">
      <div class="card-title">Assign To</div>
      <div>
        <Multiselect
          label="name"
          track-by="name"
          v-model="broker.rm_id_in"
          :searchable="true"
          class="multi-select card-details-value card-details-select"
          placeholder="Select RM"
          :options="rmsOptions"
          :canDeselect="false"
        />
      </div>
      <!-- <div>
                <Multiselect label="name" track-by="name" :searchable="true" class="multi-select card-details-value card-details-select" placeholder="Field Executive" :options="sourcesOptions" :canDeselect="false" />
            </div> -->
      <!-- <div>
                <Multiselect label="name" track-by="name"  :searchable="true" class="multi-select card-details-value card-details-select" placeholder="Status" :options="sourcesOptions" :canDeselect="false" />
            </div> -->
      <!-- <div class="date-picker-input">
                <VueDatePicker class="" id="datepicker" placeholder="NCD" v-model="date"></VueDatePicker>
            </div> -->
      <div class="footer-section position-relative shadow-none pb-0 px-0">
        <div class="submit-text m-0">Submit</div>
        <div class="cancle-text m-0" @click="$router.go(-1)">Cancel</div>
      </div>
    </div>
  </div>
  <!-- Assign To ends -->
  <!-- side bar starts -->
  <div class="side-bar" v-if="isSideBarOpen">
    <div class="side-bar-container">
      <div class="d-flex justify-content-center flex-column main-container">
        <div class="cp-details clr justify-content-between">
          <h1 class="tele-calling-details-title">{{ userRole }}</h1>
          <!-- <h1 class="tele-calling-details-title">Super Admin</h1> -->
          <img
            class="img-advanc"
            src="../assets/menu-close.svg"
            alt=""
            @click="toggleSideBar"
          />
        </div>
        <div class="sidebar-txt">
          <div class="p-3 active" @click="goTOHome">Manage Leads</div>
          <div class="p-3" @click="goToCallAnalysis()">Call Analysis</div>
          <div @click="goToBrokers()" class="p-3">Brokers</div>
          <div class="p-3" @click="goToProjects()">Projects</div>
          <div class="p-3" @click="goToManageMeeting()">Manage Meetings</div>
        </div>
        <!-- <div class="d-flex justify-content-center flex-column main-container"> -->
        <!-- <div class="cp-details clr justify-content-between">
          <h1 class="tele-calling-details-title">{{ loginData.name }}</h1>
          <img class="img-advanc" src="../assets/menu-close.svg" alt="" @click="toggleSideBar">
        </div> -->
        <!-- <div class="logout"><img src="../assets/logout.svg" alt="" @click="getlogout">
            Logout
        </div> -->
      </div>
      <div class="logout">
        <img src="../assets/logout.svg" alt="" @click="getlogout" />
        Logout
      </div>
    </div>
  </div>
  <div class="loader-wrapper" v-if="isLoading">
    <div class="loader">Loading...</div>
  </div>
  <!-- advanced Search  -->
  <div class="adv-main-container" v-if="isAdvancedSearch">
    <div class="d-flex justify-content-center flex-column">
      <div class="top-navbar">
        <h1 class="">Advance Search</h1>
        <!-- <a @click="$router.go(-1)" class="back-arrow"> -->
        <img
          class="icon-left"
          src="../assets/close.svg"
          alt=""
          @click="closeAdvSearch"
        />
        <!-- </a> -->
      </div>
      <div class="form-main page-content">
        <div class="mt-4 form-floating cp-form">
          <input
            type="text"
            v-model="brokerAdvanceSearch.name_cont"
            class="form-control"
            id="leadName"
            placeholder="Lead Name"
          />
          <label for="leadName">Broker Name</label>
        </div>
        <div class="form-floating cp-form">
          <input
            type="text"
            v-model="brokerAdvanceSearch.firm_name_cont"
            class="form-control"
            id="leadName"
            placeholder="Lead Name"
          />
          <label for="leadName"> Firm Name</label>
        </div>
        <div class="form-floating cp-form">
          <input
            type="number"
            v-model="brokerAdvanceSearch.phone_eq"
            class="form-control"
            id="mobileNumber"
            placeholder="Mobile Number"
          />
          <label for="mobileNumber">Mobile Number</label>
        </div>
        <div class="form-floating cp-form">
          <input
            type="email"
            v-model="brokerAdvanceSearch.email_eq"
            class="form-control"
            id="email"
            placeholder="Email"
          />
          <label for="email">Email</label>
        </div>
        <div class="form-floating cp-form">
          <input
            type="email"
            v-model="brokerAdvanceSearch.rera_no_eq"
            class="form-control"
            id="email"
            placeholder="Email"
          />
          <label for="email">RERA id</label>
        </div>
        <!-- <div class="form-floating cp-form">
                    <input type="email" v-model="" class="form-control" id="email" placeholder="Email" />
                    <label for="email">Select RM</label>
                </div> -->
        <div>
          <Multiselect
            label="name"
            track-by="name"
            v-model="brokerAdvanceSearch.rm_id_in"
            :searchable="true"
            class="multi-select card-details-value card-details-select"
            :options="rmsOptions"
            mode="multiple"
            placeholder="Select RM"
            :canDeselect="false"
          />
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                v-model="brokerAdvanceSearch.is_walkin_active_eq"
                id="default"
              />
              <label class="form-check-label is-walk" for="default">
                Is Walk-in Active?
              </label>
            </div>
          </div>
          <div class="col-6">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="brokerAdvanceSearch.is_booking_active_eq"
                value=""
                id="default"
              />
              <label class="form-check-label" for="default">
                Is Booking Active?
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button type="button" class="bttn btn btn-clr" @click="clearAll()">
              Clear All
            </button>
          </div>
        </div>
        <div class="footer-section">
          <button
            type="button"
            class="bttn btn search-clr"
            @click="advanceSearch()"
          >
            <img src="../assets/search-white.svg" alt="" /> Search
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "@vueform/multiselect";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "broker",
  components: {
    Multiselect,
  },
  data() {
    return {
      isLoading: true,
      total: 0,
      perPage: 0,
      userRole: localStorage.getItem("tele-calling-name"),
      isSideBarOpen: false,
      brockerLeads: [],
      canCall: false,
      brokerAdvanceSearch: {
        name_cont: "",
        firm_name_cont: "",
        email_eq: "",
        phone_eq: "",
        rera_no_eq: "",
        rm_id_in: [],
        is_walkin_active_eq: "",
        is_booking_active_eq: "",
      },
      statusOptions: [],
      userOptions: [],
      sourcesOptions: [],
      projectsOptions: [],
      brokersOptions: [],
      executivesOptions: [],
      cpOptions: [],
      isAdvancedSearch: false,
      isFromAdvSearch: false,
      meetingLead: [],
      rmsOptions: [],
      date: null,
      assignTO: false,
    };
    //   statusOptions: [],
    //   userOptions: [],
    //   sourcesOptions: [],
    //   projectsOptions: [],
    //   brokersOptions: [],
    //   executivesOptions: [],
    //   cpOptions: [],
    //   isAdvancedSearch: false,
    //   meetingLead: [],
    //   rmsOptions: [],
    //   date: null,
    //   assignTO: false,
    // }
  },
  // computed: {

  // },
  // methods: {
  //   searchFilter() {
  //     this.$axios
  //       .get("builders/brokers", {
  //         params: {
  //           bs: this.search
  //         }
  //       })
  //       .then((response) => {
  //         this.brockerLeads = response.data;
  //         // this.leadData = response.data;
  //         // callType
  //         //     ?
  //         //     (this.buildersLeads = response.data[callType]) :
  //         //     (this.buildersLeads = response.data.builders_leads);

  //       }).catch((err) => {
  //               if (err.response.status === 401 || err.response.status === 404) {
  //                   localStorage.removeItem("cp-mandate-token");
  //                   this.$router.replace("/");
  //               }
  //           });
  //   },
  //   goToassigTO() {
  //     this.assignTO = true;
  //   },
  //   closeAssiTo() {
  //     this.assignTO = false;
  //   },
  //   goToProjects() {
  //     this.$router.push({
  //       path: "/projects",
  //       query: {
  //         uuid: this.$route.query.uuid
  //       }
  //   },
  computed: {
    ...mapGetters(["loginData"]),
    totalPages() {
      if (this.total === 0 || this.perPage === 0) {
        return 0;
      } else {
        return Math.ceil(this.total / this.perPage);
      }
    },
    page: {
      get() {
        let page = this.$route.query.page || 1;
        return parseInt(page);
      },
      set() {
        true;
      },
    },
    pageStart() {
      if (this.total === 0) {
        return 0;
      }
      return Math.max(0, (this.page - 1) * this.perPage + 1);
    },
    pageEnd() {
      if (this.total === 0) {
        return 0;
      }
      return Math.min(this.total, this.page * this.perPage);
    },
  },
  methods: {
    goToPage(page) {
      let newQ = {
        ...this.$route.query,
        page,
      };
      this.$router.replace({
        query: newQ,
      });
      this.isFromAdvSearch ? this.advanceSearch(newQ) : this.getBrokers(newQ);
    },
    searchFilter() {
      this.$axios
        .get("builders/brokers", {
          params: {
            bs: this.search,
          },
        })
        .then((response) => {
          this.brockerLeads = response.data;
          // this.perPage = response.data.per_page;
          // this.total = response.data.count;
          // this.leadData = response.data;
          // callType
          //     ?
          //     (this.buildersLeads = response.data[callType]) :
          //     (this.buildersLeads = response.data.builders_leads);
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    goToassigTO() {
      this.assignTO = true;
    },
    closeAssiTo() {
      this.assignTO = false;
    },
    goToProjects() {
      this.$router.push({
        path: "/projects",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    clearAll() {
      this.brokerAdvanceSearch = {
        name_cont: "",
        firm_name_cont: "",
        email_eq: "",
        phone_eq: "",
        rera_no_eq: "",
        rm_id_in: [],
        is_walkin_active_eq: "",
        is_booking_active_eq: "",
      };
    },
    goToManageMeeting() {
      this.$router.push({
        path: "/managemeetings",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToCallAnalysis() {
      this.$router.push({
        path: "/callanalysis",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goTOHome() {
      this.$router.push({
        path: "/home",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToBrokers() {
      this.$router.push({
        path: "/broker",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToNewBroker() {
      this.$router.push({
        path: "/newbroker",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    openDialPad(phoneNo) {
      const phoneNumber = phoneNo;
      window.location.href = "tel:" + phoneNumber;
    },
    toggleSideBar() {
      this.isSideBarOpen = !this.isSideBarOpen;
    },
    getlogout() {
      this.$axios
        .delete("logout")
        .then(() => {
          localStorage.removeItem("cp-mandate-token");
          this.$router.replace("/");
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    // getlogout() {
    //     this.$router.push({
    //         path: "/",
    //         query: {
    //             uuid: this.$route.query.uuid
    //         }
    //     });
    // },
    goToHistory(selectedDetails) {
      sessionStorage.setItem(
        "selectedDetails",
        JSON.stringify(selectedDetails)
      );
      this.$router.push({
        path: "/leadhistory",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToMeetingDetail() {
      this.$router.push({
        path: "/meetingdetails",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToNewMeeting() {
      this.$router.push({
        path: "/newmeeting1",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToHome() {
      this.$router.push({
        path: "/home",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToDetail(leads) {
      sessionStorage.setItem("selectedDetails", JSON.stringify(leads));
      this.$router.push({
        path: "/leaddetail",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToAdvanceSearch() {
      // this.$router.push({
      //     path: "/advancesearch",
      //     query: {
      //         uuid: this.$route.query.uuid
      //     }
      // });
      this.isAdvancedSearch = true;
    },
    closeAdvSearch() {
      this.isAdvancedSearch = false;
    },
    goToNewEnquary() {
      this.$router.push({
        path: "/newenquiry",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToEditBroker(broker) {
      this.$router.push({
        to: {
          name: "EditBroker",
          params: {
            id: broker.value,
          },
        },
        // path: `/editbroker?${broker.value}`,
      });
    },
    getBuilderLeads(newQ) {
      this.isFromAdvSearch = false;
      const params = {
        page: 1,
      };
      params["page"] = newQ?.page;
      this.$axios
        .get("builders/leads", {
          params,
        })
        .then((response) => {
          this.leadData = response.data;

          // callType ? this.buildersLeads = response.data[callType] : this.buildersLeads = response.data.builders_leads;
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    advanceSearch(newQ) {
      this.isFromAdvSearch = true;
      let pageNo = 1;
      pageNo = newQ?.page;
      this.brokerAdvanceSearch["page"] = 1;
      this.$axios
        .post("builders/brokers/search", {
          q: this.brokerAdvanceSearch,
          page: pageNo,
        })
        .then((res) => {
          this.leadData = res.data;
          this.brockerLeads = res.data;
          this.perPage = res.data.per_page;
          this.total = res.data.count;
          this.isAdvancedSearch = false;
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    getAllData() {
      this.$axios
        .get("builders/leads/meta_data")
        .then((response) => {
          this.statusOptions = response.data.status;
          this.userOptions = response.data.users;
          this.sourcesOptions = response.data.sources;
          this.projectsOptions = response.data.projects;
          this.brokersOptions = response.data.brokers;
          this.executivesOptions = response.data.executives;
          this.cpOptions = response.data.cp_status;
          this.rmsOptions = response.data.rms;
          this.canCall = response.data.can_access_contact_number;
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    getBrokers(newQ) {
      const params = {
        page: 1,
      };
      params["page"] = newQ?.page;
      this.$axios
        .get("builders/brokers", {
          params,
        })
        .then((response) => {
          this.isLoading = false;
          this.brockerLeads = response.data;
          this.perPage = response.data.per_page;
          this.total = response.data.count;
          window.scrollTo(0, 0);
          // this.statusOptions = response.data.status;
          // this.userOptions = response.data.users;
          // this.sourcesOptions = response.data.sources;
          // this.projectsOptions = response.data.projects;
          // this.brokersOptions = response.data.brokers;
          // this.executivesOptions = response.data.executives;
          // this.cpOptions = response.data.cp_status;
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
  },
  mounted() {
    this.getBuilderLeads(null);
    this.advanceSearch();
    this.getAllData();
    this.getBrokers(null);
  },
};
</script>

<style scoped>
.submit-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #ffffff;
  background: #19a7ce;
  flex: 1;
  border-radius: 34px;
  padding: 12px;
  margin: 16px;
  display: flex;
  justify-content: center;
}

.cancle-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #19a7ce;
  border: 1px solid #19a7ce;
  border-radius: 34px;
  flex: 1;
  padding: 12px;
  margin: 16px;
  display: flex;
  justify-content: center;
}

.assign-to-form {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  height: 100dvh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}

.form-main {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}

.text-bold {
  font-weight: 600;
}

.assign-to {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  background: #19a7ce;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 34px;
  padding: 12px;
  border: none;
  outline: none;
}

.card-title {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.56px;
}

.is-walk {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #000000;
}

.cp-brok {
  font-size: 18px;
  font-weight: 800;
  line-height: 26px;
  letter-spacing: 0em;
  color: #763a3a;
}

.broker {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #19a7ce;
  flex: 1;
  border: 1px solid #19a7ce;
  border-radius: 34px;
  padding: 12px;
  margin: 16px;
  display: flex;
  justify-content: center;
  gap: 3px;
}

.broker a {
  text-decoration: none;
  color: #19a7ce !important;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 6px;
}

.sub-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #888888;
}

.cp-calling-exe {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #888888;
}

.head-name {
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0em;
  color: #000000;
}

/* side bar starts */
.side-bar {
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  height: 100vh;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.side-bar-container {
  width: 80%;
  background: #fff;
  position: relative;
  left: 20%;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.audio-main {
  padding: 15px;
}

.logout {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.12px;
  color: #ee4b4b;
  padding-bottom: 20px;
}

.sidebar-txt {
  display: flex;
  color: #888888;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  background: white;
  padding: 0 10px;
}

.sidebar-txt div {
  color: #888;
  font-size: 16px;
  border-bottom: 1px solid #f1e9e9;
  letter-spacing: 0.5px;
}

.sidebar-txt .active {
  color: #19a7ce;
  font-weight: 600;
}

/* sidebar ends */

.cp-container {
  padding: 15px;
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
}

.cp-detail {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  margin-bottom: 15px;
}

.cp-detail-new {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  margin-bottom: 32px;
  width: 50%;
}

.text-bold {
  font-weight: 600;
}

.inc-meet {
  font-size: 20px;
  font-weight: 800;
  line-height: 29px;
  letter-spacing: 0em;
  color: #000000;
}

.new-meet {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}

.main-container {
  background-color: #f6f1f1;
}

.cp-details {
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px 0px #0000000d;
  background-color: #fff;
}

.clr {
  background: #f1e9e9 !important;
}

/* .clor{
            background-color: #fff !important;
        } */

.img {
  position: absolute;
  left: 16px;
}

.cp-detail-txt {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 2px 4px 0px #00000014;
  justify-content: space-between;
}

.cp-detail-plus {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 8px;
  flex: 1;
  justify-content: space-between;
  box-shadow: 0px 2px 4px 0px #00000014;
}

.cp-detail-add {
  background: #ee964b;
  color: #ffffff;
}

.tele-calling-details-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  color: #000000;
  margin-bottom: 0;
  letter-spacing: 0.35px;
}

.search-box-main {
  margin-top: 15px;
  position: relative;
}

.search-box-main input {
  box-shadow: 0px 1px 4px 0px #0000000d;
  background: #ffffff;
  border-radius: 40px;
  width: 100%;
  border: 0;
  padding: 14px 16px;
  border: 1px solid transparent;
}

.search-box-main input:focus {
  outline: none;
  border: 1px solid #19a7ce;
}

.search-img-sub {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.search-img {
  /* display: flex;
            width: 100%;
            border: 1px solid #dcdcdc;
            border-radius: 5px;
            background-color: #fff; */
}

.text-input {
}

.advance-search {
  position: absolute;
  right: 22px;
  top: 20px;
}

.advance-search-main {
  position: relative;
  padding: 13px 0 12px 16px;
  border-top: 1px solid #a77272;
  border-bottom: 1px solid #a77272;
  letter-spacing: 0.56px;
  color: #763a3a;
  background: #f1e9e9;
}

.count {
  padding-top: 16px;
  padding-left: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #a77272;
  margin-bottom: 12px;
}

.actions-main {
  display: flex;
  gap: 15px;
}

.actions-main div {
  border: 1px solid #19a7ce;
  background: #fff;
  border-radius: 34px;
  padding: 5px 16px;
  flex: 1;
  text-align: center;
  color: #19a7ce;
  display: flex;
  gap: 5px;
  align-items: center;
}

.actions-main .active {
  background: #19a7ce;
  color: #ffffff;
}

.cp-actions-main {
  padding: 15px;
}

.cp-actions-des {
  text-align: center;
  font-weight: 400;
  color: #000;
  line-height: 17.34px;
  font-size: 12px;
  padding-bottom: 15px;
}

.cp-actions-des span {
  font-weight: 600;
}

.cp-card-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cp-card {
  box-shadow: 0px 1px 4px 0px #0000000d;
  background: #fff;
  border-radius: 16px;
}

.cp-card-new-label {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  flex-direction: column;
}

.cp-card-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 400;
  color: #888888;
  padding: 16px;
  border-bottom: 1px dashed #b9b9b9;
}

.label-des {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  display: inline-block;
  margin-right: 6px;
}

.new-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  background: #e3f8ec;
  padding: 1px 4px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  color: #ee964b;
}

/* advance search  */
.adv-main-container {
  background-color: #f1e9e9;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
}

.checkbox-main {
  align-items: center;
  gap: 10px;
  flex: 1;
  margin-top: 10px;
}

.form-main {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  background: #ffffff;
}

.img-advanc {
  position: absolute;
  right: 16px;
}

.card-details-value {
  border-radius: 8px !important;
  /* opacity: 0.5; */
  padding: 12px 20px;
  color: #bfbfbf;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  border: 1px solid #b9b9b9;
  padding: 15px;
}

.card-details-select {
  padding: 5px 15px;
}

.btn-clr {
  /* background-color: #19A7CE; */
  width: 100%;
  color: #19a7ce;
  border: 1px solid #19a7ce;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  padding: 12px;
  margin-bottom: 40px;
}

.search-clr {
  width: 100%;
  background-color: #19a7ce;
  /* border: 1px solid #19A7CE; */
  color: #ffffff;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.submit-section {
  position: relative;
}

.user-input-wrp {
  position: relative;
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  width: 100%;
  background: white;
}

.user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  /* border-bottom: 1px solid #777; */
  box-shadow: none !important;
  padding-bottom: 3px;
  padding-left: 16px;
}

.user-input-wrp .inputText:focus {
  border-color: blue;
  border-width: medium medium 2px;
}

.user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 18px;
  left: 10px;
  transition: 0.2s ease all;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #888888;
}

.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:not(:focus):valid ~ .floating-label {
  top: 6px;
  left: 16px;
  font-size: 13px;
  opacity: 1;
}
</style>
