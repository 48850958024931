<template>
  <div class="main-container">
    <div class="top-navbar">
      <h1>Meeting Details</h1>
      <a @click="$router.go(-1)" class="icon-left">
        <img src="../assets/close.svg" alt="" @click="toggleSideBar" />
      </a>
    </div>
    <div class="d-flex justify-content-center flex-column page-content">
      <div class="main-card-detail">
        <div class="name-label">
          <div class="enq-no">{{ meetingInfo.executive_name }}</div>
          <div @click="goToEditMeeting(meetingInfo)" class="edit">
            <img src="../assets/edit.svg" alt="" /> Edit
          </div>
        </div>
      </div>
      <div class="card-main">
        <div class="basic-info">Meeting Details</div>
      </div>
      <div class="cp-main-label p-4" v-if="meetingInfo">
        <div class="cp-main">
          <div class="cp-label" v-if="meetingInfo && meetingInfo.broker_name">
            <div class="cp-txt-up">Broker Name:</div>
            <div class="cp-txt">{{ meetingInfo.broker_name }}</div>
          </div>
          <div
            class="cp-label"
            v-if="meetingInfo && meetingInfo.broker_firm_name"
          >
            <div class="cp-txt-up">Firm Name:</div>
            <div class="cp-txt">{{ meetingInfo.broker_firm_name }}</div>
          </div>
        </div>
        <div class="cp-main" v-if="meetingInfo">
          <div class="cp-label" v-if="meetingInfo && meetingInfo.start_time">
            <div class="cp-txt-up">Start Time:</div>
            <div class="cp-txt">{{ meetingInfo.start_time }}</div>
          </div>
          <div class="cp-label" v-if="meetingInfo && meetingInfo.end_time">
            <div class="cp-txt-up">End Time:</div>
            <div class="cp-txt">{{ meetingInfo.end_time }}</div>
          </div>
        </div>
      </div>
      <div class="card-main">
        <div class="basic-info">Other Info</div>
      </div>
      <div class="cp-main-label p-4">
        <div class="cp-main">
          <div class="cp-label" v-if="meetingInfo && meetingInfo.rating">
            <div class="cp-txt-up">Rating</div>
            <div class="cp-txt">{{ meetingInfo.rating }} out of 5</div>
          </div>
          <div class="cp-label" v-if="meetingInfo && meetingInfo.revisited">
            <div class="cp-txt-up">Revisited</div>
            <div class="cp-txt">No</div>
          </div>
        </div>
      </div>
      <div class="audio-main" v-if="meetingInfo && meetingInfo.recording">
        <audio controls class="w-100">
          <source :src="meetingInfo.recording" type="audio/ogg" />
          <source :src="meetingInfo.recording" type="audio/mpeg" />
          Your browser does not support the audio tag.
        </audio>
      </div>
      <!-- <div class="cp-main-label p-4">
                <div class="cp-main">
                    <div class="cp-label" v-if="meetingInfo && meetingInfo.image_uploaded_at">
                        <div class="cp-txt-up">Uploaded Image</div>
                        <div class="d-flex">
                            <div class="cp-txt">Uploaded At:</div>
                            <div class="cp-txt">{{ meetingInfo.image_uploaded_at}}</div>
                        </div>
                    </div>
                    <div class="view"  @click="showImage">
                        <img src="../assets/panorama.svg" alt=""> View
                    </div>
                </div>
            </div> -->
      <!-- <div class="cp-main-label p-4" v-if="meetingInfo && meetingInfo.comment">
                <div class="cp-main">
                    <div class="cp-label">
                        <div class="cp-txt-up">Comment</div>
                        <div class="d-inline-flex">
                            <div class="cp-txt">14-12-23 10:37 AM (Super Admin) :</div>
                            <div class="cp-txt" v-html=" meetingInfo.comment">
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
      <!-- <div class="card-main">
                <div class="basic-info">Location</div>
            </div> -->
      <!-- <div class="cp-main-label p-4">
                <div class="cp-main">
                    <div class="cp-label">
                        <div class="cp-txt-up">Address</div>
                        <div class="cp-txt">
                          {{ meetingInfo.address }}
                        </div>
                    </div>
                </div>
            </div> -->
      <!-- <div class="card-main">
                <div class="basic-info">Campaigns</div>
            </div> -->
      <!-- <div class="cp-main-label p-4" v-for="(campaign,index) in meetingInfo.campaign_details" :key="index">
                <div class="cp-main">
                    <div class="cp-label">
                        <div class="cp-txt-up">Project Name:</div>
                        <div class="cp-txt">{{campaign.project_name}}</div>
                    </div>
                    <div class="cp-label">
                        <div class="cp-txt-up">Campaign Type:</div>
                        <div class="cp-txt">{{campaign.campaign_type}}</div>
                    </div>
                </div>
                <div class="cp-main">
                    <div class="cp-label">
                        <div class="cp-txt-up">Start Time:</div>
                        <div class="cp-txt">{{campaign.start_date}}</div>
                    </div>
                    <div class="cp-label">
                        <div class="cp-txt-up">End Time:</div>
                        <div class="cp-txt">{{campaign.end_date}}</div>
                    </div>
                </div>
                <div class="cp-main">
                    <div class="cp-label">
                        <div class="cp-txt-up">Comment:</div>
                        <div class="cp-txt">{{campaign.comment}}</div>
                    </div>
                    <div class="cp-label">
                        <div class="cp-txt-up">Status:</div>
                        <div class="cp-txt">{{campaign.status}}</div>
                    </div>
                </div>
            </div> -->
      <div
        class="delete"
        v-if="allMetaData && allMetaData.is_superadmin"
        @click="deleteMeeting"
      >
        <img src="../assets/delete.svg" alt="" />&nbsp;Delete Meeting
      </div>
    </div>
  </div>
</template>

    
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "IncompleteMeetingDetails",
  data() {
    return {
      meetingInfo: [],
      meetingdetails: [],
      allMetaData: [],
    };
  },
  methods: {
    goToEditMeeting(meetingInfo) {
      sessionStorage.setItem("editData", JSON.stringify(meetingInfo));
      this.$router.push({
        path: "/editincompletemeeting",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    showImage() {
      //window.location.href = this.meetingInfo.image;
      window.open(this.meetingInfo.image);
    },
    getMeetingDetails() {
      this.$axios
        .get(`meetings/${this.meetingdetails.value}`)
        .then((response) => {
          this.meetingInfo = response.data.meeting;
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    deleteMeeting() {
      let deleteConfirm = confirm("Are you sure you want to Delete?");
      if (deleteConfirm === false) {
        return;
      }
      this.$axios.delete(`meetings/${this.meetingInfo.value}`).then((res) => {
        console.log(res, "deleted");
        this.$router
          .push({
            path: "/incompletemeeting",
            // query: {
            //     uuid: this.$route.query.uuid
            // }
          })
          .catch((err) => {
            if (err.response.status === 401 || err.response.status === 404) {
              localStorage.removeItem("cp-mandate-token");
              this.$router.replace("/");
            }
          });
      });
    },
    getAllData() {
      this.$axios
        .get("builders/leads/meta_data")
        .then((response) => {
          this.allMetaData = response.data;
          // this.isSuperAdmin = response.data.is_superadmin;
          // this.isExecutive = response.data.is_executive
          // this.canAccess = response.data.can_access_contact_number;
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
  },
  mounted() {
    this.meetingdetails = JSON.parse(sessionStorage.getItem("meetingData"));
    this.getMeetingDetails();
    this.getAllData();
    console.log("meetingData", this.meetingInfo);
  },
};
</script>

    
<style scoped>
.tele-calling-details-title {
  font-size: 16px;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: 0em;
  color: #000000;
}

.cp-details {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  box-shadow: 0px 2px 4px 0px #0000000d;
  background-color: #fff;
}

.delete {
  color: #ee4b4b;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px 20px 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.56px;
}

.main-card {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0px 0px;
  background: #ffffff;
}

.img-detail {
  position: absolute;
  left: 16px;
}

.main-card-detail {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0px 0px;
  background: #ffffff;
}

.name-label {
  padding: 15px;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view {
  padding: 10px 15px;
  border: 1px solid #19a7ce;
  border-radius: 34px;
  color: #19a7ce;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.56px;
}

.enq-no {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  color: #763a3a;
}

.card-main {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.basic-info {
  background: #19a7ce;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  padding: 6px 0 6px 16px;
  color: #ffffff;
}

.cp-main-label {
  gap: 25px;
  display: flex;
  flex-direction: column;
  padding: 24px 0 24px 16px;
}

.cp-main {
  display: flex;
  flex-direction: row;
  /* gap: 31px  */
}

.other-info {
  display: flex;
  flex-direction: row;
  gap: 117px;
}

.cp-txt {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #a77272;
}

.cp-txt-up {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #a77272;
}

.cp-label {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.audio-main {
  padding: 15px;
}

.edit {
  border: 1px solid #19a7ce;
  background: #fff;
  border-radius: 34px;
  padding: 8px 16px;
  /* flex: 1; */
  text-align: center;
  color: #19a7ce;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.56px;
}
</style>
