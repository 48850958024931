<template>
  <div class="main-container">
    <div class="d-flex justify-content-center flex-column main-container">
      <div class="top-navbar">
        <h1>Lead Call History</h1>
        <a @click="$router.go(-1)" class="icon-left">
          <img src="../assets/back.svg" alt="" />
        </a>
      </div>
      <div class="page-content">
        <div class="label">
          <span class="label-cp">{{ selectedLead.name }}</span>
          <span class="label-txt">{{ selectedLead.lead_no }}</span>
        </div>
        <div class="tab-main mx-2">
          <ul
            class="nav nav-tabs lead-detail-tab mb-3"
            id="myTab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active clr"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Incoming
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link clr"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                OutGoing
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div
                class="lable-card"
                v-for="calls in incomingCalls"
                :key="calls"
              >
                <div class="cpl">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <div class="d-flex">
                        <div class="number">{{ calls.from_number }}</div>
                        <img
                          class="img-card"
                          src="../assets/blue-arrow.svg"
                          alt=""
                        />
                        <div class="number-side">{{ calls.to_number }}</div>
                      </div>
                    </div>
                    <div class="number">{{ calls.call_type }}</div>
                  </div>
                  <div class="d-flex incoming-start-end-date">
                    <div class="d-flex">
                      <div class="number me-1">Start:</div>
                      <div class="date-time">{{ calls.start_time }}</div>
                    </div>
                    <div class="d-flex">
                      <div class="number me-1">End:</div>
                      <div class="date-time">{{ calls.end_time }}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between cpp">
                  <div class="d-flex">
                    <div class="cp-lead me-1">Call Duration:</div>
                    <div class="cp-lead-side">{{ calls.duration }}</div>
                  </div>
                  <div class="d-flex">
                    <div class="cp-lead me-1">Call Status:</div>
                    <div class="cp-lead-side">{{ calls.status }}</div>
                  </div>
                </div>
                <div class="audio-main">
                  <audio controls>
                    <source :src="calls.recording_url" type="audio/ogg" />
                    <source :src="calls.recording_url" type="audio/mpeg" />
                    Your browser does not support the audio tag.
                  </audio>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div
                class="lable-card"
                v-for="calls in outGoingCalls"
                :key="calls"
              >
                <div class="cpl">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <div class="d-flex">
                        <div class="number">{{ calls.from_number }}</div>
                        <img
                          class="img-card"
                          src="../assets/blue-arrow.svg"
                          alt=""
                        />
                        <div class="number-side">{{ calls.to_number }}</div>
                      </div>
                    </div>
                    <div class="number">{{ calls.call_type }}</div>
                  </div>
                  <div class="d-flex incoming-start-end-date">
                    <div class="d-flex">
                      <div class="number me-1">Start:</div>
                      <div class="date-time">{{ calls.start_time }}</div>
                    </div>
                    <div class="d-flex">
                      <div class="number me-1">End:</div>
                      <div class="date-time">{{ calls.end_time }}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between cpp">
                  <div class="d-flex">
                    <div class="cp-lead me-1">Call Duration:</div>
                    <div class="cp-lead-side">{{ calls.duration }}</div>
                  </div>
                  <div class="d-flex">
                    <div class="cp-lead me-1">Call Status:</div>
                    <div class="cp-lead-side">{{ calls.status }}</div>
                  </div>
                </div>
                <div class="audio-main">
                  <audio controls>
                    <source :src="calls.recording_url" type="audio/ogg" />
                    <source :src="calls.recording_url" type="audio/mpeg" />
                    Your browser does not support the audio tag.
                  </audio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "leadhistory",
  data() {
    return {
      leadHistory: [],
      incomingCalls: [],
      outGoingCalls: [],
      selectedLead: {},
    };
  },
  methods: {
    getBuilderHistory() {
      const params = {
        id: this.selectedLead.value,
      };
      // if(callType) {
      //     params[callType] = true;
      // }
      this.$axios
        .get("builders/leads/call_log_histories", {
          params,
        })
        .then((response) => {
          this.leadHistory = response.data;
          this.incomingCalls = response.data.incoming_calls;
          this.outGoingCalls = response.data.outgoing_calls;
          // callType ? this.buildersLeads = response.data[callType] : this.buildersLeads = response.data.builders_leads;
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
  },
  mounted() {
    this.selectedLead = JSON.parse(sessionStorage.getItem("selectedDetails"));
    this.getBuilderHistory();
  },
};
</script>

<style scoped>
.audio-main {
  padding: 15px;
}

.audio-main audio {
  width: 100%;
  border: 1px solid #19a7ce;
  border-radius: 30px;
}

.incoming-start-end-date {
  flex-direction: column;
  gap: 4px;
}

.main-container {
  background-color: #f1e9e9;
}

.tab-main .nav-item .active {
  border-bottom: 3px solid #19a7ce;
  color: #19a7ce;
  font-weight: 600;
  letter-spacing: 0.56px;
}

.tab-main .nav-item:first-child button {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tab-main .nav-item:last-child button {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tab-main .nav-item button {
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #b9b9b9;
  color: #b9b9b9;
  font-size: 14px;
}

.cp-details {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  box-shadow: 0px 2px 4px 0px #0000000d;
  background-color: #fff;
}

.tele-calling-details-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  color: #000000;
}

.img {
  position: absolute;
  left: 16px;
}

.label {
  display: flex;
  gap: 10px;
  padding: 24px 0 24px 16px;
}

.label-cp {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  color: #763a3a;
}

.label-txt {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  color: #a77272;
}

.lead-detail-tab li {
  flex: 1;
}

.lead-detail-tab li button {
  width: 100%;
}

.lead-detail-tab {
  padding-top: 16px;
  border-radius: 16px 16px 4px 4px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05);
}

.actions-main .active {
  background: #19a7ce;
  color: #ffffff;
}

.tab-main {
  /* background: #FFFFFF; */
}

.cpl {
  border-bottom: 0.75px dashed #b9b9b9;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cp-lead {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #000000;
}

.cp-lead-side {
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  color: #000000;
}

/* .clr{
    border-bottom: 1px solid #19A7CE !important;
    border: none;
} */
.img-card {
  padding: 0px 5px 0px 5px;
}

.cpp {
  padding: 15px;
}

.lable-card {
  margin: 8px 0;
  background: #ffffff;
  border-radius: 16px;
  border: none;
}

.number {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #888888;
}

.number-side {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #000000;
}

.date-time {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #888888;
}
</style>
