<template>
  <div class="main-container">
    <div class="d-flex justify-content-center flex-column main-container">
      <div class="top-navbar">
        <h1>New Enquiry</h1>
        <a @click="$router.go(-1)" class="icon-left">
          <img src="../assets/close.svg" alt="" @click="toggleSideBar" />
        </a>
      </div>
      <div class="main-card page-content">
        <div class="mt-4 form-floating cp-form">
          <input
            type="text"
            v-model="leadForm.broker_lead_attributes.name"
            class="form-control"
            id="customerName"
            placeholder="Lead Name"
          />
          <label for="customerName"
            >Customer Name<span class="required">*</span></label
          >
          <div
            class="form-error-text"
            v-if="
              v$.leadForm.broker_lead_attributes.name.$invalid && isFormDirty
            "
          >
            This field is required
          </div>
        </div>
        <div class="form-floating cp-form">
          <input
            type="number"
            v-model="leadForm.broker_lead_attributes.phone"
            class="form-control"
            id="mobileNumber"
            placeholder="Mobile Number"
          />
          <label for="mobileNumber">Mobile Number</label>
        </div>
        <div class="form-floating cp-form">
          <input
            type="email"
            v-model="leadForm.broker_lead_attributes.email"
            class="form-control"
            id="email"
            placeholder="Email"
          />
          <label for="email">Email</label>
        </div>
        <div>
          <Multiselect
            label="name"
            v-model="leadForm.status_id"
            track-by="id"
            @input="selectedLeadStatus"
            :searchable="true"
            :options="statusOptions"
            class="multi-select card-details-value card-details-select"
            placeholder="Lead Status"
            :canDeselect="true"
          />
        </div>
        <template v-if="leadForm.status_id === 25">
          <div class="form-floating cp-form">
            <Multiselect
              label="name"
              v-model="leadForm.dead_reason"
              track-by="name"
              name="DeadReason"
              :searchable="true"
              :options="deadReasonsOptions"
              class="multi-select card-details-value card-details-select"
              placeholder="Dead Reason"
              :canDeselect="true"
              required
            />
            <div
              class="form-error-text"
              v-if="v$.leadForm.dead_reason?.$invalid && isFormDirty"
            >
              This field is required
            </div>
          </div>
          <div class="form-floating cp-form">
            <input
              type="text"
              class="form-control"
              v-model="leadForm.dead_reason_comment"
              id="cutomerName"
              placeholder="Customer Name"
              value="selectedLead.name"
            />
            <label for="cutomerName"
              >{{ leadForm.dead_reason }}<span></span
            ></label>
          </div>
        </template>
        <template v-if="leadForm.status_id == 22">
          <div class="date-picker-input">
            <VueDatePicker
              class="required"
              id="datepicker9"
              v-model="leadForm.booking_date"
              placeholder="Booking date"
              name="booking_date"
            >
            </VueDatePicker>
            <div
              class="form-error-text"
              v-if="v$.leadForm.booking_date?.$invalid && isFormDirty"
            >
              This field is required
            </div>
          </div>
        </template>
        <div>
          <!-- <label for="" class="card-details-label">Lead City</label> -->
          <!-- link for multiselect library https://github.com/vueform/multiselect -->
          <Multiselect
            label="name"
            v-model="leadForm.broker_lead_attributes.broker_id"
            track-by="name"
            name="broker"
            :searchable="true"
            :options="brokersOptions"
            class="multi-select card-details-value card-details-select required"
            placeholder="Broker"
            :canDeselect="true"
            required
          />
          <div
            class="form-error-text"
            v-if="
              v$.leadForm.broker_lead_attributes.broker_id.$invalid &&
              isFormDirty
            "
          >
            This field is required
          </div>
        </div>
        <div class="date-picker-input">
          <VueDatePicker
            v-model="leadForm.ncd"
            class=""
            id="datepicker1"
            name="ncd"
            placeholder="NCD"
            :required="true"
            @input="handleInput"
          ></VueDatePicker>
          <div
            class="form-error-text"
            v-if="v$.leadForm.ncd?.$invalid && isFormDirty"
          >
            This field is required
          </div>
        </div>
        <div>
          <Multiselect
            label="name"
            v-model="leadForm.broker_lead_attributes.user_id"
            track-by="name"
            :searchable="true"
            :options="userOptions"
            class="multi-select card-details-value card-details-select"
            placeholder="User"
            :canDeselect="false"
          />
        </div>
        <div>
          <Multiselect
            label="name"
            v-model="leadForm.broker_lead_attributes.project_id"
            track-by="name"
            name="project"
            :searchable="true"
            :options="projectsOptions"
            class="multi-select card-details-value card-details-select required"
            placeholder="Project"
            :canDeselect="false"
            required
          />
          <div
            class="form-error-text"
            v-if="
              v$.leadForm.broker_lead_attributes.project_id.$invalid &&
              isFormDirty
            "
          >
            This field is required
          </div>
        </div>
        <div>
          <Multiselect
            label="name"
            v-model="leadForm.broker_lead_attributes.source_id"
            track-by="name"
            :searchable="true"
            :options="sourcesOptions"
            class="multi-select card-details-value card-details-select"
            placeholder="Source"
            :canDeselect="false"
          />
        </div>
        <div class="form-floating cp-form">
          <input
            type="text"
            v-model="leadForm.broker_lead_attributes.other_contacts"
            class="form-control"
            id="otherContact"
            placeholder="Other Contact"
          />
          <label for="otherContact">Other Contact</label>
        </div>
        <div class="form-floating mb-2 cp-form">
          <textarea
            rows="3"
            class="form-control"
            name="Comments"
            v-model="leadForm.comment"
            placeholder="Leave a comment here"
            id="comment"
            @input="handleInput"
          ></textarea>
          <label for="comment">Comments</label>
          <div
            class="form-error-text"
            v-if="v$.leadForm.comment?.$invalid && isFormDirty"
          >
            This field is required
          </div>
        </div>
      </div>
      <div class="footer-section">
        <button type="button" class="bttn btn btn-clr" @click="getLeads()">
          <img src="../assets/save.svg" alt="" /> Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "newenquiry",
  components: {
    Multiselect,
  },
  data() {
    return {
      v$: useVuelidate(),
      value: [],
      options: [
        {
          value: "1",
          label: "Batman",
        },
        {
          value: "2",
          label: "Robin",
        },
        {
          value: "3",
          label: "Joker",
        },
      ],
      leadForm: {
        booking_date: "",
        dead_reason: "",
        dead_reason_comment: "",
        ncd: "",
        user_id: "",
        status_id: "",
        comment: "",
        broker_lead_attributes: {
          name: "",
          phone: "",
          email: "",
          broker_id: "",
          project_id: "",
          source_id: "",
          other_contacts: "",
          id: "",
        },
      },
      statusOptions: [],
      userOptions: [],
      sourcesOptions: [],
      projectsOptions: [],
      brokersOptions: [],
      deadReasonsOptions: [],
      isFormDirty: false,
    };
  },
  validations() {
    return {
      leadForm: {
        ncd: { required: requiredIf(this.leadForm.status_id !== 25) },
        booking_date: { required: requiredIf(this.leadForm.status_id === 22) },
        dead_reason: { required: requiredIf(this.leadForm.status_id === 25) },
        comment: { required: requiredIf(this.leadForm.status_id !== 25) },
        broker_lead_attributes: {
          name: { required },
          broker_id: { required },
          project_id: { required },
        },
      },
    };
  },
  methods: {
    selectedLeadStatus() {
      console.log(this.leadForm, "leadform");
      if (this.leadForm.status_id != 25) {
        this.v$.leadForm.ncd = { required };
      } else {
        delete this.v$.leadForm.ncd;
      }
    },
    // handleInput(e) {
    //     let {
    //         name,
    //         value
    //     } = e.target;
    //     console.log(name, value);
    //     if (value == '') {
    //         this.errors[name] = `${name} should not be empty`;
    //     } else {
    //         this.errors[name] = "";
    //     }
    //     console.log(this.errors)
    // },
    getLeads() {
      console.log(this.v$);
      if (this.v$.$invalid) {
        this.isFormDirty = true;
        return;
      }
      // if(this.leadForm.broker_lead_attributes.name=='') {
      //           this.errors["Customer_Name"] = "Customer_Name should not be empty"
      //       }
      //       console.log(this.leadForm.dead_reason,"dead reason")
      //       if(this.leadForm.dead_reason==''){
      //           this.errors["DeadReason"] = "DeadReason should not be empty"
      //       }else {
      //         this.errors["DeadReason"] = "";
      //       }
      //       console.log(this.leadForm.booking_date,"dead reason 1111111111")
      //       if(this.leadForm.booking_date=='' && this.leadForm.status_id == 22){
      //           this.errors["booking_date"] = "booking_date should not be empty"
      //       }else {
      //         this.errors["booking_date"] = "";
      //       }

      //       if(this.leadForm.broker_lead_attributes.broker_id==''){
      //           this.errors["broker"] = "broker should not be empty"
      //       }else {
      //         this.errors["broker"] = "";
      //       }
      //       if(this.leadForm.ncd=='' && this.leadForm.status_id != 25){
      //           this.errors["ncd"] = "ncd should not be empty"
      //       }else {
      //         this.errors["ncd"] = ""
      //       }
      //       if(this.leadForm.comment=='' && this.leadForm.ncd!=''){
      //           this.errors["Comments"] = "Comments should not be empty"
      //       }else {
      //         this.errors["Comments"] = "";
      //       }

      //       if(this.leadForm.broker_lead_attributes.project_id==''){
      //           this.errors["project"] = "project should not be empty"
      //       }else {
      //         this.errors["project"] = "";
      //       }

      //       if(this.leadForm.broker_lead_attributes.name=='' || this.leadForm.dead_reason=='' || (this.leadForm.booking_date=='' && this.leadForm.status_id == 22) || this.leadForm.broker_lead_attributes.broker_id=='' || this.leadForm.broker_lead_attributes.project_id=='' || (this.leadForm.ncd=='' && this.leadForm.status_id != 25) || (this.leadForm.comment=='' && this.leadForm.ncd!='')){
      //           return;
      //       }

      let result = this.deadReasonsOptions.filter((obj) => {
        return obj.value == this.leadForm.dead_reason;
      });
      this.leadForm.dead_reason = result[0]?.name;

      this.$axios
        .post("builders/leads", {
          builder_lead: this.leadForm,
        })
        .then((res) => {
          console.log(res, "Res");
          this.$swal({
            title: res.data.message,
            icon: "success",
          }).then(() => {
            this.$router.push({
              path: "/home",
            });
          });
        })
        .catch((err) => {
          // console.log(err);
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          });
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        });
    },
    getAllData() {
      this.$axios
        .get("builders/leads/meta_data")
        .then((response) => {
          this.statusOptions = response.data.status;
          this.userOptions = response.data.users;
          this.sourcesOptions = response.data.sources;
          this.projectsOptions = response.data.projects;
          this.brokersOptions = response.data.brokers;
          this.deadReasonsOptions = response.data.dead_reason;
          for (var i = 0; i < this.deadReasonsOptions.length; i++) {
            this.deadReasonsOptions[i]["name"] =
              this.deadReasonsOptions[i]["reason"];
            this.deadReasonsOptions[i]["value"] =
              this.deadReasonsOptions[i]["question"];
          }
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    // this.getLeads()
    this.getAllData();
  },
};
</script>

<style scoped>
.form-error-text {
  font-weight: 300;
  font-size: 13px;
  margin-top: 5px;
  color: red;
}
/* .error {
    font-size: 12px;
    color: red;
} */
.main-container {
  background-color: #f1e9e9;
}

.search-img {
  /* position: absolute;
    top: 4px;
    left: 274px; */
}

.search-img {
  position: absolute;
  top: 4px;
  left: 295px;
}

.cp-details {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  box-shadow: 0px 2px 4px 0px #0000000d;
  background-color: #fff;
}

.tele-calling-details-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  color: #000000;
}

.img {
  position: absolute;
  left: 16px;
}

.card-details-value {
  border-radius: 8px !important;
  /* opacity: 0.5;
    padding: 12px 20px; */
  color: #bfbfbf;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  border: 1px solid #b9b9b9;
  padding: 15px;
}

/* .btn-clr {
    background-color: #19A7CE;
    width: 100%;
    color: #19A7CE;
    border: 1px solid #19A7CE;
    border-radius: 34px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
} */

.search-clr {
  width: 100%;
  background-color: #19a7ce;
  /* border: 1px solid #19A7CE; */
  color: #ffffff;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}

.submit-section {
  position: relative;
}

.main-card {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  background: #ffffff;
}

.btn-clr {
  /* background-color: #19A7CE; */
  width: 100%;
  color: #ffffff;
  /* border: 1px solid #19a7ce; */
  background: #19a7ce;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.card-details-select {
  padding: 5px 15px;
}

.user-input-wrp {
  position: relative;
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  width: 100%;
  background: white;
}

.user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  /* border-bottom: 1px solid #777; */
  box-shadow: none !important;
  padding-bottom: 3px;
  padding-left: 16px;
  border-radius: 20px;
}

.user-input-wrp .inputText:focus {
  border-color: blue;
  border-width: medium medium 2px;
}

.user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 18px;
  left: 10px;
  transition: 0.2s ease all;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #888888;
}

.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:not(:focus):valid ~ .floating-label {
  top: 6px;
  left: 16px;
  font-size: 13px;
  opacity: 1;
}
</style>
