<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main-container bg-body">
    <div class="top-navbar">
      <a @click="$router.go(-1)" class="icon-left">
        <img src="../assets/back.svg" alt="">
      </a>
      <h1>Broker Initiation</h1>
      <img class="icon-right" src="../assets/menu-open.svg" alt="" @click="toggleSideBar">

    </div>
    <div class="page-content pt-4">
      <div class="cp-containe px-3 mt-4" v-if="!isAdvancedSearch">
        <div class="initiation">CP Initiation - {{ projectDetails.name }}</div>
        <div class="search-box-main">
          <img src="../assets/search.svg" alt="" class="search-img-sub">
          <input type="text" v-model="search" @input="debouncedOnChange" placeholder="Search Broker name or Firm Name"
            class="text-input">
          <!-- <div @click="openAdvancedFilter()"><img src="" alt=""></div> -->
        </div>
      </div>
      <div class="advance-search-main px-3 mt-3" @click="goToAdvanceSearch()">
        <span class="me-2 d-inline-block"><img src="../assets/advance-search.svg" alt=""></span>
        <span>ADVANCE SEARCH</span>
        <div class="opacity-50">Refine your Search Results!</div>
        <div><img class="advance-search" src="../assets/arrow.svg" alt=""></div>
      </div>
      <div v-if="!isAdvancedSearch">
        <div class="count ps-4 mt-2">Broker Count - <span class="text-bold">{{ brokerInitiationData.total_count }}</span>
        </div>
        <div class="cp-card-container px-3">
          <div class="cp-card" v-for="(broker, index) in brokerInitiationData.broker_initiation" :key="index">
            <div class="cp-card-content" v-if="broker">
              <div class="cp-card-new-label">
                <div>
                  <span class="head-name" v-if="broker && broker.broker_name">{{ broker.broker_name }}</span>
                  <div class="cp-calling-exe" v-if="broker && broker.broker_firm_name">Firm Name: <span
                      class="sub-text">{{
                        broker.broker_firm_name }}</span></div>
                  <div class="cp-calling-exe" v-if=" canAccess && broker && broker.broker_phone">Contact: <span class="sub-text">{{
                    broker.broker_phone }}</span></div>
                  <div class="cp-calling-exe" v-if="broker && broker.locality_name">Location: <span class="sub-text">{{
                    broker.locality_name }}</span></div>
                </div>
                <div class="d-flex flex-column align-items-center">
                  <div class="form-check ms-0 ps-0">
                  <input class="form-check-input ms-0" type="checkbox" value="" @input="getBroker($event, broker.value)">
                </div>
                <div class="actions-main mt-2" @click="editAssingto(broker)">
                  <div class="rounded-1 p-1">
                    <img src="../assets/edit.svg" alt="" width="12" height="12" />
                  </div>
                </div>
                </div>
              </div>
              <!-- <div class="cp-project-name">  </div> -->
            </div>
          </div>
        </div>
        <div class="pagination-indicator">
                  <span class="result-Text">Showing {{ pageStart }} - {{ pageEnd }} out of {{ total }}</span>
              </div>
              <div class="pagination-holder mt-4">
                  <paginate  v-model="page" :page-count="totalPages" :page-range="3" :margin-pages="2" :click-handler="goToPage" :prev-text="'Prev'" :next-text="'Next'" :page-link-class="'page-link'" :prev-link-class="'page-link'" :next-link-class="'page-link'" :disabled-class="'page-item disabled'" :container-class="'pagination justify-content-center'" :page-class="'page-item'">
                  </paginate>
              </div>
      </div>
      <div class="footer-section">
        <button type="button" class="assign-to" @click="goToassigTO()">Assign To</button>
      </div>
    </div>
    <!-- Assign To starts -->
    <div class="assign-to-form" v-if="assignTO">
      <div class="form-main assign-to-card">
        <div class="card-title">CP Initiation</div>
        <div>
          <Multiselect label="name" track-by="name" v-model="brokerAssignTo.user_id" :searchable="true"
            class="multi-select card-details-value card-details-select" placeholder="Assign To" :options="userOptions"
            :canDeselect="false" />
            <div class="form-error-text" v-if="v$.brokerAssignTo.user_id.$invalid && isFormDirty">
        This field is required
      </div>
        </div>
        <div>
          <Multiselect label="name" track-by="name" v-model="brokerAssignTo.meeting_executive_id" :searchable="true"
            class="multi-select card-details-value card-details-select" placeholder="Field Executive"
            :options="userOptions" :canDeselect="false" />
        </div>
        <div>
          <Multiselect label="name" track-by="name" v-model="brokerAssignTo.status_id" :searchable="true"
            class="multi-select card-details-value card-details-select" placeholder="Status" :options="initStatus"
            :canDeselect="false" />
        </div>
        <div class="date-picker-input">
          <VueDatePicker class="" id="datepicker" placeholder="NCD" v-model="brokerAssignTo.ncd"></VueDatePicker>
        </div>
        <!-- <div>
          <Multiselect label="name" track-by="name" v-model="brokerAssignTo.locality_id" :searchable="true"
            class="multi-select card-details-value card-details-select" placeholder="Locality"
            :options="localitiesOptions" :canDeselect="false" />
        </div> -->
        <!-- <div class="form-floating cp-form">
          <textarea rows="4" class="form-control" v-model="brokerAssignTo.comment" placeholder="Comment"
            id="minutes2"></textarea>
          <label for="comment">Comment</label>
        </div> -->
        <div class="footer-section position-relative shadow-none pb-0 px-0">
          <div class="submit-text m-0" @click="submitAssingTo">Submit</div>
          <div class="cancle-text m-0" @click="cancelAssingTO">Cancel</div>
        </div>
      </div>
    </div>
    <!-- Assign To ends -->
     <!-- Assign To Edit starts -->
     <div class="assign-to-form" v-if="isAssinToEdit">
      <div class="form-main assign-to-card">
        <div class="card-title">CP Initiation</div>
        <div>
          <div class="cp-calling-exe" v-if="editBrokerData && editBrokerData.broker_name">Broker Name: <span
                      class="sub-text">{{
                        editBrokerData.broker_name }}</span></div>
                  <div class="cp-calling-exe" v-if="editBrokerData && editBrokerData.broker_firm_name">Firm Name: <span
                      class="sub-text">{{
                        editBrokerData.broker_firm_name }}</span></div>
                  <div class="cp-calling-exe" v-if="canAccess && editBrokerData && editBrokerData.broker_phone">Broker Contact: <span class="sub-text">{{
                    editBrokerData.broker_phone }}</span></div>
                </div>
        <div>
          <Multiselect label="name" track-by="name" v-model="brokerEditAssignTo.projects_broker.user_id" :searchable="true"
            class="multi-select card-details-value card-details-select" placeholder="Assign To" :options="userOptions"
            :canDeselect="false" />
            <div class="form-error-text" v-if="v$.brokerEditAssignTo.projects_broker.user_id.$invalid && isFormDirty">
        This field is required
      </div>
        </div>
        <div>
          <Multiselect label="name" track-by="name" v-model="brokerEditAssignTo.projects_broker.status_id" :searchable="true"
            class="multi-select card-details-value card-details-select" placeholder="Status" :options="initStatus"
            :canDeselect="false" />
        </div>
        <div class="date-picker-input">
          <VueDatePicker class="" id="datepicker" placeholder="NCD" v-model="brokerEditAssignTo.projects_broker.ncd"></VueDatePicker>
          <div class="form-error-text" v-if="v$.brokerEditAssignTo.projects_broker.ncd.$invalid && isFormDirty">
                  This field is required
                </div>
        </div>
        <!-- <div>
          <Multiselect label="name" track-by="name" v-model="brokerEditAssignTo.projects_broker.performance" :searchable="true"
            class="multi-select card-details-value card-details-select" placeholder="Performance" :options="performanceOptions"
            :canDeselect="false" />
        </div> -->
        <!-- <div>
          <Multiselect label="name" track-by="name" v-model="brokerEditAssignTo.meeting_executive_id" :searchable="true"
            class="multi-select card-details-value card-details-select" placeholder="Field Executive"
            :options="userOptions" :canDeselect="false" />
        </div> -->
        <!-- <div>
          <Multiselect label="name" track-by="name" v-model="brokerEditAssignTo.projects_broker.cp_meet" :searchable="true"
            class="multi-select card-details-value card-details-select" placeholder="CP MEET" :options="cpMeetOptions"
            :canDeselect="false" />
        </div> -->
        <div>
          <Multiselect label="name" track-by="name" v-model="brokerEditAssignTo.projects_broker.broker_attributes.locality_id" :searchable="true"
            class="multi-select card-details-value card-details-select" placeholder="Locality"
            :options="localitiesOptions" :canDeselect="false" />
        </div>
        {{ showComment }}
        <div class="form-floating cp-form">
          <textarea rows="4" class="form-control" v-model="brokerEditAssignTo.projects_broker.comment" placeholder="Comment"
            id="minutes2"></textarea>
          <label for="comment">Comment</label>
          <div class="form-error-text" v-if="v$.brokerEditAssignTo.projects_broker.comment.$invalid && isFormDirty">
                  This field is required
                </div>
        </div>
        <!-- <div class="form-check ms-0 ps-0">
                  <input class="form-check-input ms-0" type="checkbox" value="" v-model="brokerEditAssignTo.projects_broker.visited">
                  <label for="comment">Visited</label>
                </div> -->
        <div class="footer-section position-relative shadow-none pb-0 px-0">
          <div class="submit-text m-0" @click="editSubmitAssingTo">Update</div>
          <div class="cancle-text m-0" @click="cancelEditAssingTo">Cancel</div>
        </div>
      </div>
    </div>
    <!-- Assign To Edit ends -->
    <!-- side bar starts -->
    <div class="side-bar" v-if="isSideBarOpen">
      <div class="side-bar-container">
        <div class="d-flex justify-content-center flex-column main-container">
          <div class="cp-details clr justify-content-between">
            <h1 class="tele-calling-details-title">{{ userRole }}</h1>
            <img class="img-advanc" src="../assets/menu-close.svg" alt="" @click="toggleSideBar">
          </div>
          <div class="sidebar-txt">
            <div class="p-3 active" @click="goTOHome">Manage Leads</div>
            <div class="p-3" @click=" goToCallAnalysis()">Call Analysis</div>
            <div @click="goToBrokers()" class="p-3">Brokers</div>
            <div class="p-3" @click=" goToProjects()">Projects</div>
            <div class="p-3" @click=" goToManageMeeting()">Manage Meetings</div>
          </div>
        </div>
        <div class="logout"><img src="../assets/logout.svg" alt="" @click="getlogout">
          Logout
        </div>
      </div>
      <div class="logout"><img src="../assets/logout.svg" alt="" @click="getlogout">
        Logout
      </div>
    </div>
  </div>
  <div class="loader-wrapper" v-if="isLoading">
        <div class="loader">Loading...</div>
      </div>
  <!-- advanced Search  -->
  <div class="adv-main-container" v-if="isAdvancedSearch">
    <div class="d-flex justify-content-center flex-column main-container">
      <div class="top-navbar">
        <h1>Advance Search</h1>
        <!-- <a @click="$router.go(-1)" class="back-arrow"> -->
        <img class="icon-left" src="../assets/close.svg" alt="" @click="closeAdvSearch">
        <!-- </a> -->
      </div>
      <div class="form-main page-content">
        <div class=" mt-4 form-floating cp-form">
          <input type="text" class="form-control" v-model="brokerAdvanceSearch.broker_name" placeholder="Broker Name" />
          <label for="mobileNumber">Broker Name</label>
        </div>
        <div class="form-floating cp-form">
          <input type="text" class="form-control" v-model="brokerAdvanceSearch.firm_name" id="firmName"
            placeholder="Firm Name">
          <label for="firmName">Firm Name</label>
        </div>
        <div class="form-floating cp-form">
          <input type="number" class="form-control" v-model="brokerAdvanceSearch.phone" id="number"
            placeholder="Mobile Number">
          <label for="email">Mobile Number</label>
        </div>
        <div>
          <Multiselect label="name" track-by="name" v-model="brokerAdvanceSearch.locality_ids" :searchable="true"
            class="multi-select card-details-value card-details-select" :options="localitiesOptions" mode="multiple"
            placeholder="Localities" :canDeselect="false" />
        </div>
        <div>
          <Multiselect label="name" track-by="name" v-model="brokerAdvanceSearch.assigned_to_ids" :searchable="true"
            class="multi-select card-details-value card-details-select" placeholder="Assigned to" mode="multiple"
            :options="executivesOptions" :canDeselect="false" />
        </div>
        <div>
          <Multiselect label="name" track-by="name" v-model="brokerAdvanceSearch.field_executive_ids" :searchable="true"
            class="multi-select card-details-value card-details-select" :options="executivesOptions" mode="multiple"
            placeholder="Field Executive" :canDeselect="false" />
        </div>
        <div>
          <Multiselect label="name" track-by="name" :searchable="true" v-model="brokerAdvanceSearch.status_ids"
            class="multi-select card-details-value card-details-select" :options="cpOptions"
            placeholder="Status" mode="multiple" :canDeselect="false" />
        </div>
        <div class="date-picker-input">
          <VueDatePicker class="" id="datepicker1" v-model="brokerAdvanceSearch.ncd_from" placeholder="NCD From">
          </VueDatePicker>
        </div>
        <div class="date-picker-input">
          <VueDatePicker class="" id="datepicker2" v-model="brokerAdvanceSearch.ncd_upto" placeholder="NCD UPto">
          </VueDatePicker>
        </div>
        <div class="form-group my-3 submit-section-clr">
          <button type="button" class="bttn btn-clr" @click="clearAdvanceSearch">Clear All</button>
        </div>
        <div class="footer-section">
          <button type="button" class="bttn btn search-clr" @click="advanceSearch()"> <img
              src="../assets/search-white.svg" alt="" /> Search</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { mapGetters } from 'vuex';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { useVuelidate } from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import _debounce from 'lodash.debounce';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'brokerinitiation',
  components: {
    Multiselect,
    VueDatePicker
  },
  data() {
    return {
      isLoading:true,
      total: 0,
      perPage: 0,
      userRole: localStorage.getItem('tele-calling-name'),
      v$: useVuelidate(),
      brokerAdvanceSearch: {
        broker_name: "",
        firm_name: "",
        phone: "",
        locality_ids: [],
        assigned_to_ids: [],
        field_executive_ids: [],
        status_ids: [],
        ncd_from: "",
        ncd_upto: ""
      },
      brokerAssignTo: {
        broker_ids: [],
        user_id: "",
        meeting_executive_id: "",
        status_id: "",
        ncd: "",
        // comment: "",
        // locality_id: ""
      },
      brokerEditAssignTo: {
        projects_broker:{
        user_id: "",
        status_id: "",
        ncd: "",
        // performance: "",
        // cp_meet: "",
        comment: "",
        // visited: "",
        broker_attributes: {
            id: "",
            locality_id: ""
        }
      },
       id: "",
       pb_id: ""
      },
      isSideBarOpen: false,
      search: "",
      leadData: {

      },
      data: null,
      date1: null,
      date2: null,
      buildersLeads: [],
      leadSearch: {
        // builders_leads:{
        name: '',
        phone: '',
        email: '',
        project_name: '',
        source: '',
        status: '',
        broker_status: '',
        broker_name: '',
        calling_executive: '',
        field_executive: '',
        ncd: null,
        created_at: '',
        updated_at: '',
        visit_details: [],
        booking_date: null,
        visited: '',
        revisited: '',
        is_qualified: ''
        // }
      },
      statusOptions: [],
      userOptions: [],
      sourcesOptions: [],
      projectsOptions: [],
      brokersOptions: [],
      executivesOptions: [],
      cpOptions: [],
      projectDetails: [],
      brokerInitiationData: [],
      localitiesOptions: [],
      isAdvancedSearch: false,
      isFromAdvSearch: false,
      assignTO: false,
      isAssinToEdit:false,
      editBrokerData:"",
      performanceOptions: [],
      cpMeetOptions: ["Yes", "No"],
      initStatus:[],
      isFormDirty:false,
      canAccess:false,
      showComment:""
    }
  },
  validations () {
      return {
        brokerEditAssignTo: {
          projects_broker:{
          user_id: {required},
          ncd: {required},
          comment: {required: requiredIf(this.brokerEditAssignTo.ncd != '')},
        }
      },
      brokerAssignTo: {
        user_id:{required: requiredIf(this.assignTO==true)},
      }
    }
  },
  computed: {
    ...mapGetters(['loginData']),
    debouncedOnChange () {
      return _debounce(this.searchFilter, 700);
    },
    totalPages() {
        if (this.total === 0 || this.perPage === 0) {
            return 0;
        } else {
            return Math.ceil(this.total / this.perPage);
        }
    },
    page: {
        get() {
            let page = this.$route.query.page || 1
            return parseInt(page);
        },
        set() {
            true;
        }
    },
    pageStart() {
        if (this.total === 0) {
            return 0;
        }
        return (Math.max(0, (this.page - 1) * this.perPage + 1))
    },
    pageEnd() {
        if (this.total === 0) {
            return 0;
        }
        return (Math.min(this.total, this.page * this.perPage))
    },
  },
  methods: {
    clearAdvanceSearch() {
      this.brokerAdvanceSearch = {
        broker_name: "",
        firm_name: "",
        phone: "",
        locality_ids: [],
        assigned_to_ids: [],
        field_executive_ids: [],
        status_ids: [],
        ncd_from: "",
        ncd_upto: ""
      }
    },
    editAssingto(editbrokervalue) {
     this.editBrokerData = editbrokervalue;
     console.log("bb",this.editBrokerData);
     this.brokerEditAssignTo.projects_broker.user_id = this.editBrokerData.assigned_to_id;
     this.brokerEditAssignTo.projects_broker.ncd = this.editBrokerData.ncd;
     this.brokerEditAssignTo.projects_broker.status_id = this.editBrokerData.status_id;
     this.showComment = this.editBrokerData.comment;
     this.brokerEditAssignTo.projects_broker.broker_attributes.locality_id = this.editBrokerData.locality_id;
     this.brokerEditAssignTo.projects_broker.broker_attributes.id = this.editBrokerData.broker_id;
     this.brokerEditAssignTo.pb_id = this.editBrokerData.value;
      this.isAssinToEdit = true;
    },
    openDialPad(phoneNo) {
      const phoneNumber = phoneNo;
      window.location.href = 'tel:' + phoneNumber;
    },
    getBroker(event, brokerValue) {
      if(event.target.checked) {
          this.brokerAssignTo.broker_ids.push(brokerValue);
      } else {
        const index = this.brokerAssignTo.broker_ids.indexOf(brokerValue);
        if (index > -1) { // only splice array when item is found
          this.brokerAssignTo.broker_ids.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
      console.log("valll", this.brokerAssignTo.broker_ids);
    },
    toggleSideBar() {
      this.isSideBarOpen = !this.isSideBarOpen;
    },
    getlogout() {
      this.$axios.delete('logout').then(() => {
        localStorage.removeItem('cp-mandate-token');
        this.$router.replace('/');
      });
    },
    // getlogout() {
    //     this.$router.push({
    //         path: "/",
    //         query: {
    //             uuid: this.$route.query.uuid
    //         }
    //     });
    // },
    goToPage(page) {
            let newQ = {
                ...this.$route.query,
                page
            };
            this.$router.replace({
                query: newQ
            });
            this.isFromAdvSearch ? this.advanceSearch(newQ) : this.getProjectData(newQ);
        },
    goToHistory(selectedDetails) {
      sessionStorage.setItem("selectedDetails", JSON.stringify(selectedDetails));
      this.$router.push({
        path: "/leadhistory",
        query: {
          uuid: this.$route.query.uuid
        }
      });
    },
    goToProjects() {
      this.$router.push({
        path: "/projects",
        query: {
          uuid: this.$route.query.uuid
        }
      });
    },
    searchFilter() {
      const params = {
        bs: this.search
      };
      this.$axios.get(`projects/${this.projectDetails.value}/brokers/initiation`, { params }).then((response) => {
        this.brokerInitiationData = response.data;
        window.scrollTo(0,0);
      })
        .catch(err => {
          this.$swal({
            title: err.message,
            icon: "warning",
          })
        })
    },
    cancelAssingTO() {
     this.assignTO = false;
    },
    cancelEditAssingTo() {
    this.isAssinToEdit = false;
    },
    submitAssingTo() {
      if (this.v$.brokerAssignTo.user_id.$invalid) {
        console.log("error",this.v$);
        this.isFormDirty = true;
        return;
      }
      this.$axios.post(`projects/${this.projectDetails.value}/brokers/initiation`, this.brokerAssignTo).then((response) => {
        this.assignTO = false;
        console.log("res", response);
        // this.goToProjects();
        window.location.reload();
        this.getProjectData();
      })
        .catch(err => {
          let errMsg;
          err.response.data.errors.filter((m)=>{
           errMsg = m.message;
          })
          console.log("errr",errMsg);
          this.$swal({
            title: errMsg,
            icon: "warning",
          })
        })
    },
    editSubmitAssingTo() {
      if (this.v$.$invalid) {
        this.isFormDirty = true;
        return;
      }
      this.brokerEditAssignTo.id = this.projectDetails.value;
      //this.brokerEditAssignTo.projects_broker.cp_meet = this.brokerEditAssignTo.projects_broker.cp_meet === "Yes" ? true : false;
      this.$axios.patch(`projects/${this.projectDetails.value}/brokers/${this.editBrokerData.value}/initiation`, this.brokerEditAssignTo).then((response) => {
        console.log(response);
        this.isAssinToEdit = false;
        //this.goToProjects();
        window.location.reload();
        this.getProjectData();
      })
        .catch(err => {
          let errMsg;
          err.response.data.errors.filter((m)=>{
           errMsg = m.message;
          })
          console.log("errr",errMsg);
          this.$swal({
            title: errMsg,
            icon: "warning",
          })
        })
    },
    goToManageMeeting() {
      this.$router.push({
        path: "/managemeetings",
        query: {
          uuid: this.$route.query.uuid
        }
      });
    },
    goToCallAnalysis() {
      this.$router.push({
        path: "/callanalysis",
        query: {
          uuid: this.$route.query.uuid
        }
      });
    },
    goToNewMeeting() {
      this.$router.push({
        path: "/newmeeting",
        query: {
          uuid: this.$route.query.uuid
        }
      });
    },
    goToHome() {
      this.$router.push({
        path: "/home",
        query: {
          uuid: this.$route.query.uuid
        }
      });
    },
    goToDetail(leads) {
      sessionStorage.setItem("selectedDetails", JSON.stringify(leads));
      this.$router.push({
        path: "/leaddetail",
        query: {
          uuid: this.$route.query.uuid
        }
      });
    },
    goToBrokers() {
      this.$router.push({
        path: "/broker",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    goToAdvanceSearch() {
      // this.$router.push({
      //     path: "/advancesearch",
      //     query: {
      //         uuid: this.$route.query.uuid
      //     }
      // });
      this.isAdvancedSearch = true;
    },
    closeAdvSearch() {
      this.isAdvancedSearch = false;
    },

    goToassigTO() {
      this.assignTO = true;
    },
    closeAssiTo() {
      this.assignTO = false;
    },
    goToNewEnquary() {
      this.$router.push({
        path: "/newenquiry",
        query: {
          uuid: this.$route.query.uuid
        }
      });
    },
    getBuilderLeads(callType) {
      const params = {
        page: 1,
      };
      if (callType) {
        params[callType] = true;
      }
      this.$axios.get('builders/leads', {
        params
      }).then((response) => {
        this.leadData = response.data;
        callType ? this.buildersLeads = response.data[callType] : this.buildersLeads = response.data.builders_leads;
      });
    },
    advanceSearch(newQ) {
      this.isFromAdvSearch = true;
      this.brokerInitiationData = [];
      this.brokerAdvanceSearch["page"] = 1;
      this.brokerAdvanceSearch["page"] = newQ?.page
      this.brokerAdvanceSearch.phone = this.brokerAdvanceSearch.phone.toString();
      this.$axios.post(`projects/${this.projectDetails.value}/brokers/initiation/search`, this.brokerAdvanceSearch).then(res => {
        this.brokerInitiationData = res.data;
        this.perPage = res.data.per_page;
        this.total = res.data.total_count;
        this.isAdvancedSearch = false;
      })
        .catch(err => {
          this.$swal({
            title: err.message,
            icon: "warning",
          })
        })
    },
    // advanceSearch() {
    //     console.log()
    //     this.$axios.post('builders/leads/search', {
    //             q: this.leadSearch
    //         }).then(res => {
    //             console.log(res, "Res")
    //         })
    //         .catch(err => {
    //             this.$swal({
    //                 title: err.message,
    //                 icon: "warning",
    //             })
    //         })
    // },
    getProjectData(newQ) {
      this.isFromAdvSearch = false;
      const params = {
          page: 1,
      };
      params["page"] = newQ?.page;
      this.$axios.get(`projects/${this.projectDetails.value}/brokers/initiation`, { params }).then(res => {
        this.isLoading = false;
        this.brokerInitiationData = res.data;
        this.perPage = res.data.per_page;
        this.total = res.data.total_count;
        window.scrollTo(0,0);
      })
        .catch(err => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          })
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        })
    },
    getAllData() {
      this.$axios.get('builders/leads/meta_data').then((response) => {
        this.statusOptions = response.data.status;
        this.userOptions = response.data.users;
        this.sourcesOptions = response.data.sources;
        this.projectsOptions = response.data.projects;
        this.brokersOptions = response.data.brokers;
        this.executivesOptions = response.data.executives;
        this.cpOptions = response.data.pb_status;
        this.localitiesOptions = response.data.localities;
        this.performanceOptions = response.data.pb_performance;
        this.initStatus = response.data.init_status;
        this.canAccess = response.data.can_access_contact_number;
      })
        .catch(err => {
          this.$swal({
            title: err.response.data.message,
            icon: "warning",
          })
          if (err.response.status === 401 || err.response.status === 404) {
            localStorage.removeItem("cp-mandate-token");
            this.$router.replace("/");
          }
        })
    }
  },
  mounted() {
    this.projectDetails = JSON.parse(sessionStorage.getItem("projectData"));
    //this.getBuilderLeads(null);
    //this.advanceSearch();
    this.getAllData();
    this.getProjectData();

  }
}
</script>

<style scoped>
.assign-to-form {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  height: 100dvh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}

.card-title {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.56px;
}

.form-error-text {
  font-weight: 300;
  font-size: 13px;
  margin-top: 5px;
  color: red;
}

.form-error-text.floating__error {
  position: absolute;
  right: 12px;
  top: -14px;
  background: #ffffff;
  display: inline-block;
  margin-bottom: -20px;
}

/* side bar starts */
.assign-to {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  background: #19A7CE;
  color: #FFFFFF;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 34px;
  padding: 12px;
  border: none;
  outline: none;
}

.assign-to-card {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -2px 4px 0px #0000000D;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #fff;
}

.head-name {

  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0em;
  color: #000000;

}

.side-bar {
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  height: 100vh;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.side-bar-container {
  width: 80%;
  background: #fff;
  position: relative;
  left: 20%;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.audio-main {
  padding: 15px;
}

.logout {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.12px;
  color: #EE4B4B;
  padding-bottom: 20px;
}

.initiation {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  color: #763A3A;
}

.sidebar-txt {
  display: flex;
  color: #888888;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  background: white;
  padding: 0 10px;
}

.sidebar-txt div {
  color: #888;
  font-size: 16px;
  border-bottom: 1px solid #F1E9E9;
  letter-spacing: 0.5px;
}

.sidebar-txt .active {
  color: #19A7CE;
  font-weight: 600;
}

/* sidebar ends */

.cp-container {
  padding: 15px;
}

.cp-detail {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  margin-bottom: 15px;
}

.cp-detail-new {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  margin-bottom: 32px;
}

.text-bold {
  font-weight: 600;
}

.inc-meet {
  font-size: 20px;
  font-weight: 800;
  line-height: 29px;
  letter-spacing: 0em;
  color: #000000;

}

.new-meet {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;

}

.main-container {
  background-color: #FAFAFA;
}

.cp-details {
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px 0px #0000000D;
  background-color: #fff;
}

.clr {
  background: #F1E9E9 !important;

}

/* .clor{
            background-color: #fff !important;
        } */

.close {
  position: absolute;
  left: 16px;
}

.cp-detail-txt {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 2px 4px 0px #00000014;
  justify-content: space-between;
}

.cp-detail-plus {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 8px;
  flex: 1;
  justify-content: space-between;
  box-shadow: 0px 2px 4px 0px #00000014;

}

.cp-detail-add {
  background: #EE964B;
  color: #FFFFFF;
}

.tele-calling-details-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  color: #000000;
  margin-bottom: 0;
  letter-spacing: 0.35px;
}

.search-box-main {
  margin-top: 15px;
  position: relative;
}

.search-box-main input {
  box-shadow: 0px 1px 4px 0px #0000000D;
  background: #FFFFFF;
  border-radius: 40px;
  width: 100%;
  border: 0;
  padding: 14px 16px;
  border: 1px solid transparent;
}

.search-box-main input:focus {
  outline: none;
  border: 1px solid #19A7CE;
}

.search-img-sub {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.search-img {
  /* display: flex;
            width: 100%;
            border: 1px solid #dcdcdc;
            border-radius: 5px;
            background-color: #fff; */
}

.text-input {}

.advance-search {
  position: absolute;
  right: 22px;
  top: 20px;
}

.advance-search-main {
  position: relative;
  padding: 13px 0 12px 16px;
  border-top: 1px solid #A77272;
  border-bottom: 1px solid #A77272;
  letter-spacing: 0.56px;
  color: #763A3A;
  background: #F1E9E9;
}

.count {
  padding-top: 16px;
  padding-left: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #A77272;
  margin-bottom: 12px;
}

.actions-main {
  display: flex;
  gap: 15px;
}

.actions-main div {
  border: 1px solid #19A7CE;
  background: #fff;
  border-radius: 34px;
  padding: 5px 16px;
  flex: 1;
  text-align: center;
  color: #19A7CE;
  display: flex;
  gap: 5px;
  align-items: center;
}

.actions-main .active {
  background: #19A7CE;
  color: #FFFFFF;
}

.cp-actions-main {
  padding: 15px;
}

.cp-actions-des {
  text-align: center;
  font-weight: 400;
  color: #000;
  line-height: 17.34px;
  font-size: 12px;
  padding-bottom: 15px;
}

.cp-actions-des span {
  font-weight: 600;
}

.cp-card-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cp-card {
  box-shadow: 0px 1px 4px 0px #0000000D;
  background: #fff;
  border-radius: 16px;
}

.cp-card-new-label {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */

}

.cp-card-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 400;
  color: #888888;
  padding: 16px;
  /* border-bottom: 1px dashed #B9B9B9; */
}

.label-des {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  display: inline-block;
  margin-right: 6px;
}

.new-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #888888;
  background: #E3F8EC;
  padding: 1px 4px;
  border-radius: 4px;
  display: inline-block;
}

/* advance search  */
.adv-main-container {
  background-color: #fafafa;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1000;
  min-height: 100vh;
  min-height: 100dvh;
}

.checkbox-main {
  align-items: center;
  gap: 10px;
  flex: 1;
  margin-top: 10px;
}

.form-main {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}

.img-advanc {
  position: absolute;
  right: 16px;
}

.card-details-value {
  border-radius: 8px !important;
  /* opacity: 0.5; */
  padding: 12px 20px;
  color: #bfbfbf;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  border: 1px solid #B9B9B9;
  padding: 15px;
}

.card-details-select {
  padding: 5px 15px;
}

.btn-clr {
  /* background-color: #19A7CE; */
  width: 100%;
  color: #19a7ce;
  border: 1px solid #19a7ce;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  padding: 12px;
  background-color: #fff;
}

.search-clr {
  width: 100%;
  background-color: #19a7ce;
  /* border: 1px solid #19A7CE; */
  color: #ffffff;
  border-radius: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.submit-section {
  position: relative;
}

.user-input-wrp {
  position: relative;
  border: 1px solid #B9B9B9;
  border-radius: 8px;
  width: 100%;
  background: white;
}

.user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  /* border-bottom: 1px solid #777; */
  box-shadow: none !important;
  padding-bottom: 3px;
  padding-left: 16px;
}

.user-input-wrp .inputText:focus {
  border-color: blue;
  border-width: medium medium 2px;
}

.user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 18px;
  left: 10px;
  transition: 0.2s ease all;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #888888;
}

.user-input-wrp input:focus~.floating-label,
.user-input-wrp input:not(:focus):valid~.floating-label {
  top: 6px;
  left: 16px;
  font-size: 13px;
  opacity: 1;
}

/* date picker start */
.date-picker-input {
  position: relative;
  padding-bottom: 55px;
}
.date-picker-input::after {
  top: 24% !important;
}
/* date picker end */
.submit-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #FFFFFF;
  background: #19A7CE;
  flex: 1;
  border-radius: 34px;
  padding: 12px;
  margin: 16px;
  display: flex;
  justify-content: center;
}

.cancle-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #19A7CE;
  border: 1px solid #19A7CE;
  border-radius: 34px;
  flex: 1;
  padding: 12px;
  margin: 16px;
  display: flex;
  justify-content: center;
}
</style>