import { createApp } from 'vue'
import App from './App.vue'
import router from './router' 
import store from "./store";
import "../node_modules/bootstrap/dist/css/bootstrap.css"
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js"
import "@/assets/style.css";
import axios from 'axios'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import './assets/styles/custom.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Paginate from "vuejs-paginate-next";
import LoadScript from "vue-plugin-load-script";

let baseUrl = "https://staging-rails7.mandate-sales.com/api/mobile_crm/";
if(process.env.NODE_ENV === 'production'){
  baseUrl = "https://staging-rails7.mandate-sales.com/api/mobile_crm/"
}
const axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 1000000,
    params: {}
})

axiosInstance.interceptors.request.use(function (config) {
    if(config.url.indexOf('login') < 0 && config.url.indexOf('send_reset_password_instructions') < 0){
        let token = localStorage.getItem('cp-mandate-token');
        if(token) {
            config.headers.Authorization = `Token token=${token}`;
            config.headers['ngrok-skip-browser-warning'] = 'true'
            config.headers['Content-Type'] = 'application/json'
            config.headers['Accept'] = 'application/json'
        }else{
            return {
              headers: {},
              method: config.method,
              url: ""
            };
        }
    }
    return config;
 }, function (error) {
     // Do something with request error
     return Promise.reject(error)
 })

 axios.interceptors.response.use(function(response) {
    return response;
  }, function(error){
    if(error.response.status === 401 || error.response.status === 404){
      localStorage.removeItem('cp-mandate-token');
      router.replace('/');
    }else{
      return Promise.reject(error);
    }
  });
  router.beforeEach((to, from, next) => {
    let token = localStorage.getItem('cp-mandate-token');
    if(token && to.name === 'login' ){
      next({name: 'Home'});
    }else if(to.name !== 'login' && to.name!=='ResetPassword' && !token){
      next({name: 'login'});
    }else{
      next();
    }
  })

let app = createApp(App);
app.config.globalProperties.$axios = axiosInstance;
app.use(router).use(LoadScript).use(store).use(VueSweetalert2).component("paginate", Paginate).component('VueDatePicker', VueDatePicker).mount('#app')
